<template>
    <div class="mx-4">
        <Affixes v-if="$vuetify.breakpoint.mdAndUp" />
        <v-row class="mb-4">
            <div class="py-5 px-5">
                <p>Bonjour,</p>
                <p>La guilde <b>Don't Release</b> <i>(Horde sur Confrérie du Thorium, Les Clairvoyants, Les Sentinelles, Conseil des Ombres, Culte de la Rive noire, La Croisade écarlate et Kirin Tor)</i> lance son recrutement pour Shadowland.</p>
                <p>Nous sommes un noyau dur d'une dizaine joueurs, majoritairement trentenaires et certains avec enfants, qui se connaissent pour la plupart depuis 2 ans, voir même IRL.</p>
                <p>Nous avons traversé le contenu BFA ensemble au sein d'une autre guilde, dont le but était de clean NM.</p>
                <p>Aujourd'hui, nos ambitions ayant changé, nous avons décidé de créer notre propre guilde afin de se lancer dans Shadowland avec l'objectif de clean les contenus NM et HM et de voir jusqu'ou nous pouvons aller en Mythic.</p>
                <p>Nous raidons les mardis et jeudis, de 21h à 23h30.</p>
                <p><b>La présence en raid est obligatoire (sauf cas de force majeure) pour les gens faisant parti du roster.</b></p>
                <p>Également friands de MM+, nous nous attaquons à l'objectif de time toutes les clés en +15 à chaque pallier dès la fin du progress raid (en général, quand on voit qu'on bloque toujours sur le même boss malgré les changements opérés sur la strat).</p>
                <p>Pour cela, nous souhaiterions recruter avant tout des personnes désireuses de jouer dans une bonne ambiance, de faire parti d'un groupe soudé et qui comprennent que pour progresser, il faut parfois tomber à de multiples reprises, tout en gardant le sourire.<p>
                <p>Vous souhaiter nous rejoindre?</p>
                <p>Pour cela, vous pouvez rejoindre <a href="https://discord.gg/DvNvFbH" target="_blank">notre discord</a> et publier une petite description dans la section recrutement.</p>
                <p>Vous pouvez aussi nous contacter directement sur Battle.Net</p> 
                <v-row>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                        <BnetCard name="Evanana" tag="liliangemari#2269" />
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                        <BnetCard name="Benji" tag="benJi#22582" />
                    </v-col>
                    <v-col xl="4" lg="4" md="4" sm="12" xs="12">
                        <BnetCard name="RedW" tag="redw#21502" />
                    </v-col>
                </v-row>
            </div>
        </v-row>
    </div>
</template>

<script>
import Affixes from '../../components/Affixes.vue';
import BnetCard from '../../components/BnetCard.vue';

export default {
  name: 'Home',

  components: { Affixes, BnetCard },

  data: function() {
    return {
        
    }
  },
  methods: {
      
  }
}
</script>
<style>

</style>