<template>
    <div>
        <!-- Bouton retour -->
        <v-row
            v-if="isBackAllowed()"
            class="pt-2"
            align="center"
            justify="center"
        >
            <v-btn dark color="red" @click="onClickBack" class="mr-4">
                <v-icon left>
                    mdi-keyboard-return
                </v-icon>
                Retour
            </v-btn>
            <h1 v-if="selectedName != 'All'" class="text-center">{{ selectedName != null ? selectedName.replaceAll("Ã©", "é").replaceAll("Ã", "à").replaceAll("à«", "ë") : "" }}</h1>
            <h1 v-else-if="selectedBoss != null" class="text-center">{{ selectedBoss }}</h1>
        </v-row>
        <!-- Précédent suivant en mode boss -->
        <v-row
            v-if="selectedBoss != null"
            class="pt-2"
            align="center"
            justify="center"
        >
            <v-btn dark color="green" @click="onClickLast" class="mr-4">
                <v-icon left>
                    mdi-arrow-left-circle-outline
                </v-icon>
                Précédent
            </v-btn>
            <v-btn dark color="green" @click="onClickNext" class="mr-4">
                Suivant
                <v-icon right>
                    mdi-arrow-right-circle-outline
                </v-icon>
            </v-btn>
        </v-row>
        <!-- Les titres -->
        <h1 v-if="selectedName == null" class="text-center py-2 pt-4">Choisir le personnage : </h1>
        <h1 v-else-if="selectedName == 'All' && selectedBoss == null" class="text-center py-2">Choisir le boss : </h1>
        
        <!-- Choix des perso -->
        <div v-if="selectedName == null" class="px-2 pb-4">
            <h4 class="text-center mt-4">DPS</h4>
            <v-row class="center-xy">
                <v-col cols="3" >
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick('All')">
                            <v-card-title>All</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="p in ppl" :key="p">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(p)">
                            <v-card-title>{{ p != null ? p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <h4 class="text-center mt-4">TANK</h4>
            <v-row class="center-xy">
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="t in tank" :key="t">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(t)">
                            <v-card-title>{{ t != null ? t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <h4 class="text-center mt-4">HEAL</h4>
            <v-row class="center-xy">
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="h in heal" :key="h">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(h)">
                            <v-card-title>{{ h != null ? h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </div>
        <!-- Choix des boss -->
        <v-row v-else-if="selectedName == 'All' && selectedBoss == null" class="px-2 pb-4 center-xy">
            <v-col cols="6" sm="6" xs="6" md="6" lg="6" xl="6" v-for="b in boss" :key="b.id">
                <v-hover v-slot="{ hover }">
                    <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onBossClick(b.name)">
                        <v-row class="center-xy">
                            <v-avatar size="64">
                                <img
                                    :src="b.icon"
                                    :alt="b.name"
                                >
                            </v-avatar>
                        </v-row>
                        <v-card-title class="center-xy">{{ b.name }}</v-card-title>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-row v-else class="px-2 pb-4 pt-6">
            <!-- Légende -->
            <div :style="$vuetify.breakpoint.smAndDown ? '' : 'display: flex'" :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : ''">
                <p :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'"><i>Légende : </i></p>
                <div class="mr-1 square color-green"></div> > 10% du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-blue"></div> Au dessus du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-orange"></div> Inférieur a 0% du seuil mais supérieur a -10% du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-red"></div> ???
            </div>
            <!-- Table perso unique -->
            <table class="greyGridTable mx-2" v-if="selectedName != 'All'">
                <thead>
                    <tr>
                        <th></th>
                        <th>Boss</th>
                        <th v-for="w in week" :key="w">{{w}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="b in boss" :key="b.id">
                        <td style="text-align:left; vertical-align: middle;" class="min grey">
                            <img :src="b.icon" width="50" height="50" />
                        </td>
                        <td style="text-align:left; vertical-align: middle;" class="min grey">
                            <h4>{{ b.name }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossByCharForWeek(b,w)">{{ getDataByBossByCharForWeek(b, w) }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- Table all perso & boss unique -->
            <table class="greyGridTable mx-2" v-else-if="selectedBoss != null">
                <thead>
                    <tr>
                        <th>Joueur</th>
                        <th v-for="w in week" :key="w">{{w}}</th>
                    </tr>
                </thead>
                <tbody>
                    <h4>DPS</h4>
                    <tr v-for="p in ppl" :key="p">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(p,w)">{{ getDataByBossForAllCharForWeek(p, w) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                    <h4>TANK</h4>
                    <tr v-for="t in tank" :key="t">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(t,w, true)">{{ getDataByBossForAllCharForWeek(t, w, true) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                    <h4>HEAL</h4>
                    <tr v-for="h in heal" :key="h">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(h,w, false, true)">{{ getDataByBossForAllCharForWeek(h, w, false, true) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-row>
    </div>
</template>
<!--
    Raw_data est un tableau et chaque entrée == une semaine
    Une semaine est un object avec chaque boss
    Chaque boss contient l'export CSV des logs du boss converti en JSON
    L'export CSV se chope en bas de tableau des dégat
    Pour le CSV to JSON = https://csvjson.com/csv2json (option minimify stp)
    
    /!\ Certaine log (aucune idée de savoir comment) déconne un max niveau encodage ce qui porte problème pour :
    Dryhwèn - Pestiférée - Kërronz
    Bref, les personnes avec accent.
    Avant de faire l'import, faut faire un replace des char qui déconne sinon ça créer des problèmes

    onMounted : Lancement de la conversion des raw_data en data un peu plus lisible.
    Pour chaque semaine dans raw_data, on va créer une semaine dans data et pour chaque boss de la semaine, convertir les datas de chaque boss

    getFightData : 
    L'idée est de transformer chaque combat en ceci :
        {
            name: name,
            tank: [],
            heal: [],
            dps: [],
            dps_tank: 0,
            dps_heal: 0,
            dps_nodps: 0,
            seuil: 0 
        }

    P.S 1 : Peux être qu'il serait plus judicieux d'avoir les data pour chaque personnage plutôt que pour chaque combat :thinking:

    Donc, pour chaque combat, pour chaque personne, on va determiner son type (tank, heal ou dps),
    convertir les datas DPS et le rajouter dans le tableau correspondant.
    On profite de l'itération (woaw ta pas vu venir cette optimisation !) pour faire la sommes du DPS Tank et du DPS heal 
    pour pouvoir le soustraire et faire le calcul du seuil.

    Maintenant que nous avons nos données propres, il faut laisser l'utilisateur faire son choix entre la view par perso ou la view par boss

    #TODO
-->
<script>
    import SFO from '../services/SFO';
    export default {
        name: "LogList",
        components: { },
        data () {
            return {
                raw_data: SFO.NM.log,
                ppl: [],
                boss: SFO.boss,
                week: SFO.NM.week,
                selectedName: null,
                selectedBoss: null,
                tank: ["Redm", "Loudda", "Kërronz-tank", "Dread"],
                heal: ["Syrane", "Myanaa", "Noffs"],
                data: []
            }
        },
        methods: {
            onNameClick: function(name) {
                this.selectedName = name;
            },
            onBossClick: function(name) {
                this.selectedBoss = name;
            },
            isBackAllowed: function() {
                if (this.selectedBoss != null || this.selectedName != null) {
                    return true;
                }

                return false;
            },
            onClickBack: function() {
                if (this.selectedBoss != null) {
                    this.selectedBoss = null;
                }
                else if (this.selectedName != null) {
                    this.selectedName = null;
                }
            },
            onClickLast: function() {
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (boss) {
                    if (boss.id != 0) {
                        const newBoss = this.boss.find(b => b.id == (boss.id - 1));
                        
                        if (newBoss) {
                            this.selectedBoss = newBoss.name;
                        }
                    }
                }
            },
            onClickNext: function() {
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (boss) {
                    if (boss.id != 9) {
                        const newBoss = this.boss.find(b => b.id == (boss.id + 1));
                        
                        if (newBoss) {
                            this.selectedBoss = newBoss.name;
                        }
                    }
                }
            },
            getFightData: function(raw_fight, name) {
                if (raw_fight.length <= 0) {
                    return false;
                }
                
                const temp = {
                    name: name,
                    tank: [],
                    heal: [],
                    dps: [],
                    dps_tank: 0,
                    dps_heal: 0,
                    dps_nodps: 0,
                    seuil: 0 
                };
                
                raw_fight.forEach(element => {
                    const el = {
                        name: element.Name,
                        // ça ressemble à "1764742$10.35%1.76m"
                        dps: parseFloat(element.Amount.split("$")[1].split('%')[0]),
                        
                        // La joie du JS et des , de séparation des milliers
                        // 900 c'est un number, 1,900 c'est un string youblaboom ça casse tout le code
                        dpsK: typeof element.DPS == "number" ? parseInt(element.DPS).toString() : element.DPS.replace(',', 'k').split('.')[0],
                        parsePrcnt: element["Ilvl %"],
                        ilvl: element.Ilvl
                    };

                    // Si c'est un tank
                    if (this.tank.includes(el.name)) {
                        // Ajout dans la liste des tanks du combat
                        temp.tank.push(el);

                        // calcul du dps tank
                        temp.dps_tank += el.dps;
                    }
                    else if (this.heal.includes(el.name)) {
                        // Ajout dans la liste des heals du combat
                        temp.heal.push(el);

                        // calcul du dps heal
                        temp.dps_heal += el.dps;
                    }
                    else {
                        // Si c'est un DPS (ou du moins c'est ni un tank ni un heal)
                        if (!this.ppl.includes(el.name)) {
                            // Ajout dans la liste des dps du combat
                            this.ppl.push(el.name);
                        }

                        temp.dps.push(el);
                    }
                });

                // total non-dps
                temp.dps_nodps = temp.dps_tank + temp.dps_heal;

                // calcul du seuil
                temp.seuil = (100 - temp.dps_nodps)/temp.dps.length;
                temp.seuil = Math.round((temp.seuil + Number.EPSILON) * 100) / 100

                return temp;
            },
            getItemsForArray: function(data) {
                const finalArray = [];
                // Doit retourner un tableau compatible avec le headers
                // Donc : "Name", "Tarragrue", "Oeil", "The Nine", "Ner'zhul", "Darmanin", "Raznal", "Guardian", "Roh-Kalo", "KT", "Sylvanas", "Total"
                // Pour chaque DPS, puis tank, puis healer

                // 1er étape : récupération de la liste des DPS
                const ppl_temp = [];
                
                data.forEach(element => {
                    if (element == false) { return; }
                    element.dps.forEach(dps => {
                        if (!ppl_temp.includes(dps.name)) { ppl_temp.push(dps.name); }
                    });
                });

                // Maintenant, pour chaque joueur, il va falloir faire la liste de ses scores et la rajouter dans le tableau final
                ppl_temp.forEach(ppl => {
                    const row = {
                        Pseudo: ppl,
                        Guardian: 0,
                        Dausegne: 0,
                        Wishmox: 0,
                        Pantheon: 0,
                        Skrolex: 0,
                        Halondrus: 0,
                        Lihuvim: 0,
                        Anduin: 0,
                        Dread: 0,
                        Rygelon: 0,
                        Geolier: 0
                    };

                    // Pour chaque boss
                    data.forEach(boss => {
                        if (boss == false) { return; }

                        const info = boss.dps.find(pr => pr.name == ppl);
                        if (info) {
                            row[boss.name] = info.dps;
                        }
                    });

                    finalArray.push(row);
                });
                
                // Et rajouter le seuil
                const seuil = {
                    Pseudo: "Seuil",
                    Guardian: 0,
                    Dausegne: 0,
                    Wishmox: 0,
                    Pantheon: 0,
                    Skrolex: 0,
                    Halondrus: 0,
                    Lihuvim: 0,
                    Anduin: 0,
                    Dread: 0,
                    Rygelon: 0,
                    Geolier: 0
                };
                data.forEach(boss => {
                    if (boss == false) { return; }
                    seuil[boss.name] = boss.seuil;
                });

                finalArray.push(seuil);

                return finalArray;
            },
            getDataByBossByCharForWeek: function(boss, week) {
                const weekData = this.data[this.week.indexOf(week)];
                const data = weekData[boss.id];

                if (data == false) { return "Pas down" }

                // Recherche du DPS
                let pplData = data.dps.find(p => p.name == this.selectedName);

                // Si personne, recherche tank
                if (typeof pplData == "undefined") {
                    pplData = data.tank.find(p => p.name == this.selectedName);
                }

                // Si personne, recherche heal
                if (typeof pplData == "undefined") {
                    pplData = data.heal.find(p => p.name == this.selectedName);
                }

                if (pplData) {
                    return `${pplData.ilvl}iLvl, ${pplData.dpsK} - ${pplData.dps}/${data.seuil} - ${pplData.parsePrcnt} iLvl%`;
                }
                else {
                    return "Absent";
                }
            },
            getColorByBossByCharForWeek: function(boss, week) {
                const weekData = this.data[this.week.indexOf(week)];
                const data = weekData[boss.id];

                if (data == false) { return "color-black" }
                
                // Recherche du DPS
                let pplData = data.dps.find(p => p.name == this.selectedName);

                let tank = false;
                let heal = false;

                // Si personne, recherche tank
                if (typeof pplData == "undefined") {
                    pplData = data.tank.find(p => p.name == this.selectedName);
                    tank = typeof pplData == "undefined" ? false : true;
                }

                // Si personne, recherche heal
                if (typeof pplData == "undefined") {
                    pplData = data.heal.find(p => p.name == this.selectedName);
                    heal = typeof pplData == "undefined" ? false : true;
                }

                if (pplData) {
                    if (tank || heal) {
                        return "color-blue";
                    }

                    const result = ((pplData.dps*100)/data.seuil)-100;

                    // Si DPS > 10% du seuil alors green
                    if (result > 10) { return "color-green"; }

                    // Si DPS > 0 alors rien
                    else if (result >= 0) { return "color-blue"; }

                    // Si DPS < 0% mais > a 10% du seuil, alors orange
                    else if (result < 0 && result > -10) { return "color-orange"; }

                    // Si DPS plus bas de 10% du seuil, alors rouge
                    else if (result < -10) { return "color-red"; }
                }

                return "color-black";
            },
            getDataByBossForAllCharForWeek: function(ppl, week, tank, heal) {
                const weekData = this.data[this.week.indexOf(week)];
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (typeof boss == "undefined") { return "Gné ?"; }

                const data = weekData[boss.id];

                if (data == false) { return "Pas down" }
                
                const _data = tank ? data.tank : heal ? data.heal : data.dps;

                const pplData = _data.find(p => p.name == ppl);
                
                if (pplData) {
                    return `${pplData.ilvl}iLvl, ${pplData.dpsK} - ${pplData.dps}/${data.seuil} - ${pplData.parsePrcnt} iLvl%`;
                }
                else {
                    return "Absent";
                }
            },
            getColorByBossForAllCharForWeek: function(ppl, week, tank, heal) {
                const weekData = this.data[this.week.indexOf(week)];

                const boss = this.boss.find(b => b.name == this.selectedBoss);
                
                if (typeof boss == "undefined") { return "Gné ?"; }

                const data = weekData[boss.id];

                if (data == false) { return "color-black" }

                const _data = tank ? data.tank : heal ? data.heal : data.dps;

                const pplData = _data.find(p => p.name == ppl);

                if (pplData) {
                    if (tank || heal) {
                        return "color-blue"; 
                    }

                    const result = ((pplData.dps*100)/data.seuil)-100;

                    // Si DPS > 10% du seuil alors green
                    if (result > 10) { return "color-green"; }

                    // Si DPS > 0 alors rien
                    else if (result >= 0) { return "color-blue"; }

                    // Si DPS < 0% mais > a 10% du seuil, alors orange
                    else if (result < 0 && result > -10) { return "color-orange"; }

                    // Si DPS plus bas de 10% du seuil, alors rouge
                    else if (result < -10) { return "color-red"; }
                }

                return "color-black";
            }
        },
        mounted() {
            this.raw_data.forEach(raw => {
                this.data.push([
                    this.getFightData(raw["Guardian"], "Guardian"),
                    this.getFightData(raw["Dausegne"], "Dausegne"),
                    this.getFightData(raw["Wishmox"], "Wishmox"),
                    this.getFightData(raw["Pantheon"], "Pantheon"),
                    this.getFightData(raw["Skrolex"], "Skrolex"),
                    this.getFightData(raw["Halondrus"], "Halondrus"),
                    this.getFightData(raw["Lihuvim"], "Lihuvim"),
                    this.getFightData(raw["Anduin"], "Anduin"),
                    this.getFightData(raw["Dread"], "Dread"),
                    this.getFightData(raw["Rygelon"], "Rygelon"),
                    this.getFightData(raw["Geolier"], "Geolier")
                ]);
            });
        }
    }
</script>

<style>
    .square {
        width: 20px;
        height: 20px;
    }
    .color-green {
        background: #4caf50 !important;
        color:white;
    }
    .color-blue {
        background: #2196f3 !important;
        color:white;
    }
    .color-orange {
        background: #ff5722 !important;
        color:white;
    }
    .color-red {
        background: red !important;
        color:white;
    }
    .color-black {
        background: black !important;
        color:white;
    }
    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: white !important;
    }

    .elevation-12.v-card.v-sheet.theme--light {
        background-color: #fff0;
    }

    .center-xy {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    table.greyGridTable {
        border: 2px solid #FFFFFF;
        width: 100%;
        text-align: center;
        border-collapse: collapse;
    }
    table.greyGridTable td, table.greyGridTable th {
        border: 1px solid #FFFFFF;
        padding: 3px 4px;
    }
    table.greyGridTable td.grey {
        background: #EBEBEB;
    }
    table.greyGridTable thead {
        background: #FFFFFF;
        border-bottom: 4px solid #333333;
    }
    table.greyGridTable thead th {
        font-weight: bold;
        color: #333333;
        text-align: center;
        border-left: 2px solid #333333;
    }
    table.greyGridTable thead th:first-child {
        border-left: none;
    }

    table.greyGridTable tfoot {
        font-weight: bold;
        color: #333333;
        border-top: 4px solid #333333;
    }
    table.greyGridTable tfoot td {
        font-size: 14px;
    }
    table.greyGridTable td.min {
        width: 5%;
        white-space: nowrap;
    }
</style>