<template>
<div>
    <div v-if="isMobile">
        <v-data-table
        :headers="headers_mobile"
        :items="data"
        item-key="name"
        class="elevation-1"
        dense
        hide-default-footer
        :sort-by="['rioCurrentScore']"
        :sort-desc="[true]"
        @click:row="onRowClicked"
        :items-per-page="-1"
        >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="getDiscordText">
                <v-icon class="mr-2">mdi-upload</v-icon>
                    Export
                </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:item.score="{ item }" >
            <v-chip @click="clickOnRow" style="width: 100%; justify-content: center; text-align: center;" :color="getColor(item.character.score.score)" dark>{{ Math.round(item.character.score.score) }}</v-chip>
        </template>
        </v-data-table>
    </div>
    <div v-else>
        <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        dense
        hide-default-footer
        :sort-by="['rioCurrentScore']"
        :sort-desc="[true]"
        @click:row="onRowClicked"
        :items-per-page="-1"
        >
            <template v-slot:top>
                <v-toolbar flat dark>
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="warning" @click="getDiscordText">
                <v-icon class="mr-2">mdi-upload</v-icon>
                    Export
                </v-btn>
            </v-toolbar>
            </template>
            <template v-slot:item.name="{ item }">
            <span :style="getNameStyle(item)">{{item.name}}</span>
            </template>
            <template v-slot:item.class="{ item }">
                <img style="border-radius: 100%; max-width:32px; max-height:32px;" height="100%" :src="'https://server.dont-release.fr/classMedia/' + item.character.class" />
            </template>
            <template v-slot:item.spec="{ item }">
                <img style="border-radius: 100%; max-width:32px; max-height:32px;" height="100%" :src="'https://server.dont-release.fr/specMedia/' + item.character.spec"/>
            </template>
            <template v-slot:item.score="{ item }" >
                <v-chip style="width: 100%; justify-content: center; text-align: center;" :color="getColor(item.character.score.score)" dark>{{ Math.round(item.character.score.score) }}</v-chip>
            </template>
        </v-data-table>
    </div>
    <GuildeUserDetail :show="modal.show" :user="modal.data" @close="modal.show = false" />
    <SimplePopup :title="'Export d\'analyse pour discord'" :content="exportContent" :show="exportShow" @close="exportShow = false;" />
</div>
</template>

<script>
    import GuildeUserDetail from '../components/GuildeUserDetail';
    import SimplePopup from '../components/SimplePopup';

    export default {
        name: "MembersTable",
        props: ["title", "data"],
        components : { GuildeUserDetail, SimplePopup },
        data () {
            return {
                modal: {
                    show: false,
                    data: null
                },
                exportContent: null,
                exportShow: false,
                isMobile: false,
                expanded: [],
                singleExpand: false,
                headers_mobile: [
                    {
                        value: 'id',
                        align: ' d-none',
                        sortable: false,
                        searchable: false
                    },
                    {
                        value: 'name',
                        text: 'Pseudo'
                    },
                    {
                        value: 'rioCurrentScore',
                        text: 'RIO'
                    },
                    {
                        value: 'maxKey',
                        text: 'Max'
                    },
                    {
                        value: 'maxWeeklyKey',
                        text: 'Max Weekly'
                    },
                    {
                        value: 'maxLastWeekKey',
                        text: 'Max Last Week'
                    },
                    {
                        key: 'score',
                        value: 'score',
                        text: 'Score'
                    }
                ],
                headers: [
                    {
                        value: 'id',
                        align: ' d-none',
                        sortable: false,
                        searchable: false
                    },
                    {
                        key: 'class',
                        value: 'class',
                        width: "1%"
                    },
                    {
                        key: 'spec',
                        value: 'spec',
                        width: "1%"
                    },
                    {
                        value: 'name',
                        text: 'Pseudo'
                    },
                    {
                        value: 'rioCurrentScore',
                        text: 'RIO'
                    },
                    {
                        value: 'maxKey',
                        text: 'Max'
                    },
                    {
                        value: 'maxWeeklyKey',
                        text: 'Max Weekly'
                    },
                    {
                        value: 'maxLastWeekKey',
                        text: 'Max Last Week'
                    },
                    {
                        key: 'score',
                        value: 'score',
                        text: 'Score'
                    }
                ]
            }
        },
        mounted() {
            this.isMobile = window.innerWidth <= 768;
        },
        methods: {
            getColor(score) {
                return score < 70 ? "red" : score < 100 ? "orange" : "green";
            },
            getNameStyle(item) {
                let color = "black";

                switch (item.character.class) {
                case 1: // war
                    color = "#c79c6e!important";
                    break;
                case 2: // paladin
                    color = "#f58cba!important";
                    break;
                case 3: // chasseur
                    color = "#abd473!important";
                    break;
                case 4: // voleur
                    color = "#fff569!important";
                    break;
                case 5: // prêtre
                    color = "#fff!important";
                    break;
                case 6: // dk
                    color = "#c41f3b!important";
                    break;
                case 7: // sham
                    color = "#0070de!important";
                    break;
                case 8: // mage
                    color = "#69ccf0!important";
                    break;
                case 9: // demo
                    color = "#9482c9!important";
                    break;
                case 10: // monk
                    color = "#00ff96!important";
                    break;
                case 11: // drood
                    color = "#ff7d0a!important";
                    break;
                case 12: // dh
                    color = "#a330c9!important";
                    break;
                
                default:
                    break;
                }

                return "color: " + color + "; font-weight: 500;";
            },
            getDiscordText() {
                let result = "Analyse du " + new Date().toLocaleDateString() + " : <br />";

                this.data.forEach(element => {
                    if (element.character) {
                        if (element.character.score.score < 100) {
                            let resultChar = element.name + " " + element.character.score.score.toFixed() + "/100 (Dernière MAJ le " + new Date(element.character.last_login_at).toLocaleString() + ") : <br />";

                            const score = element.character.score;
                            
                            // Enchantements
                            score.missing_enchantment.forEach(missing_enchantment => {
                                if (missing_enchantment.ilvl >= 184) {
                                    resultChar += "Il manque un enchantement sur **" + missing_enchantment.name + " (iLvl " + missing_enchantment.ilvl + ")**<br />";
                                }
                            });
                            // Gemmes
                            score.missing_socket.forEach(missing_socket => {
                                if (missing_socket.ilvl >= 184) {
                                    resultChar += "Il manque une gemme sur **" + missing_socket.name + " (iLvl " + missing_socket.ilvl + ")**<br />";
                                }
                            });

                            if (resultChar != "Pour " + element.name + " : <br />") {
                                result += resultChar + "<br />";
                            }
                        }
                    }
                });

                this.exportContent = result;
                this.exportShow = true;
            },
            onRowClicked: function(row) {
                this.modal.data = row;
                this.modal.show = true;
            }
        }
    }
</script>
<style>
  td.text-start {
    position: relative;
  }

  td.text-start > img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
</style>