import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/es5/locale/fr'

Vue.use(Vuetify);

Vue.component('v-data-table', {
    methods: {
        changeLocale () {
        this.$vuetify.lang.current = 'fr'
        },
    },
})

export default new Vuetify({
    lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
      dark: true,
      themes: {
        light: {
          primary: '#ee44aa',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107'
        },
      },
    },
});
