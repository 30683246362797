<template>
    <v-dialog
      v-model="show"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
            {{title}}
        </v-card-title>

        <v-card-text class="py-4" v-html="content">
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: "SimplePopup",
        props: ['show', 'title', 'content'],
        methods: {
            close: function() {
                this.$emit('close');
            }
        }
    }
</script>