<template>
  <div class="mx-4">
    <v-row class="mb-4">
        <div class="py-5 px-5">
          <MembersTable title="Roster" :data="getMembers" />
        </div>
    </v-row>
  </div>
</template>

<script>
import MembersTable from '../../components/MembersTable.vue';

import { mapGetters } from 'vuex'

export default {
  name: 'Roster',

  components: { MembersTable  },

  computed: {
    ...mapGetters({
      getMembers: 'guilde/getRosterMembers',
    })
  },

  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style>

</style>