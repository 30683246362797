<template>
    <div>
        <!-- Bouton retour -->
        <v-row
            v-if="isBackAllowed()"
            class="pt-2"
            align="center"
            justify="center"
        >
            <v-btn dark color="red" @click="onClickBack" class="mr-4">
                <v-icon left>
                    mdi-keyboard-return
                </v-icon>
                Retour
            </v-btn>
            <h1 v-if="selectedName != 'All'" class="text-center">{{ selectedName != null ? selectedName.replaceAll("Ã©", "é").replaceAll("Ã", "à").replaceAll("à«", "ë") : "" }}</h1>
            <h1 v-else-if="selectedBoss != null" class="text-center">{{ selectedBoss }}</h1>
        </v-row>
        <!-- Précédent suivant en mode boss -->
        <v-row
            v-if="selectedBoss != null"
            class="pt-2"
            align="center"
            justify="center"
        >
            <v-btn dark color="green" @click="onClickLast" class="mr-4">
                <v-icon left>
                    mdi-arrow-left-circle-outline
                </v-icon>
                Précédent
            </v-btn>
            <v-btn dark color="green" @click="onClickNext" class="mr-4">
                Suivant
                <v-icon right>
                    mdi-arrow-right-circle-outline
                </v-icon>
            </v-btn>
        </v-row>
        <!-- Les titres -->
        <h1 v-if="selectedName == null" class="text-center py-2 pt-4">Choisir le personnage : </h1>
        <h1 v-else-if="selectedName == 'All' && selectedBoss == null" class="text-center py-2">Choisir le boss : </h1>
        
        <!-- Choix des perso -->
        <div v-if="selectedName == null" class="px-2 pb-4">
            <h4 class="text-center mt-4">DPS</h4>
            <v-row class="center-xy">
                <v-col cols="3" >
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick('All')">
                            <v-card-title>All</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="p in ppl" :key="p">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(p)">
                            <v-card-title>{{ p != null ? p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <h4 class="text-center mt-4">TANK</h4>
            <v-row class="center-xy">
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="t in tank" :key="t">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(t)">
                            <v-card-title>{{ t != null ? t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
            <h4 class="text-center mt-4">HEAL</h4>
            <v-row class="center-xy">
                <v-col cols="6" sm="6" xs="6" md="3" lg="3" xl="3" v-for="h in heal" :key="h">
                    <v-hover v-slot="{ hover }">
                        <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onNameClick(h)">
                            <v-card-title>{{ h != null ? h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") : "" }}</v-card-title>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        </div>
        <!-- Choix des boss -->
        <v-row v-else-if="selectedName == 'All' && selectedBoss == null" class="px-2 pb-4 center-xy">
            <v-col cols="6" sm="6" xs="6" md="6" lg="6" xl="6" v-for="b in boss" :key="b.id">
                <v-hover v-slot="{ hover }">
                    <v-card :dark="hover" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" @click="onBossClick(b.name)">
                        <v-row class="center-xy">
                            <v-avatar size="64">
                                <img
                                    :src="b.icon"
                                    :alt="b.name"
                                >
                            </v-avatar>
                        </v-row>
                        <v-card-title class="center-xy">{{ b.name }}</v-card-title>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
        <v-row v-else class="px-2 pb-4 pt-6">
            <!-- Légende -->
            <div :style="$vuetify.breakpoint.smAndDown ? '' : 'display: flex'" :class="$vuetify.breakpoint.smAndDown ? 'ml-2' : ''">
                <p :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-2'"><i>Légende : </i></p>
                <div class="mr-1 square color-green"></div> > 10% du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-blue"></div> Au dessus du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-orange"></div> Inférieur a 0% du seuil mais supérieur a -10% du seuil
                <div :class="$vuetify.breakpoint.smAndDown ? '' : 'ml-4'" class="mr-1 square color-red"></div> ???
            </div>
            <!-- Table perso unique -->
            <table class="greyGridTable mx-2" v-if="selectedName != 'All'">
                <thead>
                    <tr>
                        <th></th>
                        <th>Boss</th>
                        <th v-for="w in week" :key="w">{{w}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="b in boss" :key="b.id">
                        <td style="text-align:left; vertical-align: middle;" class="min grey">
                            <img :src="b.icon" width="50" height="50" />
                        </td>
                        <td style="text-align:left; vertical-align: middle;" class="min grey">
                            <h4>{{ b.name }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossByCharForWeek(b,w)">{{ getDataByBossByCharForWeek(b, w) }}</td>
                    </tr>
                </tbody>
            </table>
            <!-- Table all perso & boss unique -->
            <table class="greyGridTable mx-2" v-else-if="selectedBoss != null">
                <thead>
                    <tr>
                        <th>Joueur</th>
                        <th v-for="w in week" :key="w">{{w}}</th>
                    </tr>
                </thead>
                <tbody>
                    <h4>DPS</h4>
                    <tr v-for="p in ppl" :key="p">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(p,w)">{{ getDataByBossForAllCharForWeek(p, w) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ p.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                    <h4>TANK</h4>
                    <tr v-for="t in tank" :key="t">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(t,w, true)">{{ getDataByBossForAllCharForWeek(t, w, true) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ t.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                    <h4>HEAL</h4>
                    <tr v-for="h in heal" :key="h">
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                        <td v-for="w in week" :key="w" :class="getColorByBossForAllCharForWeek(h,w, false, true)">{{ getDataByBossForAllCharForWeek(h, w, false, true) }}</td>
                        <td style="text-align:center; vertical-align: middle;" class="min grey">
                            <h4>{{ h.replaceAll("é", "é").replaceAll("à", "à").replaceAll("ë", "ë") }}</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-row>
    </div>
</template>
<!--
    Raw_data est un tableau et chaque entrée == une semaine
    Une semaine est un object avec chaque boss
    Chaque boss contient l'export CSV des logs du boss converti en JSON
    L'export CSV se chope en bas de tableau des dégat
    Pour le CSV to JSON = https://csvjson.com/csv2json (option minimify stp)
    
    /!\ Certaine log (aucune idée de savoir comment) déconne un max niveau encodage ce qui porte problème pour :
    Dryhwèn - Pestiférée - Kërronz
    Bref, les personnes avec accent.
    Avant de faire l'import, faut faire un replace des char qui déconne sinon ça créer des problèmes

    onMounted : Lancement de la conversion des raw_data en data un peu plus lisible.
    Pour chaque semaine dans raw_data, on va créer une semaine dans data et pour chaque boss de la semaine, convertir les datas de chaque boss

    getFightData : 
    L'idée est de transformer chaque combat en ceci :
        {
            name: name,
            tank: [],
            heal: [],
            dps: [],
            dps_tank: 0,
            dps_heal: 0,
            dps_nodps: 0,
            seuil: 0 
        }

    P.S 1 : Peux être qu'il serait plus judicieux d'avoir les data pour chaque personnage plutôt que pour chaque combat :thinking:

    Donc, pour chaque combat, pour chaque personne, on va determiner son type (tank, heal ou dps),
    convertir les datas DPS et le rajouter dans le tableau correspondant.
    On profite de l'itération (woaw ta pas vu venir cette optimisation !) pour faire la sommes du DPS Tank et du DPS heal 
    pour pouvoir le soustraire et faire le calcul du seuil.

    Maintenant que nous avons nos données propres, il faut laisser l'utilisateur faire son choix entre la view par perso ou la view par boss

    #TODO
-->
<script>
    export default {
        name: "SOD_LogListHM",
        components: { },
        data () {
            return {
                raw_data: [
                    {
                        "Tarragrue": [{"Parse %":24,"Name":"Pestiférée","Amount":"3497451$13.33%3.50m","Ilvl":226,"Ilvl %":49,"Active":"96.37%","DPS":"7,456.6","":""},{"Parse %":28,"Name":"Likene","Amount":"3170505$12.08%3.17m","Ilvl":230,"Ilvl %":33,"Active":"90.39%","DPS":"6,759.6","":""},{"Parse %":24,"Name":"Nightpumà","Amount":"3122397$11.90%3.12m","Ilvl":228,"Ilvl %":33,"Active":"87.11%","DPS":"6,657.0","":""},{"Parse %":21,"Name":"Evanana","Amount":"3076833$11.72%3.08m","Ilvl":227,"Ilvl %":32,"Active":"99.19%","DPS":"6,559.9","":""},{"Parse %":17,"Name":"Dread","Amount":"2713020$10.34%2.71m","Ilvl":235,"Ilvl %":9,"Active":"73.62%","DPS":"5,784.2","":""},{"Parse %":7,"Name":"Mordus","Amount":"1710291$6.52%1.71m","Ilvl":227,"Ilvl %":9,"Active":"95.77%","DPS":"3,646.4","":""},{"Parse %":77,"Name":"Dryhwèn","Amount":"1544847$5.89%1.54m","Ilvl":233,"Ilvl %":76,"Active":"87.29%","DPS":"3,293.6","":""},{"Parse %":38,"Name":"Thakgrin","Amount":"1529471$5.83%1.53m","Ilvl":226,"Ilvl %":55,"Active":"85.02%","DPS":"3,260.9","":""},{"Parse %":29,"Name":"Danori","Amount":"1434056$5.46%1.43m","Ilvl":235,"Ilvl %":21,"Active":"93.79%","DPS":"3,057.4","":""},{"Parse %":4,"Name":"Mamyhanzo","Amount":"1096518$4.18%1.10m","Ilvl":226,"Ilvl %":5,"Active":"30.92%","DPS":"2,337.8","":""},{"Parse %":44,"Name":"Calanthea","Amount":"1018511$3.88%1.02m","Ilvl":224,"Ilvl %":69,"Active":"88.77%","DPS":"2,171.5","":""},{"Parse %":3,"Name":"Sagah","Amount":"912073$3.47%912.1k","Ilvl":228,"Ilvl %":3,"Active":"31.85%","DPS":"1,944.6","":""},{"Parse %":3,"Name":"Kërronz","Amount":"654027$2.49%654.0k","Ilvl":230,"Ilvl %":3,"Active":"20.62%","DPS":"1,394.4","":""},{"Parse %":77,"Name":"Taratata","Amount":"434145$1.65%434.1k","Ilvl":228,"Ilvl %":81,"Active":"42.54%","DPS":925.6,"":""},{"Parse %":57,"Name":"Kekzahl","Amount":"332970$1.27%333.0k","Ilvl":228,"Ilvl %":65,"Active":"26.24%","DPS":709.9,"":""}],
                        "Oeil du Geôlier": [{"Parse %":83,"Name":"Dread","Amount":"2552843$13.02%2.55m","Ilvl":235,"Ilvl %":78,"Active":"96.96%","DPS":"5,985.5","":""},{"Parse %":44,"Name":"Nightpumà","Amount":"2106838$10.75%2.11m","Ilvl":229,"Ilvl %":65,"Active":"95.90%","DPS":"4,939.8","":""},{"Parse %":29,"Name":"Pestiférée","Amount":"1905375$9.72%1.91m","Ilvl":226,"Ilvl %":64,"Active":"97.02%","DPS":"4,467.4","":""},{"Parse %":18,"Name":"Mamyhanzo","Amount":"1783058$9.10%1.78m","Ilvl":226,"Ilvl %":34,"Active":"94.38%","DPS":"4,180.6","":""},{"Parse %":27,"Name":"Likene","Amount":"1722261$8.79%1.72m","Ilvl":230,"Ilvl %":33,"Active":"84.74%","DPS":"4,038.1","":""},{"Parse %":14,"Name":"Evanana","Amount":"1695349$8.65%1.70m","Ilvl":227,"Ilvl %":20,"Active":"95.57%","DPS":"3,975.0","":""},{"Parse %":19,"Name":"Kërronz","Amount":"1596951$8.15%1.60m","Ilvl":230,"Ilvl %":22,"Active":"98.35%","DPS":"3,744.3","":""},{"Parse %":84,"Name":"Danori","Amount":"1404062$7.16%1.40m","Ilvl":235,"Ilvl %":81,"Active":"99.79%","DPS":"3,292.0","":""},{"Parse %":8,"Name":"Sagah","Amount":"1238577$6.32%1.24m","Ilvl":228,"Ilvl %":8,"Active":"88.11%","DPS":"2,904.0","":""},{"Parse %":9,"Name":"Mordus","Amount":"1094423$5.58%1.09m","Ilvl":226,"Ilvl %":16,"Active":"94.24%","DPS":"2,566.0","":""},{"Parse %":42,"Name":"Thakgrin","Amount":"1041032$5.31%1.04m","Ilvl":226,"Ilvl %":65,"Active":"99.72%","DPS":"2,440.8","":""},{"Parse %":85,"Name":"Dryhwèn","Amount":"852253$4.35%852.3k","Ilvl":233,"Ilvl %":86,"Active":"88.94%","DPS":"1,998.2","":""},{"Parse %":12,"Name":"Calanthea","Amount":"284344$1.45%284.3k","Ilvl":224,"Ilvl %":28,"Active":"64.11%","DPS":666.7,"":""},{"Parse %":69,"Name":"Kekzahl","Amount":"248683$1.27%248.7k","Ilvl":228,"Ilvl %":79,"Active":"42.31%","DPS":583.1,"":""},{"Parse %":35,"Name":"Taratata","Amount":"77961$0.40%78.0k","Ilvl":228,"Ilvl %":40,"Active":"27.76%","DPS":182.8,"":""}],
                        "The Nine": [],
                        "Ner'zhul": [],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":69,"Name":"Pestiférée","Amount":"3488101$17.19%3.49m","Ilvl":233,"Ilvl %":83,"Active":"99.68%","DPS":"11,215.6","":""},{"Parse %":37,"Name":"Nightpumà","Amount":"2403365$11.85%2.40m","Ilvl":232,"Ilvl %":42,"Active":"99.52%","DPS":"7,727.7","":""},{"Parse %":19,"Name":"Rattataa","Amount":"2117288$10.44%2.12m","Ilvl":227,"Ilvl %":46,"Active":"88.15%","DPS":"6,807.9","":""},{"Parse %":28,"Name":"Kërronz","Amount":"2087207$10.29%2.09m","Ilvl":231,"Ilvl %":40,"Active":"99.82%","DPS":"6,711.2","":""},{"Parse %":23,"Name":"Likene","Amount":"2056820$10.14%2.06m","Ilvl":230,"Ilvl %":37,"Active":"99.80%","DPS":"6,613.5","":""},{"Parse %":17,"Name":"Mamyhanzo","Amount":"1985597$9.79%1.99m","Ilvl":227,"Ilvl %":32,"Active":"98.98%","DPS":"6,384.5","":""},{"Parse %":9,"Name":"Mordus","Amount":"1527892$7.53%1.53m","Ilvl":228,"Ilvl %":17,"Active":"91.99%","DPS":"4,912.8","":""},{"Parse %":70,"Name":"Dread-tank","Amount":"1478414$7.29%1.48m","Ilvl":234,"Ilvl %":72,"Active":"99.62%","DPS":"4,753.7","":""},{"Parse %":85,"Name":"Dryhwèn","Amount":"1272810$6.27%1.27m","Ilvl":231,"Ilvl %":93,"Active":"95.43%","DPS":"4,092.6","":""},{"Parse %":46,"Name":"Thakgrin","Amount":"1171420$5.77%1.17m","Ilvl":227,"Ilvl %":65,"Active":"86.85%","DPS":"3,766.6","":""},{"Parse %":29,"Name":"Calanthea","Amount":"582140$2.87%582.1k","Ilvl":226,"Ilvl %":54,"Active":"99.54%","DPS":"1,871.8","":""},{"Parse %":28,"Name":"Kekzahl","Amount":"118859$0.59%118.9k","Ilvl":231,"Ilvl %":31,"Active":"12.89%","DPS":382.2,"":""}],
                        "Oeil du Geôlier": [],
                        "The Nine": [],
                        "Ner'zhul": [],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":34,"Name":"Pestiférée","Amount":"2620472$16.07%2.62m","Ilvl":232,"Ilvl %":55,"Active":"99.41%","DPS":"9,035.7","":""},{"Parse %":34,"Name":"Rattataa","Amount":"2434940$14.93%2.43m","Ilvl":230,"Ilvl %":65,"Active":"97.95%","DPS":"8,396.0","":""},{"Parse %":39,"Name":"Likene","Amount":"2317887$14.21%2.32m","Ilvl":232,"Ilvl %":60,"Active":"99.45%","DPS":"7,992.4","":""},{"Parse %":20,"Name":"Mamyhanzo","Amount":"2070292$12.69%2.07m","Ilvl":229,"Ilvl %":38,"Active":"99.58%","DPS":"7,138.6","":""},{"Parse %":77,"Name":"Danori","Amount":"1855832$11.38%1.86m","Ilvl":235,"Ilvl %":89,"Active":"63.03%","DPS":"6,399.1","":""},{"Parse %":60,"Name":"Thakgrin","Amount":"1353272$8.30%1.35m","Ilvl":228,"Ilvl %":80,"Active":"76.93%","DPS":"4,666.2","":""},{"Parse %":86,"Name":"Dryhwèn","Amount":"1284095$7.87%1.28m","Ilvl":232,"Ilvl %":95,"Active":"77.52%","DPS":"4,427.7","":""},{"Parse %":7,"Name":"Mordus","Amount":"1196561$7.34%1.20m","Ilvl":228,"Ilvl %":11,"Active":"92.51%","DPS":"4,125.9","":""},{"Parse %":56,"Name":"Calanthea","Amount":"818233$5.02%818.2k","Ilvl":226,"Ilvl %":82,"Active":"73.85%","DPS":"2,821.4","":""},{"Parse %":68,"Name":"Kekzahl","Amount":"335412$2.06%335.4k","Ilvl":233,"Ilvl %":75,"Active":"30.05%","DPS":"1,156.5","":""},{"Parse %":"-","Name":"Petite putriciole","Amount":"22097$0.14%22.1k","Ilvl":"-","Ilvl %":"-","Active":"23.01%","DPS":76.2,"":""}],
                        "Oeil du Geôlier": [{"Parse %":26,"Name":"Mamyhanzo","Amount":"2095171$16.29%2.10m","Ilvl":230,"Ilvl %":49,"Active":"94.25%","DPS":"4,743.6","":""},{"Parse %":18,"Name":"Pestiférée","Amount":"1864781$14.49%1.86m","Ilvl":232,"Ilvl %":27,"Active":"96.43%","DPS":"4,222.0","":""},{"Parse %":18,"Name":"Rattataa","Amount":"1799936$13.99%1.80m","Ilvl":230,"Ilvl %":37,"Active":"93.13%","DPS":"4,075.2","":""},{"Parse %":17,"Name":"Likene","Amount":"1627558$12.65%1.63m","Ilvl":232,"Ilvl %":26,"Active":"85.01%","DPS":"3,684.9","":""},{"Parse %":69,"Name":"Danori","Amount":"1559302$12.12%1.56m","Ilvl":235,"Ilvl %":86,"Active":"92.74%","DPS":"3,530.4","":""},{"Parse %":12,"Name":"Mordus","Amount":"1385045$10.77%1.39m","Ilvl":228,"Ilvl %":25,"Active":"85.89%","DPS":"3,135.8","":""},{"Parse %":26,"Name":"Thakgrin","Amount":"1035081$8.05%1.04m","Ilvl":228,"Ilvl %":51,"Active":"90.32%","DPS":"2,343.5","":""},{"Parse %":82,"Name":"Dryhwèn","Amount":"948474$7.37%948.5k","Ilvl":232,"Ilvl %":94,"Active":"90.31%","DPS":"2,147.4","":""},{"Parse %":15,"Name":"Calanthea","Amount":"355633$2.76%355.6k","Ilvl":226,"Ilvl %":35,"Active":"68.52%","DPS":805.2,"":""},{"Parse %":49,"Name":"Kekzahl","Amount":"194301$1.51%194.3k","Ilvl":233,"Ilvl %":57,"Active":"39.62%","DPS":439.9,"":""}],
                        "The Nine": [{"Parse %":20,"Name":"Pestiférée","Amount":"2077877$14.69%2.08m","Ilvl":232,"Ilvl %":38,"Active":"99.55%","DPS":"5,331.4","":""},{"Parse %":23,"Name":"Likene","Amount":"2067019$14.61%2.07m","Ilvl":232,"Ilvl %":44,"Active":"96.09%","DPS":"5,303.6","":""},{"Parse %":19,"Name":"Mamyhanzo","Amount":"2023240$14.30%2.02m","Ilvl":230,"Ilvl %":39,"Active":"99.56%","DPS":"5,191.2","":""},{"Parse %":82,"Name":"Danori","Amount":"2015415$14.25%2.02m","Ilvl":235,"Ilvl %":96,"Active":"99.72%","DPS":"5,171.2","":""},{"Parse %":10,"Name":"Rattataa","Amount":"1974825$13.96%1.97m","Ilvl":230,"Ilvl %":24,"Active":"93.09%","DPS":"5,067.0","":""},{"Parse %":33,"Name":"Thakgrin","Amount":"1313278$9.29%1.31m","Ilvl":228,"Ilvl %":63,"Active":"99.61%","DPS":"3,369.6","":""},{"Parse %":6,"Name":"Mordus","Amount":"1062070$7.51%1.06m","Ilvl":228,"Ilvl %":9,"Active":"89.47%","DPS":"2,725.1","":""},{"Parse %":79,"Name":"Dryhwèn","Amount":"1011301$7.15%1.01m","Ilvl":232,"Ilvl %":94,"Active":"98.91%","DPS":"2,594.8","":""},{"Parse %":28,"Name":"Calanthea","Amount":"575297$4.07%575.3k","Ilvl":226,"Ilvl %":62,"Active":"87.23%","DPS":"1,476.1","":""},{"Parse %":22,"Name":"Kekzahl","Amount":"23566$0.17%23.6k","Ilvl":233,"Ilvl %":26,"Active":"6.67%","DPS":60.5,"":""}],
                        "Ner'zhul": [],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":46,"Name":"Pestiférée","Amount":"2724155$14.89%2.72m","Ilvl":232,"Ilvl %":76,"Active":"99.18%","DPS":"9,991.0","":""},{"Parse %":53,"Name":"Dread","Amount":"2541003$13.89%2.54m","Ilvl":236,"Ilvl %":59,"Active":"99.14%","DPS":"9,319.2","":""},{"Parse %":50,"Name":"Mamyhanzo","Amount":"2514305$13.74%2.51m","Ilvl":231,"Ilvl %":74,"Active":"99.11%","DPS":"9,221.3","":""},{"Parse %":49,"Name":"Likene","Amount":"2370099$12.95%2.37m","Ilvl":232,"Ilvl %":74,"Active":"98.66%","DPS":"8,692.4","":""},{"Parse %":35,"Name":"Rattataa","Amount":"2325059$12.71%2.33m","Ilvl":231,"Ilvl %":64,"Active":"75.67%","DPS":"8,527.3","":""},{"Parse %":80,"Name":"Danori","Amount":"1842255$10.07%1.84m","Ilvl":235,"Ilvl %":92,"Active":"85.30%","DPS":"6,756.6","":""},{"Parse %":45,"Name":"Thakgrin","Amount":"1091211$5.96%1.09m","Ilvl":228,"Ilvl %":68,"Active":"89.75%","DPS":"4,002.1","":""},{"Parse %":7,"Name":"Mordus","Amount":"1047242$5.72%1.05m","Ilvl":229,"Ilvl %":9,"Active":"82.70%","DPS":"3,840.8","":""},{"Parse %":67,"Name":"Dryhwèn","Amount":"897214$4.90%897.2k","Ilvl":232,"Ilvl %":82,"Active":"94.69%","DPS":"3,290.6","":""},{"Parse %":33,"Name":"Calanthea","Amount":"569508$3.11%569.5k","Ilvl":227,"Ilvl %":60,"Active":"91.23%","DPS":"2,088.7","":""},{"Parse %":73,"Name":"Kekzahl","Amount":"374677$2.05%374.7k","Ilvl":233,"Ilvl %":81,"Active":"44.43%","DPS":"1,374.1","":""}],
                        "Oeil du Geôlier": [{"Parse %":69,"Name":"Rattataa","Amount":"2180592$15.85%2.18m","Ilvl":231,"Ilvl %":98,"Active":"99.65%","DPS":"6,399.1","":""},{"Parse %":68,"Name":"Dread","Amount":"2028763$14.75%2.03m","Ilvl":236,"Ilvl %":79,"Active":"99.62%","DPS":"5,953.6","":""},{"Parse %":31,"Name":"Mamyhanzo","Amount":"1680623$12.22%1.68m","Ilvl":232,"Ilvl %":47,"Active":"96.02%","DPS":"4,931.9","":""},{"Parse %":30,"Name":"Pestiférée","Amount":"1673489$12.16%1.67m","Ilvl":232,"Ilvl %":55,"Active":"96.64%","DPS":"4,911.0","":""},{"Parse %":33,"Name":"Likene","Amount":"1577753$11.47%1.58m","Ilvl":232,"Ilvl %":56,"Active":"99.52%","DPS":"4,630.1","":""},{"Parse %":76,"Name":"Danori","Amount":"1297793$9.43%1.30m","Ilvl":235,"Ilvl %":92,"Active":"99.30%","DPS":"3,808.5","":""},{"Parse %":10,"Name":"Mordus","Amount":"1003731$7.30%1.00m","Ilvl":229,"Ilvl %":20,"Active":"89.27%","DPS":"2,945.5","":""},{"Parse %":45,"Name":"Thakgrin","Amount":"942370$6.85%942.4k","Ilvl":228,"Ilvl %":76,"Active":"96.08%","DPS":"2,765.5","":""},{"Parse %":90,"Name":"Dryhwèn","Amount":"843747$6.13%843.7k","Ilvl":232,"Ilvl %":97,"Active":"95.88%","DPS":"2,476.1","":""},{"Parse %":27,"Name":"Calanthea","Amount":"363372$2.64%363.4k","Ilvl":227,"Ilvl %":55,"Active":"86.65%","DPS":"1,066.3","":""},{"Parse %":52,"Name":"Kekzahl","Amount":"165952$1.21%166.0k","Ilvl":233,"Ilvl %":61,"Active":"48.85%","DPS":487,"":""}],
                        "The Nine": [{"Parse %":68,"Name":"Dread","Amount":"2352876$14.81%2.35m","Ilvl":237,"Ilvl %":76,"Active":"99.65%","DPS":"7,736.5","":""},{"Parse %":49,"Name":"Rattataa","Amount":"2291573$14.42%2.29m","Ilvl":231,"Ilvl %":92,"Active":"99.47%","DPS":"7,534.9","":""},{"Parse %":94,"Name":"Danori","Amount":"1862897$11.73%1.86m","Ilvl":235,"Ilvl %":99,"Active":"99.62%","DPS":"6,125.4","":""},{"Parse %":39,"Name":"Mamyhanzo","Amount":"1862772$11.72%1.86m","Ilvl":232,"Ilvl %":66,"Active":"99.39%","DPS":"6,125.0","":""},{"Parse %":33,"Name":"Pestiférée","Amount":"1855520$11.68%1.86m","Ilvl":232,"Ilvl %":67,"Active":"99.58%","DPS":"6,101.2","":""},{"Parse %":36,"Name":"Likene","Amount":"1835776$11.55%1.84m","Ilvl":232,"Ilvl %":70,"Active":"99.35%","DPS":"6,036.2","":""},{"Parse %":9,"Name":"Mordus","Amount":"1246256$7.84%1.25m","Ilvl":229,"Ilvl %":25,"Active":"94.80%","DPS":"4,097.8","":""},{"Parse %":48,"Name":"Thakgrin","Amount":"1139295$7.17%1.14m","Ilvl":229,"Ilvl %":77,"Active":"99.70%","DPS":"3,746.1","":""},{"Parse %":91,"Name":"Dryhwèn","Amount":"981483$6.18%981.5k","Ilvl":232,"Ilvl %":98,"Active":"94.75%","DPS":"3,227.2","":""},{"Parse %":27,"Name":"Calanthea","Amount":"437084$2.75%437.1k","Ilvl":227,"Ilvl %":58,"Active":"91.68%","DPS":"1,437.2","":""},{"Parse %":24,"Name":"Kekzahl","Amount":"22340$0.14%22.3k","Ilvl":233,"Ilvl %":30,"Active":"7.49%","DPS":73.5,"":""}],
                        "Ner'zhul": [{"Parse %":50,"Name":"Dread","Amount":"2131715$15.76%2.13m","Ilvl":237,"Ilvl %":75,"Active":"99.63%","DPS":"6,703.7","":""},{"Parse %":29,"Name":"Pestiférée","Amount":"1829303$13.52%1.83m","Ilvl":233,"Ilvl %":67,"Active":"99.67%","DPS":"5,752.7","":""},{"Parse %":30,"Name":"Likene","Amount":"1616915$11.95%1.62m","Ilvl":233,"Ilvl %":67,"Active":"92.36%","DPS":"5,084.8","":""},{"Parse %":59,"Name":"Danori","Amount":"1268666$9.38%1.27m","Ilvl":236,"Ilvl %":87,"Active":"99.40%","DPS":"3,989.6","":""},{"Parse %":13,"Name":"Mordus","Amount":"1186285$8.77%1.19m","Ilvl":229,"Ilvl %":43,"Active":"99.81%","DPS":"3,730.5","":""},{"Parse %":8,"Name":"Nicho","Amount":"1146210$8.47%1.15m","Ilvl":232,"Ilvl %":18,"Active":"49.66%","DPS":"3,604.5","":""},{"Parse %":9,"Name":"Kërronz","Amount":"1103908$8.16%1.10m","Ilvl":235,"Ilvl %":16,"Active":"75.49%","DPS":"3,471.5","":""},{"Parse %":33,"Name":"Thakgrin","Amount":"948242$7.01%948.2k","Ilvl":230,"Ilvl %":73,"Active":"99.67%","DPS":"2,982.0","":""},{"Parse %":89,"Name":"Dryhwèn","Amount":"930319$6.88%930.3k","Ilvl":232,"Ilvl %":99,"Active":"99.29%","DPS":"2,925.6","":""},{"Parse %":7,"Name":"Nightpumà","Amount":"821954$6.08%822.0k","Ilvl":236,"Ilvl %":7,"Active":"49.25%","DPS":"2,584.8","":""},{"Parse %":33,"Name":"Calanthea","Amount":"477885$3.53%477.9k","Ilvl":227,"Ilvl %":60,"Active":"96.14%","DPS":"1,502.8","":""},{"Parse %":32,"Name":"Taratata","Amount":"64013$0.47%64.0k","Ilvl":230,"Ilvl %":44,"Active":"35.20%","DPS":201.3,"":""}],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":41,"Name":"Pestiférée","Amount":"2953435$13.25%2.95m","Ilvl":233,"Ilvl %":77,"Active":"99.53%","DPS":"10,083.7","":""},{"Parse %":38,"Name":"Dread","Amount":"2505372$11.24%2.51m","Ilvl":237,"Ilvl %":56,"Active":"98.31%","DPS":"8,553.9","":""},{"Parse %":42,"Name":"Likene","Amount":"2484517$11.15%2.48m","Ilvl":233,"Ilvl %":77,"Active":"95.87%","DPS":"8,482.7","":""},{"Parse %":27,"Name":"Riuchine","Amount":"2369946$10.63%2.37m","Ilvl":228,"Ilvl %":76,"Active":"95.72%","DPS":"8,091.5","":""},{"Parse %":26,"Name":"Nightpumà","Amount":"2179765$9.78%2.18m","Ilvl":238,"Ilvl %":28,"Active":"99.07%","DPS":"7,442.2","":""},{"Parse %":16,"Name":"Mamyhanzo","Amount":"2121226$9.52%2.12m","Ilvl":232,"Ilvl %":34,"Active":"99.86%","DPS":"7,242.3","":""},{"Parse %":57,"Name":"Danori","Amount":"1689695$7.58%1.69m","Ilvl":238,"Ilvl %":74,"Active":"85.60%","DPS":"5,769.0","":""},{"Parse %":13,"Name":"Mordus","Amount":"1628665$7.31%1.63m","Ilvl":231,"Ilvl %":30,"Active":"99.46%","DPS":"5,560.6","":""},{"Parse %":8,"Name":"Nicho","Amount":"1609940$7.22%1.61m","Ilvl":235,"Ilvl %":11,"Active":"61.16%","DPS":"5,496.7","":""},{"Parse %":88,"Name":"Dryhwèn","Amount":"1446740$6.49%1.45m","Ilvl":233,"Ilvl %":96,"Active":"87.33%","DPS":"4,939.5","":""},{"Parse %":26,"Name":"Thakgrin","Amount":"1076807$4.83%1.08m","Ilvl":231,"Ilvl %":51,"Active":"85.77%","DPS":"3,676.5","":""},{"Parse %":21,"Name":"Kekzahl","Amount":"105378$0.47%105.4k","Ilvl":235,"Ilvl %":28,"Active":"23.34%","DPS":359.8,"":""},{"Parse %":32,"Name":"Taratata","Amount":"101347$0.45%101.3k","Ilvl":232,"Ilvl %":45,"Active":"35.25%","DPS":346,"":""},{"Parse %":"-","Name":"Petite putriciole","Amount":"16777$0.08%16.8k","Ilvl":"-","Ilvl %":"-","Active":"38.18%","DPS":57.3,"":""}],
                        "Oeil du Geôlier": [{"Parse %":62,"Name":"Dread","Amount":"1861175$11.03%1.86m","Ilvl":237,"Ilvl %":85,"Active":"99.51%","DPS":"6,009.1","":""},{"Parse %":43,"Name":"Nicho","Amount":"1770960$10.49%1.77m","Ilvl":235,"Ilvl %":78,"Active":"99.41%","DPS":"5,717.8","":""},{"Parse %":44,"Name":"Likene","Amount":"1623373$9.62%1.62m","Ilvl":233,"Ilvl %":81,"Active":"95.32%","DPS":"5,241.3","":""},{"Parse %":29,"Name":"Mamyhanzo","Amount":"1595791$9.46%1.60m","Ilvl":232,"Ilvl %":62,"Active":"99.62%","DPS":"5,152.3","":""},{"Parse %":32,"Name":"Pestiférée","Amount":"1585799$9.40%1.59m","Ilvl":233,"Ilvl %":69,"Active":"99.75%","DPS":"5,120.0","":""},{"Parse %":33,"Name":"Nightpumà","Amount":"1461886$8.66%1.46m","Ilvl":238,"Ilvl %":42,"Active":"99.61%","DPS":"4,719.9","":""},{"Parse %":21,"Name":"Riuchine","Amount":"1379162$8.17%1.38m","Ilvl":228,"Ilvl %":70,"Active":"99.85%","DPS":"4,452.8","":""},{"Parse %":20,"Name":"Kërronz","Amount":"1236124$7.32%1.24m","Ilvl":235,"Ilvl %":36,"Active":"99.26%","DPS":"3,991.0","":""},{"Parse %":67,"Name":"Danori","Amount":"1222267$7.24%1.22m","Ilvl":238,"Ilvl %":85,"Active":"99.78%","DPS":"3,946.3","":""},{"Parse %":20,"Name":"Mordus","Amount":"1202679$7.13%1.20m","Ilvl":231,"Ilvl %":47,"Active":"87.24%","DPS":"3,883.0","":""},{"Parse %":45,"Name":"Thakgrin","Amount":"956927$5.67%956.9k","Ilvl":232,"Ilvl %":75,"Active":"99.69%","DPS":"3,089.6","":""},{"Parse %":88,"Name":"Dryhwèn","Amount":"794308$4.71%794.3k","Ilvl":233,"Ilvl %":97,"Active":"91.87%","DPS":"2,564.5","":""},{"Parse %":44,"Name":"Kekzahl","Amount":"149170$0.88%149.2k","Ilvl":235,"Ilvl %":57,"Active":"23.09%","DPS":481.6,"":""},{"Parse %":21,"Name":"Taratata","Amount":"36079$0.21%36.1k","Ilvl":232,"Ilvl %":31,"Active":"17.76%","DPS":116.5,"":""}],
                        "The Nine": [{"Parse %":62,"Name":"Dread","Amount":"2364862$11.22%2.36m","Ilvl":237,"Ilvl %":85,"Active":"99.37%","DPS":"7,918.4","":""},{"Parse %":51,"Name":"Likene","Amount":"2089575$9.92%2.09m","Ilvl":233,"Ilvl %":92,"Active":"95.93%","DPS":"6,996.7","":""},{"Parse %":41,"Name":"Nicho","Amount":"2051364$9.74%2.05m","Ilvl":235,"Ilvl %":80,"Active":"99.75%","DPS":"6,868.7","":""},{"Parse %":41,"Name":"Kërronz","Amount":"2047717$9.72%2.05m","Ilvl":234,"Ilvl %":84,"Active":"99.38%","DPS":"6,856.5","":""},{"Parse %":39,"Name":"Pestiférée","Amount":"2007492$9.53%2.01m","Ilvl":233,"Ilvl %":84,"Active":"99.07%","DPS":"6,721.8","":""},{"Parse %":32,"Name":"Nightpumà","Amount":"1764459$8.37%1.76m","Ilvl":238,"Ilvl %":39,"Active":"99.25%","DPS":"5,908.1","":""},{"Parse %":23,"Name":"Mamyhanzo","Amount":"1747183$8.29%1.75m","Ilvl":233,"Ilvl %":52,"Active":"99.50%","DPS":"5,850.2","":""},{"Parse %":81,"Name":"Danori","Amount":"1689882$8.02%1.69m","Ilvl":238,"Ilvl %":97,"Active":"99.74%","DPS":"5,658.3","":""},{"Parse %":26,"Name":"Mordus","Amount":"1650295$7.83%1.65m","Ilvl":231,"Ilvl %":68,"Active":"95.84%","DPS":"5,525.8","":""},{"Parse %":20,"Name":"Riuchine","Amount":"1526294$7.24%1.53m","Ilvl":228,"Ilvl %":68,"Active":"99.43%","DPS":"5,110.6","":""},{"Parse %":53,"Name":"Thakgrin","Amount":"1270424$6.03%1.27m","Ilvl":232,"Ilvl %":85,"Active":"99.90%","DPS":"4,253.8","":""},{"Parse %":76,"Name":"Dryhwèn","Amount":"808803$3.84%808.8k","Ilvl":233,"Ilvl %":93,"Active":"99.18%","DPS":"2,708.2","":""},{"Parse %":26,"Name":"Taratata","Amount":"46771$0.22%46.8k","Ilvl":232,"Ilvl %":36,"Active":"31.89%","DPS":156.6,"":""},{"Parse %":9,"Name":"Kekzahl","Amount":"5215$0.02%5.2k","Ilvl":235,"Ilvl %":12,"Active":"2.68%","DPS":17.5,"":""}],
                        "Ner'zhul": [{"Parse %":36,"Name":"Nicho","Amount":"1665629$15.41%1.67m","Ilvl":235,"Ilvl %":71,"Active":"99.09%","DPS":"6,206.0","":""},{"Parse %":27,"Name":"Pestiférée","Amount":"1523500$14.10%1.52m","Ilvl":233,"Ilvl %":65,"Active":"99.64%","DPS":"5,676.4","":""},{"Parse %":15,"Name":"Evanana","Amount":"1288091$11.92%1.29m","Ilvl":228,"Ilvl %":47,"Active":"98.78%","DPS":"4,799.3","":""},{"Parse %":22,"Name":"Nightpumà","Amount":"1265381$11.71%1.27m","Ilvl":238,"Ilvl %":25,"Active":"99.25%","DPS":"4,714.7","":""},{"Parse %":21,"Name":"Likene","Amount":"1216579$11.26%1.22m","Ilvl":235,"Ilvl %":41,"Active":"94.23%","DPS":"4,532.8","":""},{"Parse %":51,"Name":"Danori","Amount":"1005600$9.31%1.01m","Ilvl":238,"Ilvl %":74,"Active":"99.44%","DPS":"3,746.8","":""},{"Parse %":43,"Name":"Thakgrin","Amount":"876657$8.11%876.7k","Ilvl":232,"Ilvl %":73,"Active":"99.32%","DPS":"3,266.3","":""},{"Parse %":9,"Name":"Mordus","Amount":"843561$7.81%843.6k","Ilvl":232,"Ilvl %":19,"Active":"99.09%","DPS":"3,143.0","":""},{"Parse %":89,"Name":"Dryhwèn","Amount":"788255$7.29%788.3k","Ilvl":233,"Ilvl %":97,"Active":"98.75%","DPS":"2,937.0","":""},{"Parse %":21,"Name":"Calanthea","Amount":"333666$3.09%333.7k","Ilvl":228,"Ilvl %":42,"Active":"90.88%","DPS":"1,243.2","":""}],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":67,"Name":"Pestiférée","Amount":"2833393$13.96%2.83m","Ilvl":233,"Ilvl %":96,"Active":"98.82%","DPS":"12,563.5","":""},{"Parse %":66,"Name":"Nicho","Amount":"2829777$13.94%2.83m","Ilvl":236,"Ilvl %":92,"Active":"99.57%","DPS":"12,547.5","":""},{"Parse %":69,"Name":"Kërronz","Amount":"2397274$11.81%2.40m","Ilvl":236,"Ilvl %":92,"Active":"99.58%","DPS":"10,629.7","":""},{"Parse %":22,"Name":"Mamyhanzo","Amount":"1812135$8.93%1.81m","Ilvl":234,"Ilvl %":42,"Active":"99.33%","DPS":"8,035.2","":""},{"Parse %":21,"Name":"Evanana","Amount":"1783869$8.79%1.78m","Ilvl":228,"Ilvl %":61,"Active":"98.71%","DPS":"7,909.9","":""},{"Parse %":32,"Name":"Noffs-DPS","Amount":"1757017$8.66%1.76m","Ilvl":227,"Ilvl %":81,"Active":"95.09%","DPS":"7,790.8","":""},{"Parse %":24,"Name":"Likene","Amount":"1580425$7.79%1.58m","Ilvl":238,"Ilvl %":31,"Active":"86.36%","DPS":"7,007.8","":""},{"Parse %":19,"Name":"Nightpumà","Amount":"1529045$7.53%1.53m","Ilvl":240,"Ilvl %":13,"Active":"97.87%","DPS":"6,779.9","":""},{"Parse %":67,"Name":"Danori","Amount":"1454801$7.17%1.45m","Ilvl":238,"Ilvl %":83,"Active":"88.91%","DPS":"6,450.7","":""},{"Parse %":75,"Name":"Thakgrin","Amount":"1427387$7.03%1.43m","Ilvl":234,"Ilvl %":90,"Active":"95.34%","DPS":"6,329.2","":""},{"Parse %":52,"Name":"Dryhwèn","Amount":"660397$3.25%660.4k","Ilvl":232,"Ilvl %":72,"Active":"82.33%","DPS":"2,928.3","":""},{"Parse %":70,"Name":"Taratata","Amount":"219299$1.08%219.3k","Ilvl":232,"Ilvl %":82,"Active":"31.97%","DPS":972.4,"":""},{"Parse %":"-","Name":"Petite putriciole","Amount":"14131$0.07%14.1k","Ilvl":"-","Ilvl %":"-","Active":"7.88%","DPS":62.7,"":""}],
                        "Oeil du Geôlier": [{"Parse %":58,"Name":"Nicho","Amount":"1804929$12.47%1.80m","Ilvl":236,"Ilvl %":90,"Active":"99.46%","DPS":"6,432.1","":""},{"Parse %":54,"Name":"Likene","Amount":"1605967$11.10%1.61m","Ilvl":238,"Ilvl %":73,"Active":"95.36%","DPS":"5,723.0","":""},{"Parse %":50,"Name":"Kërronz","Amount":"1564653$10.81%1.56m","Ilvl":236,"Ilvl %":82,"Active":"99.67%","DPS":"5,575.8","":""},{"Parse %":37,"Name":"Mamyhanzo","Amount":"1547612$10.69%1.55m","Ilvl":234,"Ilvl %":70,"Active":"95.44%","DPS":"5,515.1","":""},{"Parse %":39,"Name":"Pestiférée","Amount":"1544421$10.67%1.54m","Ilvl":233,"Ilvl %":80,"Active":"99.50%","DPS":"5,503.7","":""},{"Parse %":43,"Name":"Nightpumà","Amount":"1465147$10.12%1.47m","Ilvl":240,"Ilvl %":45,"Active":"94.31%","DPS":"5,221.2","":""},{"Parse %":76,"Name":"Danori","Amount":"1212574$8.38%1.21m","Ilvl":238,"Ilvl %":93,"Active":"94.92%","DPS":"4,321.1","":""},{"Parse %":9,"Name":"Evanana","Amount":"1064701$7.36%1.06m","Ilvl":228,"Ilvl %":30,"Active":"84.99%","DPS":"3,794.2","":""},{"Parse %":19,"Name":"Noffs-DPS","Amount":"1004964$6.94%1.00m","Ilvl":227,"Ilvl %":59,"Active":"87.09%","DPS":"3,581.3","":""},{"Parse %":56,"Name":"Thakgrin","Amount":"947092$6.54%947.1k","Ilvl":234,"Ilvl %":80,"Active":"99.52%","DPS":"3,375.1","":""},{"Parse %":82,"Name":"Dryhwèn","Amount":"655105$4.53%655.1k","Ilvl":232,"Ilvl %":96,"Active":"94.87%","DPS":"2,334.5","":""},{"Parse %":31,"Name":"Taratata","Amount":"53913$0.37%53.9k","Ilvl":232,"Ilvl %":44,"Active":"33.31%","DPS":192.1,"":""}],
                        "The Nine": [{"Parse %":49,"Name":"Nicho","Amount":"2103675$11.94%2.10m","Ilvl":236,"Ilvl %":83,"Active":"99.49%","DPS":"7,316.9","":""},{"Parse %":42,"Name":"Kërronz","Amount":"1989841$11.30%1.99m","Ilvl":236,"Ilvl %":76,"Active":"99.55%","DPS":"6,921.0","":""},{"Parse %":49,"Name":"Likene","Amount":"1982207$11.25%1.98m","Ilvl":238,"Ilvl %":74,"Active":"98.68%","DPS":"6,894.4","":""},{"Parse %":32,"Name":"Pestiférée","Amount":"1830130$10.39%1.83m","Ilvl":233,"Ilvl %":75,"Active":"93.65%","DPS":"6,365.5","":""},{"Parse %":39,"Name":"Nightpumà","Amount":"1799382$10.21%1.80m","Ilvl":240,"Ilvl %":37,"Active":"98.17%","DPS":"6,258.5","":""},{"Parse %":28,"Name":"Mamyhanzo","Amount":"1777179$10.09%1.78m","Ilvl":234,"Ilvl %":59,"Active":"99.39%","DPS":"6,181.3","":""},{"Parse %":19,"Name":"Evanana","Amount":"1602311$9.10%1.60m","Ilvl":228,"Ilvl %":63,"Active":"98.36%","DPS":"5,573.1","":""},{"Parse %":70,"Name":"Danori","Amount":"1460498$8.29%1.46m","Ilvl":238,"Ilvl %":90,"Active":"99.50%","DPS":"5,079.9","":""},{"Parse %":60,"Name":"Thakgrin","Amount":"1279706$7.26%1.28m","Ilvl":234,"Ilvl %":85,"Active":"99.43%","DPS":"4,451.0","":""},{"Parse %":13,"Name":"Noffs-DPS","Amount":"1186365$6.73%1.19m","Ilvl":227,"Ilvl %":47,"Active":"98.94%","DPS":"4,126.4","":""},{"Parse %":45,"Name":"Dryhwèn","Amount":"554972$3.15%555.0k","Ilvl":232,"Ilvl %":68,"Active":"82.38%","DPS":"1,930.3","":""},{"Parse %":28,"Name":"Taratata","Amount":"49094$0.28%49.1k","Ilvl":232,"Ilvl %":39,"Active":"22.81%","DPS":170.8,"":""}],
                        "Ner'zhul": [{"Parse %":44,"Name":"Nicho","Amount":"1588099$12.32%1.59m","Ilvl":236,"Ilvl %":78,"Active":"99.32%","DPS":"6,663.5","":""},{"Parse %":48,"Name":"Likene","Amount":"1438304$11.16%1.44m","Ilvl":238,"Ilvl %":71,"Active":"94.92%","DPS":"6,035.0","":""},{"Parse %":30,"Name":"Mamyhanzo","Amount":"1422181$11.03%1.42m","Ilvl":234,"Ilvl %":62,"Active":"99.49%","DPS":"5,967.4","":""},{"Parse %":31,"Name":"Pestiférée","Amount":"1404450$10.89%1.40m","Ilvl":233,"Ilvl %":71,"Active":"99.03%","DPS":"5,893.0","":""},{"Parse %":39,"Name":"Kërronz","Amount":"1393843$10.81%1.39m","Ilvl":237,"Ilvl %":68,"Active":"98.91%","DPS":"5,848.4","":""},{"Parse %":35,"Name":"Nightpumà","Amount":"1297508$10.07%1.30m","Ilvl":240,"Ilvl %":34,"Active":"99.08%","DPS":"5,444.2","":""},{"Parse %":16,"Name":"Evanana","Amount":"1157654$8.98%1.16m","Ilvl":229,"Ilvl %":45,"Active":"98.47%","DPS":"4,857.4","":""},{"Parse %":16,"Name":"Noffs-DPS","Amount":"942500$7.31%942.5k","Ilvl":227,"Ilvl %":58,"Active":"88.67%","DPS":"3,954.7","":""},{"Parse %":51,"Name":"Danori","Amount":"889249$6.90%889.2k","Ilvl":238,"Ilvl %":73,"Active":"94.95%","DPS":"3,731.2","":""},{"Parse %":49,"Name":"Thakgrin","Amount":"815918$6.33%815.9k","Ilvl":234,"Ilvl %":76,"Active":"98.30%","DPS":"3,423.5","":""},{"Parse %":64,"Name":"Dryhwèn","Amount":"510112$3.96%510.1k","Ilvl":232,"Ilvl %":89,"Active":"87.15%","DPS":"2,140.4","":""},{"Parse %":23,"Name":"Taratata","Amount":"31181$0.24%31.2k","Ilvl":232,"Ilvl %":32,"Active":"13.43%","DPS":130.8,"":""}],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":61,"Name":"Nicho","Amount":"3241976$13.37%3.24m","Ilvl":237,"Ilvl %":86,"Active":"99.35%","DPS":"12,003.8","":""},{"Parse %":48,"Name":"Pestiférée","Amount":"2909027$12.00%2.91m","Ilvl":233,"Ilvl %":85,"Active":"99.27%","DPS":"10,771.0","":""},{"Parse %":55,"Name":"Rattataa","Amount":"2892333$11.93%2.89m","Ilvl":237,"Ilvl %":81,"Active":"91.86%","DPS":"10,709.2","":""},{"Parse %":58,"Name":"Nightpumà","Amount":"2637460$10.88%2.64m","Ilvl":240,"Ilvl %":64,"Active":"98.87%","DPS":"9,765.5","":""},{"Parse %":49,"Name":"Kërronz","Amount":"2455908$10.13%2.46m","Ilvl":240,"Ilvl %":59,"Active":"96.00%","DPS":"9,093.3","":""},{"Parse %":23,"Name":"Mamyhanzo","Amount":"2183875$9.01%2.18m","Ilvl":234,"Ilvl %":43,"Active":"99.27%","DPS":"8,086.1","":""},{"Parse %":78,"Name":"Danori","Amount":"1978651$8.16%1.98m","Ilvl":238,"Ilvl %":92,"Active":"82.54%","DPS":"7,326.2","":""},{"Parse %":11,"Name":"Likene","Amount":"1445224$5.96%1.45m","Ilvl":238,"Ilvl %":10,"Active":"75.18%","DPS":"5,351.1","":""},{"Parse %":54,"Name":"Thakgrin","Amount":"1349355$5.56%1.35m","Ilvl":234,"Ilvl %":75,"Active":"95.84%","DPS":"4,996.1","":""},{"Parse %":79,"Name":"Dryhwèn","Amount":"1120678$4.62%1.12m","Ilvl":234,"Ilvl %":89,"Active":"85.37%","DPS":"4,149.4","":""},{"Parse %":5,"Name":"Evanana","Amount":"923830$3.81%923.8k","Ilvl":231,"Ilvl %":7,"Active":"53.94%","DPS":"3,420.6","":""},{"Parse %":37,"Name":"Calanthea","Amount":"648150$2.67%648.2k","Ilvl":229,"Ilvl %":64,"Active":"85.81%","DPS":"2,399.9","":""},{"Parse %":2,"Name":"Noffs-DPS","Amount":"326472$1.35%326.5k","Ilvl":233,"Ilvl %":3,"Active":"8.79%","DPS":"1,208.8","":""},{"Parse %":31,"Name":"Kekzahl","Amount":"135800$0.56%135.8k","Ilvl":236,"Ilvl %":40,"Active":"19.84%","DPS":502.8,"":""}],
                        "Oeil du Geôlier": [{"Parse %":56,"Name":"Rattataa","Amount":"1739126$10.39%1.74m","Ilvl":237,"Ilvl %":86,"Active":"95.91%","DPS":"6,248.9","":""},{"Parse %":49,"Name":"Nicho","Amount":"1670585$9.98%1.67m","Ilvl":237,"Ilvl %":76,"Active":"99.19%","DPS":"6,002.6","":""},{"Parse %":47,"Name":"Pestiférée","Amount":"1645024$9.83%1.65m","Ilvl":233,"Ilvl %":89,"Active":"99.49%","DPS":"5,910.8","":""},{"Parse %":45,"Name":"Mamyhanzo","Amount":"1621571$9.69%1.62m","Ilvl":234,"Ilvl %":80,"Active":"94.66%","DPS":"5,826.5","":""},{"Parse %":55,"Name":"Nightpumà","Amount":"1614598$9.65%1.61m","Ilvl":240,"Ilvl %":63,"Active":"94.93%","DPS":"5,801.4","":""},{"Parse %":39,"Name":"Likene","Amount":"1376508$8.22%1.38m","Ilvl":240,"Ilvl %":41,"Active":"94.36%","DPS":"4,946.0","":""},{"Parse %":39,"Name":"Noffs-DPS","Amount":"1342781$8.02%1.34m","Ilvl":234,"Ilvl %":72,"Active":"95.43%","DPS":"4,824.8","":""},{"Parse %":25,"Name":"Kërronz","Amount":"1204710$7.20%1.20m","Ilvl":240,"Ilvl %":26,"Active":"89.06%","DPS":"4,328.7","":""},{"Parse %":12,"Name":"Evanana","Amount":"1119329$6.69%1.12m","Ilvl":231,"Ilvl %":28,"Active":"94.24%","DPS":"4,021.9","":""},{"Parse %":61,"Name":"Danori","Amount":"1048498$6.26%1.05m","Ilvl":239,"Ilvl %":76,"Active":"95.72%","DPS":"3,767.4","":""},{"Parse %":96,"Name":"Dryhwèn","Amount":"935376$5.59%935.4k","Ilvl":234,"Ilvl %":99,"Active":"89.07%","DPS":"3,360.9","":""},{"Parse %":50,"Name":"Thakgrin","Amount":"900276$5.38%900.3k","Ilvl":234,"Ilvl %":74,"Active":"98.69%","DPS":"3,234.8","":""},{"Parse %":23,"Name":"Calanthea","Amount":"286860$1.71%286.9k","Ilvl":229,"Ilvl %":44,"Active":"81.44%","DPS":"1,030.7","":""},{"Parse %":63,"Name":"Kekzahl","Amount":"231362$1.38%231.4k","Ilvl":236,"Ilvl %":75,"Active":"59.26%","DPS":831.3,"":""}],
                        "The Nine": [{"Parse %":63,"Name":"Rattataa","Amount":"2955201$14.19%2.96m","Ilvl":237,"Ilvl %":95,"Active":"99.64%","DPS":"8,780.5","":""},{"Parse %":40,"Name":"Kërronz","Amount":"2299332$11.04%2.30m","Ilvl":240,"Ilvl %":49,"Active":"99.36%","DPS":"6,831.8","":""},{"Parse %":47,"Name":"Nightpumà","Amount":"2246838$10.79%2.25m","Ilvl":240,"Ilvl %":51,"Active":"98.92%","DPS":"6,675.8","":""},{"Parse %":36,"Name":"Pestiférée","Amount":"2217460$10.65%2.22m","Ilvl":233,"Ilvl %":81,"Active":"99.51%","DPS":"6,588.5","":""},{"Parse %":26,"Name":"Mamyhanzo","Amount":"2031708$9.76%2.03m","Ilvl":234,"Ilvl %":54,"Active":"99.61%","DPS":"6,036.6","":""},{"Parse %":12,"Name":"Evanana","Amount":"1658452$7.96%1.66m","Ilvl":231,"Ilvl %":32,"Active":"98.44%","DPS":"4,927.6","":""},{"Parse %":64,"Name":"Danori","Amount":"1635836$7.86%1.64m","Ilvl":239,"Ilvl %":82,"Active":"99.58%","DPS":"4,860.4","":""},{"Parse %":20,"Name":"Noffs-DPS","Amount":"1632620$7.84%1.63m","Ilvl":234,"Ilvl %":39,"Active":"90.74%","DPS":"4,850.8","":""},{"Parse %":48,"Name":"Thakgrin","Amount":"1384419$6.65%1.38m","Ilvl":234,"Ilvl %":74,"Active":"99.73%","DPS":"4,113.4","":""},{"Parse %":5,"Name":"Nicho","Amount":"815796$3.92%815.8k","Ilvl":237,"Ilvl %":5,"Active":"43.62%","DPS":"2,423.9","":""},{"Parse %":65,"Name":"Dryhwèn","Amount":"809833$3.89%809.8k","Ilvl":232,"Ilvl %":86,"Active":"86.57%","DPS":"2,406.2","":""},{"Parse %":5,"Name":"Likene","Amount":"676842$3.25%676.8k","Ilvl":240,"Ilvl %":4,"Active":"26.58%","DPS":"2,011.0","":""},{"Parse %":21,"Name":"Calanthea","Amount":"459653$2.21%459.7k","Ilvl":229,"Ilvl %":42,"Active":"94.57%","DPS":"1,365.7","":""}],
                        "Ner'zhul": [{"Parse %":56,"Name":"Rattataa","Amount":"1762399$12.42%1.76m","Ilvl":237,"Ilvl %":89,"Active":"99.48%","DPS":"7,373.3","":""},{"Parse %":49,"Name":"Nicho","Amount":"1662578$11.71%1.66m","Ilvl":237,"Ilvl %":81,"Active":"99.47%","DPS":"6,955.7","":""},{"Parse %":34,"Name":"Mamyhanzo","Amount":"1479335$10.42%1.48m","Ilvl":234,"Ilvl %":70,"Active":"99.25%","DPS":"6,189.1","":""},{"Parse %":42,"Name":"Kërronz","Amount":"1435460$10.11%1.44m","Ilvl":240,"Ilvl %":55,"Active":"99.73%","DPS":"6,005.5","":""},{"Parse %":31,"Name":"Pestiférée","Amount":"1416242$9.98%1.42m","Ilvl":233,"Ilvl %":72,"Active":"98.99%","DPS":"5,925.1","":""},{"Parse %":41,"Name":"Nightpumà","Amount":"1377630$9.71%1.38m","Ilvl":240,"Ilvl %":43,"Active":"98.57%","DPS":"5,763.6","":""},{"Parse %":36,"Name":"Noffs-DPS","Amount":"1285091$9.05%1.29m","Ilvl":234,"Ilvl %":69,"Active":"98.90%","DPS":"5,376.4","":""},{"Parse %":16,"Name":"Evanana","Amount":"1174846$8.28%1.17m","Ilvl":231,"Ilvl %":40,"Active":"98.60%","DPS":"4,915.2","":""},{"Parse %":51,"Name":"Danori","Amount":"894229$6.30%894.2k","Ilvl":239,"Ilvl %":68,"Active":"98.53%","DPS":"3,741.2","":""},{"Parse %":53,"Name":"Thakgrin","Amount":"846037$5.96%846.0k","Ilvl":234,"Ilvl %":80,"Active":"99.71%","DPS":"3,539.6","":""},{"Parse %":60,"Name":"Dryhwèn","Amount":"488563$3.44%488.6k","Ilvl":232,"Ilvl %":86,"Active":"89.82%","DPS":"2,044.0","":""},{"Parse %":28,"Name":"Calanthea","Amount":"336964$2.37%337.0k","Ilvl":229,"Ilvl %":51,"Active":"98.83%","DPS":"1,409.8","":""},{"Parse %":24,"Name":"Kekzahl","Amount":"33615$0.24%33.6k","Ilvl":236,"Ilvl %":32,"Active":"13.15%","DPS":140.6,"":""}],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":72,"Name":"Nicho","Amount":"3710036$15.29%3.71m","Ilvl":239,"Ilvl %":90,"Active":"98.81%","DPS":"13,181.2","":""},{"Parse %":36,"Name":"Pestiférée","Amount":"2729851$11.25%2.73m","Ilvl":233,"Ilvl %":72,"Active":"99.28%","DPS":"9,698.8","":""},{"Parse %":39,"Name":"Rattataa","Amount":"2644759$10.90%2.64m","Ilvl":240,"Ilvl %":47,"Active":"98.86%","DPS":"9,396.4","":""},{"Parse %":46,"Name":"Nightpumà","Amount":"2510489$10.35%2.51m","Ilvl":242,"Ilvl %":37,"Active":"98.48%","DPS":"8,919.4","":""},{"Parse %":31,"Name":"Blläck","Amount":"2028341$8.36%2.03m","Ilvl":223,"Ilvl %":87,"Active":"88.80%","DPS":"7,206.4","":""},{"Parse %":76,"Name":"Danori","Amount":"2014507$8.30%2.01m","Ilvl":240,"Ilvl %":86,"Active":"87.36%","DPS":"7,157.2","":""},{"Parse %":21,"Name":"Likene","Amount":"1873188$7.72%1.87m","Ilvl":239,"Ilvl %":22,"Active":"94.87%","DPS":"6,655.2","":""},{"Parse %":10,"Name":"Ayano","Amount":"1644164$6.78%1.64m","Ilvl":227,"Ilvl %":40,"Active":"98.96%","DPS":"5,841.5","":""},{"Parse %":58,"Name":"Noffs","Amount":"1464477$6.04%1.46m","Ilvl":234,"Ilvl %":79,"Active":"94.09%","DPS":"5,203.1","":""},{"Parse %":5,"Name":"Mamyhanzo","Amount":"1039684$4.28%1.04m","Ilvl":234,"Ilvl %":6,"Active":"34.79%","DPS":"3,693.8","":""},{"Parse %":68,"Name":"Dryhwèn","Amount":"1001407$4.13%1.00m","Ilvl":233,"Ilvl %":83,"Active":"84.25%","DPS":"3,557.9","":""},{"Parse %":49,"Name":"Calanthea","Amount":"788755$3.25%788.8k","Ilvl":229,"Ilvl %":75,"Active":"87.05%","DPS":"2,802.3","":""},{"Parse %":3,"Name":"Kërronz","Amount":"511971$2.11%512.0k","Ilvl":241,"Ilvl %":3,"Active":"15.38%","DPS":"1,819.0","":""},{"Parse %":57,"Name":"Kekzahl","Amount":"303761$1.25%303.8k","Ilvl":236,"Ilvl %":68,"Active":"7.05%","DPS":"1,079.2","":""}],
                        "Oeil du Geôlier": [{"Parse %":77,"Name":"Rattataa","Amount":"1995641$11.89%2.00m","Ilvl":240,"Ilvl %":95,"Active":"94.56%","DPS":"7,379.1","":""},{"Parse %":59,"Name":"Nicho","Amount":"1754849$10.46%1.75m","Ilvl":239,"Ilvl %":80,"Active":"90.84%","DPS":"6,488.8","":""},{"Parse %":61,"Name":"Likene","Amount":"1643219$9.79%1.64m","Ilvl":239,"Ilvl %":78,"Active":"91.38%","DPS":"6,076.0","":""},{"Parse %":58,"Name":"Nightpumà","Amount":"1612898$9.61%1.61m","Ilvl":242,"Ilvl %":54,"Active":"94.54%","DPS":"5,963.9","":""},{"Parse %":47,"Name":"Mamyhanzo","Amount":"1599012$9.53%1.60m","Ilvl":234,"Ilvl %":82,"Active":"95.04%","DPS":"5,912.5","":""},{"Parse %":45,"Name":"Pestiférée","Amount":"1573653$9.38%1.57m","Ilvl":233,"Ilvl %":87,"Active":"99.78%","DPS":"5,818.8","":""},{"Parse %":44,"Name":"Kërronz","Amount":"1424260$8.49%1.42m","Ilvl":241,"Ilvl %":47,"Active":"95.22%","DPS":"5,266.4","":""},{"Parse %":80,"Name":"Danori","Amount":"1213710$7.23%1.21m","Ilvl":240,"Ilvl %":92,"Active":"95.41%","DPS":"4,487.8","":""},{"Parse %":38,"Name":"Blläck","Amount":"1139397$6.79%1.14m","Ilvl":224,"Ilvl %":87,"Active":"94.10%","DPS":"4,213.1","":""},{"Parse %":12,"Name":"Ayano","Amount":"923466$5.50%923.5k","Ilvl":227,"Ilvl %":44,"Active":"93.55%","DPS":"3,414.6","":""},{"Parse %":47,"Name":"Noffs","Amount":"854444$5.09%854.4k","Ilvl":234,"Ilvl %":71,"Active":"85.67%","DPS":"3,159.4","":""},{"Parse %":88,"Name":"Dryhwèn","Amount":"693619$4.13%693.6k","Ilvl":233,"Ilvl %":97,"Active":"80.80%","DPS":"2,564.7","":""},{"Parse %":19,"Name":"Calanthea","Amount":"254131$1.51%254.1k","Ilvl":229,"Ilvl %":39,"Active":"62.18%","DPS":939.7,"":""},{"Parse %":38,"Name":"Kekzahl","Amount":"101561$0.61%101.6k","Ilvl":236,"Ilvl %":49,"Active":"37.64%","DPS":375.5,"":""}],
                        "The Nine": [{"Parse %":60,"Name":"Rattataa","Amount":"2560643$12.30%2.56m","Ilvl":240,"Ilvl %":82,"Active":"99.24%","DPS":"8,613.4","":""},{"Parse %":61,"Name":"Nightpumà","Amount":"2211184$10.62%2.21m","Ilvl":242,"Ilvl %":60,"Active":"98.91%","DPS":"7,437.9","":""},{"Parse %":45,"Name":"Kërronz","Amount":"2097768$10.07%2.10m","Ilvl":241,"Ilvl %":50,"Active":"99.36%","DPS":"7,056.4","":""},{"Parse %":46,"Name":"Likene","Amount":"2005549$9.63%2.01m","Ilvl":239,"Ilvl %":64,"Active":"99.47%","DPS":"6,746.2","":""},{"Parse %":34,"Name":"Mamyhanzo","Amount":"1923470$9.24%1.92m","Ilvl":234,"Ilvl %":70,"Active":"99.21%","DPS":"6,470.1","":""},{"Parse %":34,"Name":"Pestiférée","Amount":"1918238$9.21%1.92m","Ilvl":233,"Ilvl %":78,"Active":"99.21%","DPS":"6,452.5","":""},{"Parse %":75,"Name":"Danori","Amount":"1580858$7.59%1.58m","Ilvl":240,"Ilvl %":91,"Active":"99.60%","DPS":"5,317.6","":""},{"Parse %":18,"Name":"Blläck","Amount":"1387934$6.67%1.39m","Ilvl":224,"Ilvl %":70,"Active":"75.33%","DPS":"4,668.7","":""},{"Parse %":11,"Name":"Nicho","Amount":"1353665$6.50%1.35m","Ilvl":239,"Ilvl %":9,"Active":"68.55%","DPS":"4,553.4","":""},{"Parse %":59,"Name":"Noffs","Amount":"1319314$6.34%1.32m","Ilvl":234,"Ilvl %":85,"Active":"99.55%","DPS":"4,437.8","":""},{"Parse %":15,"Name":"Ayano","Amount":"1297399$6.23%1.30m","Ilvl":227,"Ilvl %":57,"Active":"97.82%","DPS":"4,364.1","":""},{"Parse %":70,"Name":"Dryhwèn","Amount":"756355$3.63%756.4k","Ilvl":233,"Ilvl %":90,"Active":"93.99%","DPS":"2,544.2","":""},{"Parse %":20,"Name":"Calanthea","Amount":"402011$1.93%402.0k","Ilvl":229,"Ilvl %":41,"Active":"79.19%","DPS":"1,352.3","":""},{"Parse %":11,"Name":"Kekzahl","Amount":"9030$0.04%9.0k","Ilvl":236,"Ilvl %":15,"Active":"2.39%","DPS":30.4,"":""}],
                        "Ner'zhul": [{"Parse %":69,"Name":"Rattataa","Amount":"1793141$11.81%1.79m","Ilvl":240,"Ilvl %":91,"Active":"98.96%","DPS":"8,129.0","":""},{"Parse %":55,"Name":"Pestiférée","Amount":"1616185$10.65%1.62m","Ilvl":233,"Ilvl %":95,"Active":"99.72%","DPS":"7,326.8","":""},{"Parse %":46,"Name":"Mamyhanzo","Amount":"1504539$9.91%1.50m","Ilvl":234,"Ilvl %":85,"Active":"99.47%","DPS":"6,820.7","":""},{"Parse %":61,"Name":"Nightpumà","Amount":"1482038$9.76%1.48m","Ilvl":242,"Ilvl %":61,"Active":"99.06%","DPS":"6,718.7","":""},{"Parse %":51,"Name":"Kërronz","Amount":"1430072$9.42%1.43m","Ilvl":241,"Ilvl %":64,"Active":"99.30%","DPS":"6,483.1","":""},{"Parse %":48,"Name":"Likene","Amount":"1330534$8.77%1.33m","Ilvl":239,"Ilvl %":66,"Active":"99.39%","DPS":"6,031.8","":""},{"Parse %":28,"Name":"Nicho","Amount":"1254554$8.27%1.25m","Ilvl":239,"Ilvl %":35,"Active":"89.41%","DPS":"5,687.4","":""},{"Parse %":41,"Name":"Blläck","Amount":"1122994$7.40%1.12m","Ilvl":224,"Ilvl %":93,"Active":"98.92%","DPS":"5,091.0","":""},{"Parse %":71,"Name":"Danori","Amount":"989157$6.52%989.2k","Ilvl":240,"Ilvl %":86,"Active":"99.28%","DPS":"4,484.2","":""},{"Parse %":16,"Name":"Ayano","Amount":"902305$5.95%902.3k","Ilvl":228,"Ilvl %":49,"Active":"92.77%","DPS":"4,090.5","":""},{"Parse %":69,"Name":"Noffs","Amount":"889050$5.86%889.1k","Ilvl":234,"Ilvl %":91,"Active":"98.98%","DPS":"4,030.4","":""},{"Parse %":81,"Name":"Dryhwèn","Amount":"575062$3.79%575.1k","Ilvl":233,"Ilvl %":95,"Active":"98.58%","DPS":"2,607.0","":""},{"Parse %":19,"Name":"Calanthea","Amount":"262533$1.73%262.5k","Ilvl":229,"Ilvl %":36,"Active":"78.63%","DPS":"1,190.2","":""},{"Parse %":21,"Name":"Kekzahl","Amount":"24954$0.16%25.0k","Ilvl":236,"Ilvl %":28,"Active":"11.80%","DPS":113.1,"":""}],
                        "Gérald Darmanin": [{"Parse %":39,"Name":"Kërronz","Amount":"2820929$11.39%2.82m","Ilvl":241,"Ilvl %":47,"Active":"99.58%","DPS":"7,554.2","":""},{"Parse %":30,"Name":"Rattataa","Amount":"2667718$10.77%2.67m","Ilvl":240,"Ilvl %":38,"Active":"99.51%","DPS":"7,143.9","":""},{"Parse %":40,"Name":"Nicho","Amount":"2625029$10.60%2.63m","Ilvl":240,"Ilvl %":56,"Active":"99.81%","DPS":"7,029.6","":""},{"Parse %":36,"Name":"Pestiférée","Amount":"2540741$10.26%2.54m","Ilvl":233,"Ilvl %":84,"Active":"99.68%","DPS":"6,803.9","":""},{"Parse %":30,"Name":"Nightpumà","Amount":"2212898$8.94%2.21m","Ilvl":242,"Ilvl %":20,"Active":"97.22%","DPS":"5,925.9","":""},{"Parse %":16,"Name":"Mamyhanzo","Amount":"2127826$8.59%2.13m","Ilvl":234,"Ilvl %":33,"Active":"99.52%","DPS":"5,698.1","":""},{"Parse %":27,"Name":"Likene","Amount":"2113229$8.53%2.11m","Ilvl":239,"Ilvl %":35,"Active":"90.90%","DPS":"5,659.0","":""},{"Parse %":20,"Name":"Blläck","Amount":"1940742$7.84%1.94m","Ilvl":224,"Ilvl %":69,"Active":"99.68%","DPS":"5,197.1","":""},{"Parse %":54,"Name":"Danori","Amount":"1750036$7.07%1.75m","Ilvl":240,"Ilvl %":70,"Active":"99.90%","DPS":"4,686.4","":""},{"Parse %":43,"Name":"Noffs","Amount":"1566472$6.33%1.57m","Ilvl":234,"Ilvl %":75,"Active":"99.67%","DPS":"4,194.9","":""},{"Parse %":73,"Name":"Dryhwèn","Amount":"977640$3.95%977.6k","Ilvl":233,"Ilvl %":91,"Active":"99.19%","DPS":"2,618.0","":""},{"Parse %":4,"Name":"Ayano","Amount":"688659$2.78%688.7k","Ilvl":229,"Ilvl %":7,"Active":"42.18%","DPS":"1,844.2","":""},{"Parse %":16,"Name":"Calanthea","Amount":"454797$1.84%454.8k","Ilvl":229,"Ilvl %":28,"Active":"87.66%","DPS":"1,217.9","":""},{"Parse %":64,"Name":"Kekzahl","Amount":"275332$1.11%275.3k","Ilvl":236,"Ilvl %":76,"Active":"19.58%","DPS":737.3,"":""}],
                        "Raznal": [{"Parse %":48,"Name":"Rattataa","Amount":"2050899$12.88%2.05m","Ilvl":242,"Ilvl %":57,"Active":"76.07%","DPS":"5,246.8","":""},{"Parse %":35,"Name":"Nicho","Amount":"1855994$11.66%1.86m","Ilvl":240,"Ilvl %":48,"Active":"76.10%","DPS":"4,748.2","":""},{"Parse %":44,"Name":"Dread","Amount":"1827401$11.48%1.83m","Ilvl":240,"Ilvl %":56,"Active":"75.96%","DPS":"4,675.0","":""},{"Parse %":42,"Name":"Nightpumà","Amount":"1734415$10.89%1.73m","Ilvl":242,"Ilvl %":41,"Active":"74.33%","DPS":"4,437.2","":""},{"Parse %":22,"Name":"Mamyhanzo","Amount":"1592592$10.00%1.59m","Ilvl":234,"Ilvl %":49,"Active":"75.03%","DPS":"4,074.3","":""},{"Parse %":39,"Name":"Likene","Amount":"1513441$9.51%1.51m","Ilvl":240,"Ilvl %":48,"Active":"69.96%","DPS":"3,871.8","":""},{"Parse %":19,"Name":"Noffs","Amount":"1446214$9.08%1.45m","Ilvl":235,"Ilvl %":35,"Active":"75.97%","DPS":"3,699.9","":""},{"Parse %":23,"Name":"Blläck","Amount":"1314148$8.25%1.31m","Ilvl":228,"Ilvl %":72,"Active":"63.89%","DPS":"3,362.0","":""},{"Parse %":52,"Name":"Danori","Amount":"1077135$6.77%1.08m","Ilvl":240,"Ilvl %":69,"Active":"74.42%","DPS":"2,755.6","":""},{"Parse %":41,"Name":"Thakgrin","Amount":"944537$5.93%944.5k","Ilvl":234,"Ilvl %":66,"Active":"76.74%","DPS":"2,416.4","":""},{"Parse %":44,"Name":"Dryhwèn","Amount":"539144$3.39%539.1k","Ilvl":233,"Ilvl %":66,"Active":"67.55%","DPS":"1,379.3","":""},{"Parse %":14,"Name":"Syrane","Amount":"23354$0.15%23.4k","Ilvl":221,"Ilvl %":28,"Active":"0.00%","DPS":59.7,"":""},{"Parse %":1,"Name":"Kekzahl","Amount":"1497$0.01%1.5k","Ilvl":236,"Ilvl %":1,"Active":"0.00%","DPS":3.8,"":""}],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [{"Parse %":91,"Name":"Nicho","Amount":"3814672$18.81%3.81m","Ilvl":241,"Ilvl %":98,"Active":"99.44%","DPS":"16,300.6","":""},{"Parse %":63,"Name":"Likene","Amount":"2371072$11.69%2.37m","Ilvl":240,"Ilvl %":72,"Active":"99.34%","DPS":"10,131.9","":""},{"Parse %":60,"Name":"Nightpumà","Amount":"2332013$11.50%2.33m","Ilvl":243,"Ilvl %":51,"Active":"99.16%","DPS":"9,965.0","":""},{"Parse %":46,"Name":"Rattataa","Amount":"2326332$11.47%2.33m","Ilvl":243,"Ilvl %":42,"Active":"99.37%","DPS":"9,940.7","":""},{"Parse %":32,"Name":"Pestiférée","Amount":"2175456$10.73%2.18m","Ilvl":233,"Ilvl %":65,"Active":"99.54%","DPS":"9,296.0","":""},{"Parse %":17,"Name":"Mamyhanzo","Amount":"1748007$8.62%1.75m","Ilvl":234,"Ilvl %":31,"Active":"99.55%","DPS":"7,469.4","":""},{"Parse %":82,"Name":"Thakgrin","Amount":"1669514$8.23%1.67m","Ilvl":234,"Ilvl %":95,"Active":"99.59%","DPS":"7,134.0","":""},{"Parse %":29,"Name":"Blläck","Amount":"1640424$8.09%1.64m","Ilvl":228,"Ilvl %":68,"Active":"99.23%","DPS":"7,009.7","":""},{"Parse %":37,"Name":"Noffs","Amount":"978753$4.83%978.8k","Ilvl":234,"Ilvl %":57,"Active":"94.62%","DPS":"4,182.3","":""},{"Parse %":74,"Name":"Dryhwèn","Amount":"908134$4.48%908.1k","Ilvl":234,"Ilvl %":86,"Active":"97.88%","DPS":"3,880.6","":""},{"Parse %":79,"Name":"Dread-HEAL","Amount":"299341$1.48%299.3k","Ilvl":238,"Ilvl %":81,"Active":"45.27%","DPS":"1,279.1","":""},{"Parse %":8,"Name":"Syrane","Amount":"16967$0.08%17.0k","Ilvl":225,"Ilvl %":11,"Active":"3.42%","DPS":72.5,"":""}],
                        "Oeil du Geôlier": [{"Parse %":63,"Name":"Nicho","Amount":"2380330$16.58%2.38m","Ilvl":241,"Ilvl %":76,"Active":"99.45%","DPS":"6,730.6","":""},{"Parse %":51,"Name":"Nightpumà","Amount":"1973749$13.75%1.97m","Ilvl":243,"Ilvl %":37,"Active":"99.19%","DPS":"5,581.0","":""},{"Parse %":33,"Name":"Mamyhanzo","Amount":"1894536$13.20%1.89m","Ilvl":234,"Ilvl %":63,"Active":"99.57%","DPS":"5,357.0","":""},{"Parse %":46,"Name":"Likene","Amount":"1877855$13.08%1.88m","Ilvl":240,"Ilvl %":52,"Active":"93.73%","DPS":"5,309.8","":""},{"Parse %":28,"Name":"Pestiférée","Amount":"1727162$12.03%1.73m","Ilvl":233,"Ilvl %":62,"Active":"99.52%","DPS":"4,883.7","":""},{"Parse %":67,"Name":"Thakgrin","Amount":"1307629$9.11%1.31m","Ilvl":234,"Ilvl %":87,"Active":"99.73%","DPS":"3,697.4","":""},{"Parse %":53,"Name":"Noffs","Amount":"1166456$8.13%1.17m","Ilvl":234,"Ilvl %":77,"Active":"96.79%","DPS":"3,298.3","":""},{"Parse %":90,"Name":"Dryhwèn","Amount":"948889$6.61%948.9k","Ilvl":234,"Ilvl %":97,"Active":"91.69%","DPS":"2,683.1","":""},{"Parse %":4,"Name":"Rattataa","Amount":"443654$3.09%443.7k","Ilvl":243,"Ilvl %":3,"Active":"14.69%","DPS":"1,254.5","":""},{"Parse %":90,"Name":"Dread-HEAL","Amount":"361171$2.52%361.2k","Ilvl":238,"Ilvl %":94,"Active":"84.24%","DPS":"1,021.2","":""},{"Parse %":3,"Name":"Blläck","Amount":"248717$1.73%248.7k","Ilvl":228,"Ilvl %":6,"Active":"10.37%","DPS":703.3,"":""},{"Parse %":15,"Name":"Syrane","Amount":"24281$0.17%24.3k","Ilvl":225,"Ilvl %":26,"Active":"19.31%","DPS":68.7,"":""}],
                        "The Nine": [{"Parse %":82,"Name":"Rattataa","Amount":"2749598$15.79%2.75m","Ilvl":243,"Ilvl %":93,"Active":"99.64%","DPS":"9,941.6","":""},{"Parse %":57,"Name":"Nicho","Amount":"2164354$12.43%2.16m","Ilvl":241,"Ilvl %":72,"Active":"99.79%","DPS":"7,825.6","":""},{"Parse %":43,"Name":"Pestiférée","Amount":"1935839$11.12%1.94m","Ilvl":233,"Ilvl %":89,"Active":"99.71%","DPS":"6,999.4","":""},{"Parse %":44,"Name":"Likene","Amount":"1828233$10.50%1.83m","Ilvl":240,"Ilvl %":53,"Active":"99.79%","DPS":"6,610.3","":""},{"Parse %":45,"Name":"Nightpumà","Amount":"1820891$10.46%1.82m","Ilvl":243,"Ilvl %":29,"Active":"98.59%","DPS":"6,583.7","":""},{"Parse %":40,"Name":"Blläck","Amount":"1677987$9.64%1.68m","Ilvl":228,"Ilvl %":91,"Active":"99.51%","DPS":"6,067.0","":""},{"Parse %":25,"Name":"Mamyhanzo","Amount":"1654880$9.51%1.65m","Ilvl":234,"Ilvl %":52,"Active":"99.65%","DPS":"5,983.5","":""},{"Parse %":56,"Name":"Thakgrin","Amount":"1203034$6.91%1.20m","Ilvl":234,"Ilvl %":82,"Active":"99.75%","DPS":"4,349.8","":""},{"Parse %":52,"Name":"Noffs","Amount":"1167124$6.70%1.17m","Ilvl":234,"Ilvl %":78,"Active":"99.65%","DPS":"4,219.9","":""},{"Parse %":76,"Name":"Dryhwèn","Amount":"747654$4.29%747.7k","Ilvl":234,"Ilvl %":91,"Active":"89.72%","DPS":"2,703.3","":""},{"Parse %":95,"Name":"Dread-HEAL","Amount":"438981$2.52%439.0k","Ilvl":239,"Ilvl %":97,"Active":"98.80%","DPS":"1,587.2","":""},{"Parse %":15,"Name":"Syrane","Amount":"20355$0.12%20.4k","Ilvl":226,"Ilvl %":22,"Active":"3.00%","DPS":73.6,"":""}],
                        "Ner'zhul": [{"Parse %":77,"Name":"Rattataa","Amount":"2150849$16.46%2.15m","Ilvl":243,"Ilvl %":88,"Active":"99.47%","DPS":"8,624.0","":""},{"Parse %":44,"Name":"Nicho","Amount":"1665900$12.75%1.67m","Ilvl":241,"Ilvl %":52,"Active":"99.67%","DPS":"6,679.5","":""},{"Parse %":39,"Name":"Pestiférée","Amount":"1588603$12.16%1.59m","Ilvl":233,"Ilvl %":82,"Active":"99.86%","DPS":"6,369.6","":""},{"Parse %":52,"Name":"Nightpumà","Amount":"1566513$11.99%1.57m","Ilvl":243,"Ilvl %":41,"Active":"99.31%","DPS":"6,281.0","":""},{"Parse %":25,"Name":"Mamyhanzo","Amount":"1423905$10.90%1.42m","Ilvl":234,"Ilvl %":52,"Active":"99.35%","DPS":"5,709.2","":""},{"Parse %":32,"Name":"Blläck","Amount":"1145390$8.77%1.15m","Ilvl":228,"Ilvl %":79,"Active":"99.07%","DPS":"4,592.5","":""},{"Parse %":16,"Name":"Likene","Amount":"993189$7.60%993.2k","Ilvl":240,"Ilvl %":12,"Active":"63.23%","DPS":"3,982.2","":""},{"Parse %":53,"Name":"Thakgrin","Amount":"884911$6.77%884.9k","Ilvl":234,"Ilvl %":80,"Active":"99.61%","DPS":"3,548.1","":""},{"Parse %":34,"Name":"Noffs","Amount":"754835$5.78%754.8k","Ilvl":234,"Ilvl %":60,"Active":"86.70%","DPS":"3,026.6","":""},{"Parse %":72,"Name":"Dryhwèn","Amount":"578075$4.42%578.1k","Ilvl":234,"Ilvl %":91,"Active":"90.02%","DPS":"2,317.8","":""},{"Parse %":91,"Name":"Dread-HEAL","Amount":"286165$2.19%286.2k","Ilvl":239,"Ilvl %":93,"Active":"76.26%","DPS":"1,147.4","":""},{"Parse %":21,"Name":"Syrane","Amount":"29323$0.22%29.3k","Ilvl":226,"Ilvl %":33,"Active":"9.83%","DPS":117.6,"":""}],
                        "Gérald Darmanin": [{"Parse %":59,"Name":"Rattataa","Amount":"3603897$16.56%3.60m","Ilvl":243,"Ilvl %":65,"Active":"95.45%","DPS":"8,883.2","":""},{"Parse %":37,"Name":"Nicho","Amount":"2766954$12.71%2.77m","Ilvl":241,"Ilvl %":44,"Active":"99.82%","DPS":"6,820.2","":""},{"Parse %":26,"Name":"Pestiférée","Amount":"2469082$11.34%2.47m","Ilvl":233,"Ilvl %":68,"Active":"99.31%","DPS":"6,086.0","":""},{"Parse %":13,"Name":"Mamyhanzo","Amount":"2161284$9.93%2.16m","Ilvl":234,"Ilvl %":25,"Active":"99.76%","DPS":"5,327.3","":""},{"Parse %":21,"Name":"Nightpumà","Amount":"2160917$9.93%2.16m","Ilvl":243,"Ilvl %":9,"Active":"96.24%","DPS":"5,326.4","":""},{"Parse %":19,"Name":"Likene","Amount":"2022614$9.29%2.02m","Ilvl":240,"Ilvl %":19,"Active":"99.31%","DPS":"4,985.5","":""},{"Parse %":57,"Name":"Thakgrin","Amount":"1865951$8.57%1.87m","Ilvl":234,"Ilvl %":85,"Active":"99.50%","DPS":"4,599.4","":""},{"Parse %":41,"Name":"Noffs","Amount":"1679074$7.71%1.68m","Ilvl":234,"Ilvl %":72,"Active":"99.79%","DPS":"4,138.7","":""},{"Parse %":13,"Name":"Blläck","Amount":"1676149$7.70%1.68m","Ilvl":228,"Ilvl %":43,"Active":"74.72%","DPS":"4,131.5","":""},{"Parse %":54,"Name":"Dryhwèn","Amount":"879116$4.04%879.1k","Ilvl":234,"Ilvl %":77,"Active":"93.34%","DPS":"2,166.9","":""},{"Parse %":91,"Name":"Dread-HEAL","Amount":"456460$2.10%456.5k","Ilvl":240,"Ilvl %":93,"Active":"60.25%","DPS":"1,125.1","":""},{"Parse %":12,"Name":"Syrane","Amount":"23090$0.11%23.1k","Ilvl":226,"Ilvl %":17,"Active":"7.13%","DPS":56.9,"":""}],
                        "Raznal": [{"Parse %":76,"Name":"Rattataa","Amount":"2538689$17.20%2.54m","Ilvl":243,"Ilvl %":89,"Active":"77.13%","DPS":"6,142.5","":""},{"Parse %":44,"Name":"Nicho","Amount":"2136121$14.47%2.14m","Ilvl":241,"Ilvl %":59,"Active":"77.23%","DPS":"5,168.4","":""},{"Parse %":44,"Name":"Pestiférée","Amount":"2132189$14.45%2.13m","Ilvl":233,"Ilvl %":90,"Active":"76.98%","DPS":"5,158.9","":""},{"Parse %":44,"Name":"Nightpumà","Amount":"1857982$12.59%1.86m","Ilvl":243,"Ilvl %":37,"Active":"76.36%","DPS":"4,495.5","":""},{"Parse %":35,"Name":"Likene","Amount":"1536085$10.41%1.54m","Ilvl":240,"Ilvl %":42,"Active":"68.28%","DPS":"3,716.6","":""},{"Parse %":51,"Name":"Thakgrin","Amount":"1067772$7.24%1.07m","Ilvl":234,"Ilvl %":77,"Active":"77.58%","DPS":"2,583.5","":""},{"Parse %":9,"Name":"Mamyhanzo","Amount":"997627$6.76%997.6k","Ilvl":234,"Ilvl %":18,"Active":"39.74%","DPS":"2,413.8","":""},{"Parse %":19,"Name":"Noffs","Amount":"824071$5.58%824.1k","Ilvl":234,"Ilvl %":37,"Active":"74.75%","DPS":"1,993.9","":""},{"Parse %":8,"Name":"Blläck","Amount":"675436$4.58%675.4k","Ilvl":228,"Ilvl %":33,"Active":"37.74%","DPS":"1,634.2","":""},{"Parse %":59,"Name":"Dryhwèn","Amount":"656726$4.45%656.7k","Ilvl":235,"Ilvl %":77,"Active":"74.48%","DPS":"1,589.0","":""},{"Parse %":92,"Name":"Dread-HEAL","Amount":"316714$2.15%316.7k","Ilvl":240,"Ilvl %":94,"Active":"64.14%","DPS":766.3,"":""},{"Parse %":12,"Name":"Syrane","Amount":"18212$0.12%18.2k","Ilvl":228,"Ilvl %":15,"Active":"4.97%","DPS":44.1,"":""}],
                        "Guardian": [{"Parse %":60,"Name":"Nicho","Amount":"2234974$11.40%2.23m","Ilvl":241,"Ilvl %":84,"Active":"99.04%","DPS":"8,225.7","":""},{"Parse %":52,"Name":"Rattataa","Amount":"2045929$10.44%2.05m","Ilvl":243,"Ilvl %":59,"Active":"99.06%","DPS":"7,529.9","":""},{"Parse %":55,"Name":"Dread","Amount":"1989016$10.15%1.99m","Ilvl":241,"Ilvl %":66,"Active":"98.92%","DPS":"7,320.4","":""},{"Parse %":34,"Name":"Pestiférée","Amount":"1823648$9.30%1.82m","Ilvl":233,"Ilvl %":82,"Active":"97.44%","DPS":"6,711.8","":""},{"Parse %":53,"Name":"Nightpumà","Amount":"1804581$9.21%1.80m","Ilvl":243,"Ilvl %":47,"Active":"98.45%","DPS":"6,641.6","":""},{"Parse %":22,"Name":"Mamyhanzo","Amount":"1614053$8.23%1.61m","Ilvl":235,"Ilvl %":48,"Active":"93.67%","DPS":"5,940.4","":""},{"Parse %":36,"Name":"Blläck","Amount":"1585126$8.09%1.59m","Ilvl":232,"Ilvl %":81,"Active":"99.27%","DPS":"5,834.0","":""},{"Parse %":15,"Name":"Evanana","Amount":"1366839$6.97%1.37m","Ilvl":229,"Ilvl %":49,"Active":"93.15%","DPS":"5,030.6","":""},{"Parse %":22,"Name":"Likene","Amount":"1212660$6.19%1.21m","Ilvl":240,"Ilvl %":24,"Active":"81.52%","DPS":"4,463.1","":""},{"Parse %":13,"Name":"Kërronz","Amount":"1157338$5.90%1.16m","Ilvl":242,"Ilvl %":9,"Active":"68.97%","DPS":"4,259.5","":""},{"Parse %":57,"Name":"Thakgrin","Amount":"1104576$5.63%1.10m","Ilvl":234,"Ilvl %":86,"Active":"99.35%","DPS":"4,065.3","":""},{"Parse %":55,"Name":"Noffs","Amount":"1088409$5.55%1.09m","Ilvl":234,"Ilvl %":85,"Active":"99.61%","DPS":"4,005.8","":""},{"Parse %":51,"Name":"Dryhwèn","Amount":"549003$2.80%549.0k","Ilvl":235,"Ilvl %":75,"Active":"99.47%","DPS":"2,020.6","":""},{"Parse %":18,"Name":"Syrane","Amount":"20826$0.11%20.8k","Ilvl":227,"Ilvl %":26,"Active":"12.05%","DPS":76.6,"":""},{"Parse %":5,"Name":"Kekzahl","Amount":"5769$0.03%5.8k","Ilvl":237,"Ilvl %":8,"Active":"0.73%","DPS":21.2,"":""}],
                        "Roh-Kalo": [{"Parse %":65,"Name":"Rattataa","Amount":"4041415$11.73%4.04m","Ilvl":243,"Ilvl %":74,"Active":"99.69%","DPS":"7,838.1","":""},{"Parse %":70,"Name":"Dread","Amount":"3976914$11.55%3.98m","Ilvl":241,"Ilvl %":83,"Active":"95.81%","DPS":"7,713.0","":""},{"Parse %":45,"Name":"Kërronz","Amount":"3456636$10.04%3.46m","Ilvl":242,"Ilvl %":52,"Active":"99.92%","DPS":"6,703.9","":""},{"Parse %":31,"Name":"Mamyhanzo","Amount":"3145860$9.13%3.15m","Ilvl":235,"Ilvl %":67,"Active":"99.79%","DPS":"6,101.2","":""},{"Parse %":33,"Name":"Nicho","Amount":"3032566$8.81%3.03m","Ilvl":241,"Ilvl %":45,"Active":"99.83%","DPS":"5,881.5","":""},{"Parse %":22,"Name":"Evanana","Amount":"2929749$8.51%2.93m","Ilvl":229,"Ilvl %":73,"Active":"99.44%","DPS":"5,682.1","":""},{"Parse %":26,"Name":"Pestiférée","Amount":"2853597$8.29%2.85m","Ilvl":233,"Ilvl %":75,"Active":"99.81%","DPS":"5,534.4","":""},{"Parse %":30,"Name":"Blläck","Amount":"2784609$8.09%2.78m","Ilvl":232,"Ilvl %":75,"Active":"92.49%","DPS":"5,400.6","":""},{"Parse %":74,"Name":"Thakgrin","Amount":"2455511$7.13%2.46m","Ilvl":235,"Ilvl %":96,"Active":"99.90%","DPS":"4,762.3","":""},{"Parse %":11,"Name":"Nightpumà","Amount":"2312245$6.71%2.31m","Ilvl":243,"Ilvl %":9,"Active":"70.67%","DPS":"4,484.4","":""},{"Parse %":57,"Name":"Noffs","Amount":"2175643$6.32%2.18m","Ilvl":234,"Ilvl %":90,"Active":"98.15%","DPS":"4,219.5","":""},{"Parse %":61,"Name":"Dryhwèn","Amount":"1088968$3.16%1.09m","Ilvl":235,"Ilvl %":82,"Active":"86.08%","DPS":"2,112.0","":""},{"Parse %":30,"Name":"Kekzahl","Amount":"134789$0.39%134.8k","Ilvl":237,"Ilvl %":36,"Active":"21.45%","DPS":261.4,"":""},{"Parse %":13,"Name":"Syrane","Amount":"52157$0.15%52.2k","Ilvl":227,"Ilvl %":19,"Active":"5.93%","DPS":101.2,"":""}],
                        "KT": [],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [],
                        "Oeil du Geôlier": [],
                        "The Nine": [],
                        "Ner'zhul": [],
                        "Gérald Darmanin": [],
                        "Raznal": [{"Parse %":48,"Name":"Redh","Amount":"1767450$12.06%1.77m","Ilvl":234,"Ilvl %":87,"Active":"74.16%","DPS":"5,068.1","":""},{"Parse %":36,"Name":"Rattataa","Amount":"1679630$11.46%1.68m","Ilvl":243,"Ilvl %":32,"Active":"74.09%","DPS":"4,816.2","":""},{"Parse %":33,"Name":"Nicho","Amount":"1609966$10.98%1.61m","Ilvl":242,"Ilvl %":33,"Active":"61.99%","DPS":"4,616.5","":""},{"Parse %":49,"Name":"Nightpumà","Amount":"1607055$10.96%1.61m","Ilvl":243,"Ilvl %":45,"Active":"73.68%","DPS":"4,608.1","":""},{"Parse %":36,"Name":"Kërronz","Amount":"1538368$10.50%1.54m","Ilvl":242,"Ilvl %":37,"Active":"71.94%","DPS":"4,411.2","":""},{"Parse %":24,"Name":"Evanana","Amount":"1449819$9.89%1.45m","Ilvl":230,"Ilvl %":65,"Active":"73.75%","DPS":"4,157.3","":""},{"Parse %":46,"Name":"Likene","Amount":"1414746$9.65%1.41m","Ilvl":241,"Ilvl %":53,"Active":"58.73%","DPS":"4,056.7","":""},{"Parse %":29,"Name":"Blläck","Amount":"1266868$8.64%1.27m","Ilvl":233,"Ilvl %":55,"Active":"69.87%","DPS":"3,632.7","":""},{"Parse %":59,"Name":"Noffs","Amount":"961359$6.56%961.4k","Ilvl":238,"Ilvl %":76,"Active":"73.86%","DPS":"2,756.6","":""},{"Parse %":40,"Name":"Thakgrin","Amount":"842563$5.75%842.6k","Ilvl":235,"Ilvl %":65,"Active":"74.63%","DPS":"2,416.0","":""},{"Parse %":49,"Name":"Dryhwèn","Amount":"502789$3.43%502.8k","Ilvl":237,"Ilvl %":64,"Active":"65.62%","DPS":"1,441.7","":""},{"Parse %":12,"Name":"Syrane","Amount":"17063$0.12%17.1k","Ilvl":229,"Ilvl %":18,"Active":"0.00%","DPS":48.9,"":""}],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [{"Parse %":39,"Name":"Likene","Amount":"2994715$12.54%2.99m","Ilvl":241,"Ilvl %":59,"Active":"96.44%","DPS":"6,424.1","":""},{"Parse %":35,"Name":"Rattataa","Amount":"2956965$12.38%2.96m","Ilvl":243,"Ilvl %":41,"Active":"97.25%","DPS":"6,343.1","":""},{"Parse %":30,"Name":"Kërronz","Amount":"2931871$12.28%2.93m","Ilvl":242,"Ilvl %":38,"Active":"99.53%","DPS":"6,289.3","":""},{"Parse %":17,"Name":"Nightpumà","Amount":"2453909$10.28%2.45m","Ilvl":243,"Ilvl %":14,"Active":"91.34%","DPS":"5,264.0","":""},{"Parse %":17,"Name":"Nicho","Amount":"2353638$9.86%2.35m","Ilvl":242,"Ilvl %":19,"Active":"94.48%","DPS":"5,048.9","":""},{"Parse %":13,"Name":"Evanana","Amount":"2238325$9.37%2.24m","Ilvl":231,"Ilvl %":41,"Active":"91.64%","DPS":"4,801.5","":""},{"Parse %":21,"Name":"Blläck","Amount":"2159666$9.04%2.16m","Ilvl":233,"Ilvl %":51,"Active":"95.94%","DPS":"4,632.8","":""},{"Parse %":41,"Name":"Thakgrin","Amount":"1914280$8.02%1.91m","Ilvl":235,"Ilvl %":76,"Active":"93.47%","DPS":"4,106.4","":""},{"Parse %":37,"Name":"Noffs","Amount":"1866558$7.82%1.87m","Ilvl":238,"Ilvl %":64,"Active":"97.07%","DPS":"4,004.0","":""},{"Parse %":55,"Name":"Dryhwèn","Amount":"1060697$4.44%1.06m","Ilvl":237,"Ilvl %":81,"Active":"91.53%","DPS":"2,275.3","":""},{"Parse %":96,"Name":"Dread-HEAL","Amount":"881015$3.69%881.0k","Ilvl":241,"Ilvl %":99,"Active":"74.76%","DPS":"1,889.9","":""},{"Parse %":15,"Name":"Syrane","Amount":"69436$0.29%69.4k","Ilvl":229,"Ilvl %":26,"Active":"3.98%","DPS":149,"":""}],
                        "Sylvanas": []
                    },
                    {
                        "Tarragrue": [],
                        "Oeil du Geôlier": [],
                        "The Nine": [],
                        "Ner'zhul": [],
                        "Gérald Darmanin": [],
                        "Raznal": [],
                        "Guardian": [],
                        "Roh-Kalo": [],
                        "KT": [],
                        "Sylvanas": []
                    }
                ],
                selectedName: null,
                selectedBoss: null,
                ppl: [],
                boss: [
                    { id: 0, name: "Tarragrue", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2423-icon.jpg" },
                    { id: 1, name: "Oeil du Geôlier", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2433-icon.jpg" },
                    { id: 2, name: "The Nine", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2429-icon.jpg" },
                    { id: 3, name: "Ner'zhul", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2432-icon.jpg" },
                    { id: 4, name: "Gérald Darmanin", icon: "https://pbs.twimg.com/profile_images/1369363441689968640/4Bl_temn_400x400.jpg" },
                    { id: 5, name: "Raznal", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2430-icon.jpg" },
                    { id: 6, name: "Guardian", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2436-icon.jpg" },
                    { id: 7, name: "Roh-Kalo", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2431-icon.jpg" },
                    { id: 8, name: "KT", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2422-icon.jpg" },
                    { id: 9, name: "Sylvanas", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2435-icon.jpg" }
                ],
                week: ["week 3", "week 4", "week 5", "week 6", "week 7", "week 8", "week 9", "week 10", "week 11", "week 12"],
                tank: ["Danori", "Thakgrin", "Dread-tank", "Foenix", "Noffs"],
                heal: ["Dryhwèn", "Calanthea", "Kekzahl", "Taratata", "Dread-HEAL", "Syrane"],
                data: [],
            }
        },
        methods: {
            onNameClick: function(name) {
                this.selectedName = name;
            },
            onBossClick: function(name) {
                this.selectedBoss = name;
            },
            isBackAllowed: function() {
                if (this.selectedBoss != null || this.selectedName != null) {
                    return true;
                }

                return false;
            },
            onClickBack: function() {
                if (this.selectedBoss != null) {
                    this.selectedBoss = null;
                }
                else if (this.selectedName != null) {
                    this.selectedName = null;
                }
            },
            onClickLast: function() {
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (boss) {
                    if (boss.id != 0) {
                        const newBoss = this.boss.find(b => b.id == (boss.id - 1));
                        
                        if (newBoss) {
                            this.selectedBoss = newBoss.name;
                        }
                    }
                }
            },
            onClickNext: function() {
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (boss) {
                    if (boss.id != 9) {
                        const newBoss = this.boss.find(b => b.id == (boss.id + 1));
                        
                        if (newBoss) {
                            this.selectedBoss = newBoss.name;
                        }
                    }
                }
            },
            getFightData: function(raw_fight, name) {
                if (raw_fight.length <= 0) {
                    return false;
                }
                
                const temp = {
                    name: name,
                    tank: [],
                    heal: [],
                    dps: [],
                    dps_tank: 0,
                    dps_heal: 0,
                    dps_nodps: 0,
                    seuil: 0 
                };
                
                raw_fight.forEach(element => {
                    // Va savoir ce que ce truc fait dans les logs de tarragrue...
                    if (element.Name == "Petite putriciole") { return; }

                    const el = {
                        name: element.Name,
                        // ça ressemble à "1764742$10.35%1.76m"
                        dps: parseFloat(element.Amount.split("$")[1].split('%')[0]),
                        
                        // La joie du JS et des , de séparation des milliers
                        // 900 c'est un number, 1,900 c'est un string youblaboom ça casse tout le code
                        dpsK: typeof element.DPS == "number" ? parseInt(element.DPS).toString() : element.DPS.replace(',', 'k').split('.')[0],
                        parsePrcnt: element["Ilvl %"],
                        ilvl: element.Ilvl
                    };

                    // Si c'est un tank
                    if (this.tank.includes(el.name)) {
                        // Ajout dans la liste des tanks du combat
                        temp.tank.push(el);

                        // calcul du dps tank
                        temp.dps_tank += el.dps;
                    }
                    else if (this.heal.includes(el.name)) {
                        // Ajout dans la liste des heals du combat
                        temp.heal.push(el);

                        // calcul du dps heal
                        temp.dps_heal += el.dps;
                    }
                    else {
                        // Si c'est un DPS (ou du moins c'est ni un tank ni un heal)
                        if (!this.ppl.includes(el.name)) {
                            // Ajout dans la liste des dps du combat
                            this.ppl.push(el.name);
                        }

                        temp.dps.push(el);
                    }
                });

                // total non-dps
                temp.dps_nodps = temp.dps_tank + temp.dps_heal;

                // calcul du seuil
                temp.seuil = (100 - temp.dps_nodps)/temp.dps.length;
                temp.seuil = Math.round((temp.seuil + Number.EPSILON) * 100) / 100

                return temp;
            },
            getItemsForArray: function(data) {
                const finalArray = [];
                // Doit retourner un tableau compatible avec le headers
                // Donc : "Name", "Tarragrue", "Oeil", "The Nine", "Ner'zhul", "Darmanin", "Raznal", "Guardian", "Roh-Kalo", "KT", "Sylvanas", "Total"
                // Pour chaque DPS, puis tank, puis healer

                // 1er étape : récupération de la liste des DPS
                const ppl_temp = [];
                
                data.forEach(element => {
                    if (element == false) { return; }
                    element.dps.forEach(dps => {
                        if (!ppl_temp.includes(dps.name)) { ppl_temp.push(dps.name); }
                    });
                });

                // Maintenant, pour chaque joueur, il va falloir faire la liste de ses scores et la rajouter dans le tableau final
                ppl_temp.forEach(ppl => {
                    const row = {
                        Pseudo: ppl,
                        Tarragrue: 0,
                        Oeil: 0,
                        Nine: 0,
                        Nerzhul: 0,
                        Darmanin: 0,
                        Raznal: 0,
                        Guardian: 0,
                        RohKalo: 0,
                        KT: 0,
                        Sylvanas: 0
                    };

                    // Pour chaque boss
                    data.forEach(boss => {
                        if (boss == false) { return; }

                        const info = boss.dps.find(pr => pr.name == ppl);
                        if (info) {
                            row[boss.name] = info.dps;
                        }
                    });

                    finalArray.push(row);
                });
                
                // Et rajouter le seuil
                const seuil = {
                    Pseudo: "Seuil",
                    Tarragrue: 0,
                    Oeil: 0,
                    Nine: 0,
                    Nerzhul: 0,
                    Darmanin: 0,
                    Raznal: 0,
                    Guardian: 0,
                    RohKalo: 0,
                    KT: 0,
                    Sylvanas: 0
                };
                data.forEach(boss => {
                    if (boss == false) { return; }
                    seuil[boss.name] = boss.seuil;
                });

                finalArray.push(seuil);

                return finalArray;
            },
            getDataByBossByCharForWeek: function(boss, week) {
                const weekData = this.data[this.week.indexOf(week)];
                const data = weekData[boss.id];

                if (data == false) { return "Pas down" }

                // Recherche du DPS
                let pplData = data.dps.find(p => p.name == this.selectedName);

                // Si personne, recherche tank
                if (typeof pplData == "undefined") {
                    pplData = data.tank.find(p => p.name == this.selectedName);
                }

                // Si personne, recherche heal
                if (typeof pplData == "undefined") {
                    pplData = data.heal.find(p => p.name == this.selectedName);
                }

                if (pplData) {
                    return `${pplData.ilvl}iLvl, ${pplData.dpsK} - ${pplData.dps}/${data.seuil} - ${pplData.parsePrcnt} iLvl%`;
                }
                else {
                    return "Absent";
                }
            },
            getColorByBossByCharForWeek: function(boss, week) {
                const weekData = this.data[this.week.indexOf(week)];
                const data = weekData[boss.id];

                if (data == false) { return "Ø" }
                
                // Recherche du DPS
                let pplData = data.dps.find(p => p.name == this.selectedName);

                let tank = false;
                let heal = false;

                // Si personne, recherche tank
                if (typeof pplData == "undefined") {
                    pplData = data.tank.find(p => p.name == this.selectedName);
                    tank = typeof pplData == "undefined" ? false : true;
                }

                // Si personne, recherche heal
                if (typeof pplData == "undefined") {
                    pplData = data.heal.find(p => p.name == this.selectedName);
                    heal = typeof pplData == "undefined" ? false : true;
                }

                if (pplData) {
                    if (tank || heal) {
                        return "color-blue";
                    }

                    const result = ((pplData.dps*100)/data.seuil)-100;

                    // Si DPS > 10% du seuil alors green
                    if (result > 10) { return "color-green"; }

                    // Si DPS > 0 alors rien
                    else if (result >= 0) { return "color-blue"; }

                    // Si DPS < 0% mais > a 10% du seuil, alors orange
                    else if (result < 0 && result > -10) { return "color-orange"; }

                    // Si DPS plus bas de 10% du seuil, alors rouge
                    else if (result < -10) { return "color-red"; }
                }

                return "color-black";
            },
            getDataByBossForAllCharForWeek: function(ppl, week, tank, heal) {
                const weekData = this.data[this.week.indexOf(week)];
                const boss = this.boss.find(b => b.name == this.selectedBoss);

                if (typeof boss == "undefined") { return "Gné ?"; }

                const data = weekData[boss.id];

                if (data == false) { return "Pas down" }
                
                const _data = tank ? data.tank : heal ? data.heal : data.dps;

                const pplData = _data.find(p => p.name == ppl);
                
                if (pplData) {
                    return `${pplData.ilvl}iLvl, ${pplData.dpsK} - ${pplData.dps}/${data.seuil} - ${pplData.parsePrcnt} iLvl%`;
                }
                else {
                    return "Absent";
                }
            },
            getColorByBossForAllCharForWeek: function(ppl, week, tank, heal) {
                const weekData = this.data[this.week.indexOf(week)];
                const boss = this.boss.find(b => b.name == this.selectedBoss);
                
                if (typeof boss == "undefined") { return "Gné ?"; }

                const data = weekData[boss.id];

                if (data == false) { return "Ø" }

                const _data = tank ? data.tank : heal ? data.heal : data.dps;

                const pplData = _data.find(p => p.name == ppl);

                if (pplData) {
                    if (tank || heal) {
                        return "color-blue"; 
                    }
                    const result = ((pplData.dps*100)/data.seuil)-100;

                    // Si DPS > 10% du seuil alors green
                    if (result > 10) { return "color-green"; }

                    // Si DPS > 0 alors rien
                    else if (result >= 0) { return "color-blue"; }

                    // Si DPS < 0% mais > a 10% du seuil, alors orange
                    else if (result < 0 && result > -10) { return "color-orange"; }

                    // Si DPS plus bas de 10% du seuil, alors rouge
                    else if (result < -10) { return "color-red"; }
                }

                return "color-black";
            }
        },
        mounted() {
            this.raw_data.forEach(raw => {
                this.data.push([
                        this.getFightData(raw["Tarragrue"], "Tarragrue"),
                        this.getFightData(raw["Oeil du Geôlier"], "Oeil"),
                        this.getFightData(raw["The Nine"], "Nine"),
                        this.getFightData(raw["Ner'zhul"], "Nerzhul"),
                        this.getFightData(raw["Gérald Darmanin"], "Darmanin"),
                        this.getFightData(raw["Raznal"], "Raznal"),
                        this.getFightData(raw["Guardian"], "Guardian"),
                        this.getFightData(raw["Roh-Kalo"], "RohKalo"),
                        this.getFightData(raw["KT"], "KT"),
                        this.getFightData(raw["Sylvanas"], "Sylvanas")
                    ]
                );
            });
        }
    }
</script>

<style>
    .square {
        width: 20px;
        height: 20px;
    }
    .color-green {
        background: #4caf50 !important;
        color:white;
    }
    .color-blue {
        background: #2196f3 !important;
        color:white;
    }
    .color-orange {
        background: #ff5722 !important;
        color:white;
    }
    .color-red {
        background: red !important;
        color:white;
    }
    .color-black {
        background: black !important;
        color:white;
    }
    .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
        color: white !important;
    }

    .elevation-12.v-card.v-sheet.theme--light {
        background-color: #fff0;
    }

    .center-xy {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }
    table {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }
    table.greyGridTable {
        border: 2px solid #FFFFFF;
        width: 100%;
        text-align: center;
        border-collapse: collapse;
    }
    table.greyGridTable td, table.greyGridTable th {
        border: 1px solid #FFFFFF;
        padding: 3px 4px;
    }
    table.greyGridTable td.grey {
        background: #EBEBEB;
    }
    table.greyGridTable thead {
        background: #FFFFFF;
        border-bottom: 4px solid #333333;
    }
    table.greyGridTable thead th {
        font-weight: bold;
        color: #333333;
        text-align: center;
        border-left: 2px solid #333333;
    }
    table.greyGridTable thead th:first-child {
        border-left: none;
    }

    table.greyGridTable tfoot {
        font-weight: bold;
        color: #333333;
        border-top: 4px solid #333333;
    }
    table.greyGridTable tfoot td {
        font-size: 14px;
    }
    table.greyGridTable td.min {
        width: 5%;
        white-space: nowrap;
    }
</style>