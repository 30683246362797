<template>
  <div>
    <v-row>
      <v-col xl="1" lg="1" md="1" sm="1" xs="1">
        <img :src="'https://server.dont-release.fr/affixMedia/' + affixes[0].id" />
      </v-col>
      <v-col xl="1" lg="1" md="1" sm="1" xs="1">
        <img :src="'https://server.dont-release.fr/affixMedia/' + affixes[1].id" />
      </v-col>
      <v-col xl="1" lg="1" md="1" sm="1" xs="1">
        <img :src="'https://server.dont-release.fr/affixMedia/' + affixes[2].id" />
      </v-col>
      <v-col xl="1" lg="1" md="1" sm="1" xs="1">
        <img :src="'https://server.dont-release.fr/affixMedia/' + affixes[3].id" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: "Affixes",
    data () {
      return {
        affixes: [],
      }
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData(){
            const res = await fetch('https://server.dont-release.fr/affixes');
            const data = await res.json();
            
            this.affixes = data.affix_details;
        }
    }
  }
</script>