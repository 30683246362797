import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import guilde from './modules/guilde'
import classes from './modules/classes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    guilde,
    classes
  }
});