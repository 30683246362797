export default [
    { id: 0, name: "Guardian", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2512-icon.jpg" },
    { id: 1, name: "Dausegne", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2540-icon.jpg" },
    { id: 2, name: "Wishmox", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2553-icon.jpg" },
    { id: 3, name: "Pantheon", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2544-icon.jpg" },
    { id: 4, name: "Skrolex", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2542-icon.jpg" },
    { id: 5, name: "Halondrus", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2529-icon.jpg" },
    { id: 6, name: "Lihuvim", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2539-icon.jpg" },
    { id: 7, name: "Anduin", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2546-icon.jpg" },
    { id: 8, name: "Dread", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2543-icon.jpg" },
    { id: 9, name: "Rygelon", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2549-icon.jpg" },
    { id: 10, name: "Geolier", icon: "https://assets.rpglogs.com/img/warcraft/bosses/2537-icon.jpg" }
]