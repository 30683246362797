<template>
  <div class="mx-4">
    <v-row class="mb-4">
        <div class="py-5 px-5" style="width: 100%">
            <GlobalLogListNM />
        </div>
    </v-row>
  </div>
</template>

<script>
import GlobalLogListNM from '../../page/GlobalLogList.vue'
export default {
  name: 'AllNM',

  components: { GlobalLogListNM  },

  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style>

</style>