<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-sheet
        class="pa-4"
        style="text-align: center;"
      >
        <v-avatar
            class="mb-4"
            color="grey darken-1"
            size="128"
        >
            <img
                src="https://cdn.discordapp.com/icons/692162531560652972/3258fb9e80fff1b36f0b3bc2c2aed092.png"
                alt="John"
            >
        </v-avatar>
        <v-row>
            <v-spacer></v-spacer>
            <v-btn 
                icon
                href="https://worldofwarcraft.com/fr-fr/guild/eu/confr%C3%A9rie-du-thorium/dont-release"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/wow-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                href="https://raider.io/guilds/eu/confr%C3%A9rie-du-thorium/Dont%20release"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/brand/Icon_FullColor.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                href="https://fr.warcraftlogs.com/guild/reports-list/544486/"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/warcraftlogs-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />  
            </v-btn>
            <v-btn 
                icon
                href="https://discord.gg/DvNvFbH"
                target="_blank" >
                <img
                    src="https://e1.pngegg.com/pngimages/916/717/png-clipart-clay-os-6-a-macos-icon-discord-round-blue-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />  
            </v-btn>
            <v-spacer></v-spacer>
        </v-row>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, link] in links"
          :key="icon"
          link :to="'/' + link"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar v-if="$vuetify.breakpoint.mdAndDown">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <v-btn 
                icon
                href="https://worldofwarcraft.com/fr-fr/guild/eu/confr%C3%A9rie-du-thorium/dont-release"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/wow-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                href="https://raider.io/guilds/eu/confr%C3%A9rie-du-thorium/Dont%20release"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/brand/Icon_FullColor.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                href="https://fr.warcraftlogs.com/guild/reports-list/544486/"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/warcraftlogs-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />  
            </v-btn>
            <v-btn 
                icon
                href="https://discord.gg/DvNvFbH"
                target="_blank" >
                <img
                    src="https://e1.pngegg.com/pngimages/916/717/png-clipart-clay-os-6-a-macos-icon-discord-round-blue-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />  
            </v-btn>
        </v-toolbar>
    <v-main>
      <v-container
        class="py-8 px-6"
        fluid
      >
        <vue-page-transition name="fade-in-up">
            <router-view></router-view>
        </vue-page-transition>
      </v-container>
    </v-main>
    <notifications position="bottom right" group="app" />
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: null,
      links: [
        ['mdi-home', 'Accueil', 'home'],
        ['mdi-account-group', 'Guilde', 'guilde'],
        ['mdi-chess-queen', 'Roster', 'roster'],
        ['mdi-sword', 'All Raid NM', 'allnm'],
        ['mdi-pickaxe', 'Raid NM', 'nm'],
        ['mdi-axe', 'Raid HM', 'hm'],
        ['mdi-hexagram-outline', 'SOD NM', 'SOD_nm'],
        ['mdi-hexagram', 'SOD HM', 'SOD_hm'],
        ['mdi-chart-pie', 'Statistiques', 'statistiques'],
      ],
    }),
    mounted: function() {
        this.$store.dispatch("classes/load");
        this.$store.dispatch("guilde/loadMembers");
    }
  }
</script>