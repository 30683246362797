<template>
    <v-card
        class="mx-auto"
        color="#001845"
        dark
        max-width="400"
    >
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-battlenet
            </v-icon>
            <span class="title font-weight-light">{{name}}</span>
        </v-card-title>

        <v-card-text class="headline font-weight-bold">
            {{tag}}
        </v-card-text>
    </v-card>
</template>
<script>
    export default {
        name: "BnetCard",
        props: ['name', 'tag']
    }
</script>