import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/index.vue'
import Guilde from '../views/Guilde/index.vue'
import Roster from '../views/Roster/index.vue'
import NM from '../views/NM/index.vue'
import AllNM from '../views/AllNM/index.vue'
import HM from '../views/HM/index.vue'
import SOD_NM from '../views/SOD_NM/index.vue'
import SOD_HM from '../views/SOD_HM/index.vue'
import Statistiques from '../views/Statistiques/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/guilde',
    name: 'guilde',
    component: Guilde
  },
  {
    path: '/roster',
    name: 'roster',
    component: Roster
  },
  {
    path: '/nm',
    name: 'nm',
    component: NM
  },
  {
    path: '/allnm',
    name: 'allnm',
    component: AllNM
  },
  {
    path: '/hm',
    name: 'hm',
    component: HM
  },
  {
    path: '/SOD_nm',
    name: 'SOD_nm',
    component: SOD_NM
  },
  {
    path: '/SOD_hm',
    name: 'SOD_hm',
    component: SOD_HM
  },
  {
    path: '/statistiques',
    name: 'statistiques',
    component: Statistiques
  }
]

const router = new VueRouter({
  routes
})

export default router
