export default [
    {                        
        "Guardian": [{"Parse %":84,"Name":"Nightpumà","Amount":"2559667$11.70%2.56m","Ilvl":250,"Ilvl %":79,"Active":"82.25%","DPS":"5,491.8","":""},{"Parse %":56,"Name":"Kërronz","Amount":"2556839$11.69%2.56m","Ilvl":249,"Ilvl %":44,"Active":"84.91%","DPS":"5,485.7","":""},{"Parse %":44,"Name":"Mahbit","Amount":"2371841$10.85%2.37m","Ilvl":248,"Ilvl %":23,"Active":"84.80%","DPS":"5,088.8","":""},{"Parse %":43,"Name":"Dryhwa","Amount":"2264603$10.36%2.26m","Ilvl":242,"Ilvl %":63,"Active":"80.54%","DPS":"4,858.7","":""},{"Parse %":16,"Name":"Mamyhanzo","Amount":"2071508$9.47%2.07m","Ilvl":239,"Ilvl %":34,"Active":"80.24%","DPS":"4,444.4","":""},{"Parse %":33,"Name":"Blackbétty","Amount":"2033257$9.30%2.03m","Ilvl":245,"Ilvl %":20,"Active":"82.92%","DPS":"4,362.4","":""},{"Parse %":9,"Name":"Araghast","Amount":"1738601$7.95%1.74m","Ilvl":229,"Ilvl %":32,"Active":"76.62%","DPS":"3,730.2","":""},{"Parse %":87,"Name":"Redm","Amount":"1694136$7.75%1.69m","Ilvl":244,"Ilvl %":97,"Active":"83.94%","DPS":"3,634.8","":""},{"Parse %":12,"Name":"Mevanana","Amount":"1670822$7.64%1.67m","Ilvl":235,"Ilvl %":54,"Active":"66.83%","DPS":"3,584.8","":""},{"Parse %":17,"Name":"Merrigus","Amount":"1628922$7.45%1.63m","Ilvl":249,"Ilvl %":7,"Active":"69.80%","DPS":"3,494.9","":""},{"Parse %":18,"Name":"Loudda","Amount":"1156741$5.29%1.16m","Ilvl":243,"Ilvl %":28,"Active":"78.45%","DPS":"2,481.8","":""},{"Parse %":33,"Name":"Myanaa","Amount":"94000$0.43%94.0k","Ilvl":241,"Ilvl %":43,"Active":"7.59%","DPS":201.7,"":""},{"Parse %":13,"Name":"Syrane","Amount":"27992$0.13%28.0k","Ilvl":242,"Ilvl %":18,"Active":"0.00%","DPS":60.1,"":""}],
        "Dausegne": [{"Parse %":51,"Name":"Blackbétty","Amount":"2018716$12.01%2.02m","Ilvl":249,"Ilvl %":50,"Active":"95.95%","DPS":"6,839.6","":""},{"Parse %":61,"Name":"Likene","Amount":"2016860$12.00%2.02m","Ilvl":251,"Ilvl %":35,"Active":"99.50%","DPS":"6,833.3","":""},{"Parse %":62,"Name":"Nightpumà","Amount":"1907236$11.35%1.91m","Ilvl":250,"Ilvl %":56,"Active":"91.60%","DPS":"6,461.9","":""},{"Parse %":53,"Name":"Mahbit","Amount":"1872256$11.14%1.87m","Ilvl":249,"Ilvl %":39,"Active":"98.76%","DPS":"6,343.4","":""},{"Parse %":50,"Name":"Merrigus","Amount":"1867087$11.11%1.87m","Ilvl":250,"Ilvl %":44,"Active":"99.88%","DPS":"6,325.9","":""},{"Parse %":32,"Name":"Mamyhanzo","Amount":"1840687$10.95%1.84m","Ilvl":240,"Ilvl %":74,"Active":"99.50%","DPS":"6,236.4","":""},{"Parse %":18,"Name":"Dryhwa","Amount":"1580981$9.41%1.58m","Ilvl":248,"Ilvl %":10,"Active":"99.50%","DPS":"5,356.5","":""},{"Parse %":65,"Name":"Redm","Amount":"1192507$7.09%1.19m","Ilvl":251,"Ilvl %":51,"Active":"92.10%","DPS":"4,040.3","":""},{"Parse %":35,"Name":"Loudda","Amount":"878054$5.22%878.1k","Ilvl":245,"Ilvl %":46,"Active":"99.54%","DPS":"2,974.9","":""},{"Parse %":9,"Name":"Mevanana","Amount":"877902$5.22%877.9k","Ilvl":237,"Ilvl %":17,"Active":"65.81%","DPS":"2,974.4","":""},{"Parse %":84,"Name":"Noffs","Amount":"649568$3.86%649.6k","Ilvl":252,"Ilvl %":80,"Active":"88.69%","DPS":"2,200.8","":""},{"Parse %":26,"Name":"Myanaa","Amount":"74105$0.44%74.1k","Ilvl":243,"Ilvl %":34,"Active":"14.06%","DPS":251.1,"":""},{"Parse %":14,"Name":"Syrane","Amount":"33028$0.20%33.0k","Ilvl":244,"Ilvl %":18,"Active":"5.92%","DPS":111.9,"":""}],
        "Wishmox": [{"Parse %":35,"Name":"Dryhwa","Amount":"2660570$12.09%2.66m","Ilvl":243,"Ilvl %":56,"Active":"99.50%","DPS":"5,677.2","":""},{"Parse %":52,"Name":"Blackbétty","Amount":"2592149$11.78%2.59m","Ilvl":246,"Ilvl %":57,"Active":"99.31%","DPS":"5,531.2","":""},{"Parse %":49,"Name":"Merrigus","Amount":"2554102$11.60%2.55m","Ilvl":248,"Ilvl %":43,"Active":"94.16%","DPS":"5,450.1","":""},{"Parse %":42,"Name":"Mahbit","Amount":"2547188$11.57%2.55m","Ilvl":248,"Ilvl %":29,"Active":"82.87%","DPS":"5,435.3","":""},{"Parse %":29,"Name":"Mamyhanzo","Amount":"2418082$10.99%2.42m","Ilvl":239,"Ilvl %":59,"Active":"87.11%","DPS":"5,159.8","":""},{"Parse %":30,"Name":"Nightpumà","Amount":"2071784$9.41%2.07m","Ilvl":250,"Ilvl %":26,"Active":"78.92%","DPS":"4,420.9","":""},{"Parse %":84,"Name":"Redm","Amount":"1945663$8.84%1.95m","Ilvl":244,"Ilvl %":100,"Active":"99.70%","DPS":"4,151.7","":""},{"Parse %":60,"Name":"Kërronz-tank","Amount":"1546762$7.03%1.55m","Ilvl":249,"Ilvl %":58,"Active":"96.81%","DPS":"3,300.5","":""},{"Parse %":8,"Name":"Araghast","Amount":"1307004$5.94%1.31m","Ilvl":229,"Ilvl %":30,"Active":"70.91%","DPS":"2,788.9","":""},{"Parse %":9,"Name":"Mevanana","Amount":"1239152$5.63%1.24m","Ilvl":236,"Ilvl %":"19*","Active":"62.64%","DPS":"2,644.2","":""},{"Parse %":84,"Name":"Noffs","Amount":"925052$4.20%925.1k","Ilvl":252,"Ilvl %":80,"Active":"97.22%","DPS":"1,973.9","":""},{"Parse %":47,"Name":"Myanaa","Amount":"178258$0.81%178.3k","Ilvl":241,"Ilvl %":62,"Active":"20.05%","DPS":380.4,"":""},{"Parse %":9,"Name":"Syrane","Amount":"23496$0.11%23.5k","Ilvl":242,"Ilvl %":13,"Active":"0.00%","DPS":50.1,"":""}],
        "Pantheon": [{"Parse %":54,"Name":"Nightpumà","Amount":"2617543$11.34%2.62m","Ilvl":250,"Ilvl %":54,"Active":"90.23%","DPS":"7,487.2","":""},{"Parse %":55,"Name":"Blackbétty","Amount":"2531081$10.97%2.53m","Ilvl":250,"Ilvl %":58,"Active":"90.76%","DPS":"7,239.9","":""},{"Parse %":52,"Name":"Mahbit","Amount":"2516936$10.91%2.52m","Ilvl":249,"Ilvl %":41,"Active":"91.72%","DPS":"7,199.4","":""},{"Parse %":54,"Name":"Merrigus","Amount":"2463743$10.68%2.46m","Ilvl":250,"Ilvl %":53,"Active":"90.41%","DPS":"7,047.2","":""},{"Parse %":50,"Name":"Likene","Amount":"2406116$10.43%2.41m","Ilvl":251,"Ilvl %":27,"Active":"90.89%","DPS":"6,882.4","":""},{"Parse %":28,"Name":"Mamyhanzo","Amount":"2269363$9.83%2.27m","Ilvl":240,"Ilvl %":71,"Active":"90.07%","DPS":"6,491.2","":""},{"Parse %":31,"Name":"Dryhwa","Amount":"2024494$8.77%2.02m","Ilvl":248,"Ilvl %":24,"Active":"81.01%","DPS":"5,790.8","":""},{"Parse %":77,"Name":"Redm","Amount":"1882891$8.16%1.88m","Ilvl":251,"Ilvl %":69,"Active":"90.16%","DPS":"5,385.8","":""},{"Parse %":11,"Name":"Mevanana","Amount":"1757430$7.62%1.76m","Ilvl":237,"Ilvl %":36,"Active":"84.41%","DPS":"5,026.9","":""},{"Parse %":44,"Name":"Loudda","Amount":"1518565$6.58%1.52m","Ilvl":245,"Ilvl %":60,"Active":"89.86%","DPS":"4,343.7","":""},{"Parse %":77,"Name":"Noffs","Amount":"724581$3.14%724.6k","Ilvl":252,"Ilvl %":72,"Active":"76.39%","DPS":"2,072.6","":""},{"Parse %":75,"Name":"Myanaa","Amount":"298230$1.29%298.2k","Ilvl":243,"Ilvl %":84,"Active":"35.45%","DPS":853.1,"":""},{"Parse %":19,"Name":"Syrane","Amount":"63620$0.28%63.6k","Ilvl":244,"Ilvl %":25,"Active":"6.31%","DPS":182,"":""}],
        "Skrolex": [{"Parse %":81,"Name":"Merrigus","Amount":"2436846$13.11%2.44m","Ilvl":247,"Ilvl %":92,"Active":"99.32%","DPS":"7,285.5","":""},{"Parse %":81,"Name":"Nightpumà","Amount":"2239624$12.05%2.24m","Ilvl":250,"Ilvl %":77,"Active":"99.18%","DPS":"6,695.9","":""},{"Parse %":76,"Name":"Mahbit","Amount":"2229663$12.00%2.23m","Ilvl":248,"Ilvl %":66,"Active":"99.76%","DPS":"6,666.1","":""},{"Parse %":52,"Name":"Dryhwa","Amount":"2222989$11.96%2.22m","Ilvl":243,"Ilvl %":79,"Active":"99.59%","DPS":"6,646.1","":""},{"Parse %":15,"Name":"Mamyhanzo","Amount":"1860113$10.01%1.86m","Ilvl":239,"Ilvl %":32,"Active":"85.24%","DPS":"5,561.2","":""},{"Parse %":81,"Name":"Redm","Amount":"1444993$7.78%1.44m","Ilvl":244,"Ilvl %":97,"Active":"99.48%","DPS":"4,320.1","":""},{"Parse %":23,"Name":"Araghast","Amount":"1436097$7.73%1.44m","Ilvl":229,"Ilvl %":57,"Active":"91.58%","DPS":"4,293.5","":""},{"Parse %":26,"Name":"Mevanana","Amount":"1363096$7.33%1.36m","Ilvl":236,"Ilvl %":49,"Active":"98.95%","DPS":"4,075.3","":""},{"Parse %":22,"Name":"Blackbétty","Amount":"1278649$6.88%1.28m","Ilvl":245,"Ilvl %":16,"Active":"80.57%","DPS":"3,822.8","":""},{"Parse %":67,"Name":"Kërronz-tank","Amount":"1141583$6.14%1.14m","Ilvl":249,"Ilvl %":60,"Active":"99.80%","DPS":"3,413.0","":""},{"Parse %":96,"Name":"Noffs","Amount":"853499$4.59%853.5k","Ilvl":252,"Ilvl %":94,"Active":"99.58%","DPS":"2,551.7","":""},{"Parse %":32,"Name":"Myanaa","Amount":"55782$0.30%55.8k","Ilvl":241,"Ilvl %":42,"Active":"11.37%","DPS":166.8,"":""},{"Parse %":17,"Name":"Syrane","Amount":"20711$0.11%20.7k","Ilvl":242,"Ilvl %":22,"Active":"0.00%","DPS":61.9,"":""}],
        "Halondrus": [],
        "Lihuvim": [{"Parse %":26,"Name":"Dryhwa","Amount":"2409076$12.09%2.41m","Ilvl":248,"Ilvl %":20,"Active":"99.39%","DPS":"6,356.7","":""},{"Parse %":46,"Name":"Blackbétty","Amount":"2402895$12.06%2.40m","Ilvl":250,"Ilvl %":52,"Active":"96.33%","DPS":"6,340.4","":""},{"Parse %":47,"Name":"Mahbit","Amount":"2383540$11.96%2.38m","Ilvl":249,"Ilvl %":35,"Active":"99.31%","DPS":"6,289.3","":""},{"Parse %":60,"Name":"Nightpumà","Amount":"2129171$10.69%2.13m","Ilvl":250,"Ilvl %":62,"Active":"99.05%","DPS":"5,618.1","":""},{"Parse %":26,"Name":"Mamyhanzo","Amount":"2100328$10.54%2.10m","Ilvl":240,"Ilvl %":71,"Active":"96.18%","DPS":"5,542.0","":""},{"Parse %":39,"Name":"Likene","Amount":"1893254$9.50%1.89m","Ilvl":251,"Ilvl %":20,"Active":"99.54%","DPS":"4,995.6","":""},{"Parse %":34,"Name":"Merrigus","Amount":"1826967$9.17%1.83m","Ilvl":251,"Ilvl %":16,"Active":"99.12%","DPS":"4,820.7","":""},{"Parse %":83,"Name":"Redm","Amount":"1580552$7.93%1.58m","Ilvl":251,"Ilvl %":80,"Active":"95.57%","DPS":"4,170.5","":""},{"Parse %":4,"Name":"Mevanana","Amount":"1311421$6.58%1.31m","Ilvl":237,"Ilvl %":8,"Active":"95.87%","DPS":"3,460.4","":""},{"Parse %":23,"Name":"Loudda","Amount":"1015753$5.10%1.02m","Ilvl":246,"Ilvl %":35,"Active":"90.40%","DPS":"2,680.2","":""},{"Parse %":46,"Name":"Noffs","Amount":"596745$3.00%596.7k","Ilvl":252,"Ilvl %":38,"Active":"92.61%","DPS":"1,574.6","":""},{"Parse %":45,"Name":"Myanaa","Amount":"208019$1.04%208.0k","Ilvl":243,"Ilvl %":57,"Active":"39.08%","DPS":548.9,"":""},{"Parse %":16,"Name":"Syrane","Amount":"63701$0.32%63.7k","Ilvl":245,"Ilvl %":20,"Active":"17.91%","DPS":168.1,"":""}],
        "Anduin": [],
        "Dread": [],
        "Rygelon": [],
        "Geolier": []
    },
    {                        
        "Guardian": [{"Parse %":95,"Name":"Blackbétty","Amount":"3175837$11.41%3.18m","Ilvl":252,"Ilvl %":95,"Active":"93.04%","DPS":"7,317.9","":""},{"Parse %":92,"Name":"Mahbit","Amount":"3157630$11.35%3.16m","Ilvl":252,"Ilvl %":87,"Active":"94.45%","DPS":"7,276.0","":""},{"Parse %":90,"Name":"Merrigus","Amount":"2871480$10.32%2.87m","Ilvl":254,"Ilvl %":70,"Active":"88.50%","DPS":"6,616.6","":""},{"Parse %":90,"Name":"Nightpumà","Amount":"2721294$9.78%2.72m","Ilvl":255,"Ilvl %":65,"Active":"94.42%","DPS":"6,270.5","":""},{"Parse %":49,"Name":"Mamyhanzo","Amount":"2635424$9.47%2.64m","Ilvl":253,"Ilvl %":32,"Active":"95.86%","DPS":"6,072.7","":""},{"Parse %":70,"Name":"Likene","Amount":"2482323$8.92%2.48m","Ilvl":254,"Ilvl %":33,"Active":"93.55%","DPS":"5,719.9","":""},{"Parse %":52,"Name":"Dryhwa","Amount":"2477532$8.90%2.48m","Ilvl":252,"Ilvl %":34,"Active":"91.60%","DPS":"5,708.8","":""},{"Parse %":14,"Name":"Araghast","Amount":"1939640$6.97%1.94m","Ilvl":229,"Ilvl %":65,"Active":"80.40%","DPS":"4,469.4","":""},{"Parse %":20,"Name":"Mevanana","Amount":"1921249$6.90%1.92m","Ilvl":244,"Ilvl %":34,"Active":"91.37%","DPS":"4,427.0","":""},{"Parse %":90,"Name":"Redm","Amount":"1837653$6.60%1.84m","Ilvl":254,"Ilvl %":80,"Active":"83.71%","DPS":"4,234.4","":""},{"Parse %":79,"Name":"Loudda","Amount":"1723787$6.20%1.72m","Ilvl":249,"Ilvl %":84,"Active":"99.22%","DPS":"3,972.0","":""},{"Parse %":64,"Name":"Noffs","Amount":"631119$2.27%631.1k","Ilvl":257,"Ilvl %":42,"Active":"85.48%","DPS":"1,454.3","":""},{"Parse %":52,"Name":"Myanaa","Amount":"174426$0.63%174.4k","Ilvl":250,"Ilvl %":52,"Active":"38.59%","DPS":401.9,"":""},{"Parse %":25,"Name":"Syrane","Amount":"75953$0.27%76.0k","Ilvl":246,"Ilvl %":30,"Active":"0.61%","DPS":175,"":""}],
        "Dausegne": [{"Parse %":79,"Name":"Merrigus","Amount":"2241079$12.64%2.24m","Ilvl":254,"Ilvl %":43,"Active":"92.44%","DPS":"8,033.4","":""},{"Parse %":65,"Name":"Blackbétty","Amount":"2133098$12.03%2.13m","Ilvl":252,"Ilvl %":57,"Active":"98.63%","DPS":"7,646.4","":""},{"Parse %":63,"Name":"Nightpumà","Amount":"1850673$10.44%1.85m","Ilvl":255,"Ilvl %":19,"Active":"98.86%","DPS":"6,634.0","":""},{"Parse %":38,"Name":"Dryhwa","Amount":"1849220$10.43%1.85m","Ilvl":252,"Ilvl %":19,"Active":"99.43%","DPS":"6,628.8","":""},{"Parse %":37,"Name":"Mamyhanzo","Amount":"1840231$10.38%1.84m","Ilvl":253,"Ilvl %":20,"Active":"99.61%","DPS":"6,596.5","":""},{"Parse %":54,"Name":"Mahbit","Amount":"1825852$10.30%1.83m","Ilvl":252,"Ilvl %":29,"Active":"99.73%","DPS":"6,545.0","":""},{"Parse %":37,"Name":"Likene","Amount":"1642013$9.26%1.64m","Ilvl":254,"Ilvl %":9,"Active":"71.13%","DPS":"5,886.0","":""},{"Parse %":68,"Name":"Redm","Amount":"1182131$6.67%1.18m","Ilvl":254,"Ilvl %":34,"Active":"94.74%","DPS":"4,237.5","":""},{"Parse %":9,"Name":"Mevanana","Amount":"907576$5.12%907.6k","Ilvl":244,"Ilvl %":17,"Active":"77.90%","DPS":"3,253.3","":""},{"Parse %":21,"Name":"Loudda","Amount":"770099$4.34%770.1k","Ilvl":249,"Ilvl %":18,"Active":"89.50%","DPS":"2,760.5","":""},{"Parse %":5,"Name":"Araghast","Amount":"737188$4.16%737.2k","Ilvl":229,"Ilvl %":25,"Active":"66.95%","DPS":"2,642.5","":""},{"Parse %":56,"Name":"Noffs","Amount":"478078$2.70%478.1k","Ilvl":257,"Ilvl %":21,"Active":"72.26%","DPS":"1,713.7","":""},{"Parse %":60,"Name":"Myanaa","Amount":"239763$1.35%239.8k","Ilvl":250,"Ilvl %":65,"Active":"47.82%","DPS":859.5,"":""},{"Parse %":13,"Name":"Syrane","Amount":"32339$0.18%32.3k","Ilvl":247,"Ilvl %":16,"Active":"14.92%","DPS":115.9,"":""}],
        "Wishmox": [{"Parse %":90,"Name":"Blackbétty","Amount":"3051815$12.85%3.05m","Ilvl":252,"Ilvl %":94,"Active":"99.46%","DPS":"8,403.7","":""},{"Parse %":80,"Name":"Likene","Amount":"2605496$10.97%2.61m","Ilvl":254,"Ilvl %":51,"Active":"81.91%","DPS":"7,174.7","":""},{"Parse %":68,"Name":"Mahbit","Amount":"2457909$10.35%2.46m","Ilvl":252,"Ilvl %":52,"Active":"99.65%","DPS":"6,768.3","":""},{"Parse %":83,"Name":"Nightpumà","Amount":"2375748$10.00%2.38m","Ilvl":255,"Ilvl %":50,"Active":"99.19%","DPS":"6,542.0","":""},{"Parse %":51,"Name":"Mamyhanzo","Amount":"2348878$9.89%2.35m","Ilvl":253,"Ilvl %":39,"Active":"99.31%","DPS":"6,468.0","":""},{"Parse %":65,"Name":"Merrigus","Amount":"2329634$9.81%2.33m","Ilvl":254,"Ilvl %":34,"Active":"99.93%","DPS":"6,415.1","":""},{"Parse %":40,"Name":"Dryhwa","Amount":"2315441$9.75%2.32m","Ilvl":253,"Ilvl %":26,"Active":"99.61%","DPS":"6,376.0","":""},{"Parse %":81,"Name":"Redm","Amount":"1535586$6.47%1.54m","Ilvl":255,"Ilvl %":56,"Active":"99.48%","DPS":"4,228.5","":""},{"Parse %":15,"Name":"Araghast","Amount":"1388397$5.85%1.39m","Ilvl":230,"Ilvl %":54,"Active":"96.08%","DPS":"3,823.2","":""},{"Parse %":64,"Name":"Loudda","Amount":"1264891$5.33%1.26m","Ilvl":249,"Ilvl %":69,"Active":"99.41%","DPS":"3,483.1","":""},{"Parse %":12,"Name":"Mevanana","Amount":"1250497$5.27%1.25m","Ilvl":245,"Ilvl %":16,"Active":"96.23%","DPS":"3,443.5","":""},{"Parse %":71,"Name":"Noffs","Amount":"658875$2.77%658.9k","Ilvl":257,"Ilvl %":43,"Active":"96.63%","DPS":"1,814.3","":""},{"Parse %":40,"Name":"Myanaa","Amount":"119782$0.50%119.8k","Ilvl":250,"Ilvl %":43,"Active":"23.89%","DPS":329.8,"":""},{"Parse %":17,"Name":"Syrane","Amount":"43694$0.18%43.7k","Ilvl":247,"Ilvl %":22,"Active":"0.00%","DPS":120.3,"":""}],
        "Pantheon": [{"Parse %":85,"Name":"Merrigus","Amount":"2985349$12.00%2.99m","Ilvl":254,"Ilvl %":56,"Active":"86.09%","DPS":"9,010.5","":""},{"Parse %":59,"Name":"Mamyhanzo","Amount":"2717444$10.92%2.72m","Ilvl":253,"Ilvl %":49,"Active":"88.40%","DPS":"8,201.9","":""},{"Parse %":64,"Name":"Nightpumà","Amount":"2694164$10.83%2.69m","Ilvl":255,"Ilvl %":22,"Active":"85.97%","DPS":"8,131.6","":""},{"Parse %":63,"Name":"Blackbétty","Amount":"2580196$10.37%2.58m","Ilvl":252,"Ilvl %":53,"Active":"88.63%","DPS":"7,787.6","":""},{"Parse %":60,"Name":"Mahbit","Amount":"2560118$10.29%2.56m","Ilvl":252,"Ilvl %":40,"Active":"91.31%","DPS":"7,727.0","":""},{"Parse %":61,"Name":"Likene","Amount":"2511629$10.10%2.51m","Ilvl":254,"Ilvl %":25,"Active":"77.38%","DPS":"7,580.7","":""},{"Parse %":27,"Name":"Dryhwa","Amount":"1909379$7.68%1.91m","Ilvl":252,"Ilvl %":11,"Active":"85.97%","DPS":"5,762.9","":""},{"Parse %":79,"Name":"Redm","Amount":"1868262$7.51%1.87m","Ilvl":255,"Ilvl %":53,"Active":"89.78%","DPS":"5,638.8","":""},{"Parse %":49,"Name":"Loudda","Amount":"1513774$6.09%1.51m","Ilvl":249,"Ilvl %":50,"Active":"89.58%","DPS":"4,568.9","":""},{"Parse %":5,"Name":"Araghast","Amount":"1368978$5.50%1.37m","Ilvl":229,"Ilvl %":35,"Active":"72.21%","DPS":"4,131.9","":""},{"Parse %":4,"Name":"Mevanana","Amount":"1188867$4.78%1.19m","Ilvl":245,"Ilvl %":6,"Active":"67.30%","DPS":"3,588.3","":""},{"Parse %":59,"Name":"Noffs","Amount":"581271$2.34%581.3k","Ilvl":257,"Ilvl %":29,"Active":"73.27%","DPS":"1,754.4","":""},{"Parse %":82,"Name":"Myanaa","Amount":"375914$1.51%375.9k","Ilvl":250,"Ilvl %":87,"Active":"47.34%","DPS":"1,134.6","":""},{"Parse %":7,"Name":"Syrane","Amount":"18487$0.07%18.5k","Ilvl":247,"Ilvl %":9,"Active":"0.00%","DPS":55.8,"":""}],
        "Skrolex": [{"Parse %":88,"Name":"Merrigus","Amount":"2306685$11.54%2.31m","Ilvl":254,"Ilvl %":60,"Active":"99.22%","DPS":"8,321.0","":""},{"Parse %":82,"Name":"Blackbétty","Amount":"2229367$11.15%2.23m","Ilvl":252,"Ilvl %":81,"Active":"99.55%","DPS":"8,042.0","":""},{"Parse %":95,"Name":"Nightpumà","Amount":"2155889$10.78%2.16m","Ilvl":255,"Ilvl %":73,"Active":"98.90%","DPS":"7,777.0","":""},{"Parse %":64,"Name":"Mamyhanzo","Amount":"2098456$10.50%2.10m","Ilvl":253,"Ilvl %":46,"Active":"99.45%","DPS":"7,569.8","":""},{"Parse %":71,"Name":"Likene","Amount":"1997361$9.99%2.00m","Ilvl":254,"Ilvl %":27,"Active":"99.06%","DPS":"7,205.1","":""},{"Parse %":49,"Name":"Dryhwa","Amount":"1945446$9.73%1.95m","Ilvl":253,"Ilvl %":27,"Active":"99.39%","DPS":"7,017.8","":""},{"Parse %":70,"Name":"Mahbit","Amount":"1877607$9.39%1.88m","Ilvl":252,"Ilvl %":45,"Active":"99.86%","DPS":"6,773.1","":""},{"Parse %":84,"Name":"Redm","Amount":"1332029$6.66%1.33m","Ilvl":255,"Ilvl %":59,"Active":"99.37%","DPS":"4,805.1","":""},{"Parse %":26,"Name":"Mevanana","Amount":"1247312$6.24%1.25m","Ilvl":245,"Ilvl %":34,"Active":"99.23%","DPS":"4,499.5","":""},{"Parse %":19,"Name":"Araghast","Amount":"1211154$6.06%1.21m","Ilvl":230,"Ilvl %":65,"Active":"98.90%","DPS":"4,369.0","":""},{"Parse %":37,"Name":"Loudda","Amount":"838458$4.19%838.5k","Ilvl":249,"Ilvl %":32,"Active":"99.51%","DPS":"3,024.6","":""},{"Parse %":89,"Name":"Noffs","Amount":"625451$3.13%625.5k","Ilvl":257,"Ilvl %":70,"Active":"93.94%","DPS":"2,256.2","":""},{"Parse %":52,"Name":"Myanaa","Amount":"104494$0.52%104.5k","Ilvl":250,"Ilvl %":54,"Active":"17.85%","DPS":376.9,"":""},{"Parse %":18,"Name":"Syrane","Amount":"22297$0.11%22.3k","Ilvl":247,"Ilvl %":22,"Active":"0.00%","DPS":80.4,"":""}],
        "Halondrus": [{"Parse %":31,"Name":"Dryhwa","Amount":"3206764$14.82%3.21m","Ilvl":255,"Ilvl %":9,"Active":"99.88%","DPS":"7,426.5","":""},{"Parse %":45,"Name":"Mamyhanzo","Amount":"2923797$13.52%2.92m","Ilvl":256,"Ilvl %":23,"Active":"99.87%","DPS":"6,771.2","":""},{"Parse %":51,"Name":"Blackbétty","Amount":"2919153$13.49%2.92m","Ilvl":252,"Ilvl %":50,"Active":"99.70%","DPS":"6,760.5","":""},{"Parse %":56,"Name":"Mahbit","Amount":"2826607$13.07%2.83m","Ilvl":252,"Ilvl %":48,"Active":"99.93%","DPS":"6,546.1","":""},{"Parse %":48,"Name":"Likene","Amount":"2338405$10.81%2.34m","Ilvl":255,"Ilvl %":29,"Active":"87.01%","DPS":"5,415.5","":""},{"Parse %":51,"Name":"Nightpumà","Amount":"2193266$10.14%2.19m","Ilvl":256,"Ilvl %":28,"Active":"99.42%","DPS":"5,079.4","":""},{"Parse %":82,"Name":"Redm","Amount":"1852965$8.57%1.85m","Ilvl":257,"Ilvl %":60,"Active":"99.96%","DPS":"4,291.3","":""},{"Parse %":74,"Name":"Loudda","Amount":"1540593$7.12%1.54m","Ilvl":253,"Ilvl %":74,"Active":"99.84%","DPS":"3,567.9","":""},{"Parse %":3,"Name":"Mevanana","Amount":"1098651$5.08%1.10m","Ilvl":246,"Ilvl %":4,"Active":"91.31%","DPS":"2,544.4","":""},{"Parse %":48,"Name":"Noffs","Amount":"599227$2.77%599.2k","Ilvl":257,"Ilvl %":33,"Active":"74.36%","DPS":"1,387.7","":""},{"Parse %":26,"Name":"Myanaa","Amount":"75156$0.35%75.2k","Ilvl":251,"Ilvl %":28,"Active":"12.02%","DPS":174.1,"":""},{"Parse %":21,"Name":"Syrane","Amount":"58486$0.27%58.5k","Ilvl":249,"Ilvl %":24,"Active":"13.77%","DPS":135.4,"":""}],
        "Lihuvim": [{"Parse %":73,"Name":"Mahbit","Amount":"2385556$11.09%2.39m","Ilvl":252,"Ilvl %":59,"Active":"96.58%","DPS":"7,080.6","":""},{"Parse %":44,"Name":"Dryhwa","Amount":"2377894$11.05%2.38m","Ilvl":253,"Ilvl %":30,"Active":"99.64%","DPS":"7,057.9","":""},{"Parse %":54,"Name":"Blackbétty","Amount":"2247124$10.45%2.25m","Ilvl":252,"Ilvl %":44,"Active":"99.45%","DPS":"6,669.7","":""},{"Parse %":54,"Name":"Mamyhanzo","Amount":"2188940$10.18%2.19m","Ilvl":253,"Ilvl %":46,"Active":"99.31%","DPS":"6,497.0","":""},{"Parse %":71,"Name":"Likene","Amount":"2119067$9.85%2.12m","Ilvl":254,"Ilvl %":41,"Active":"96.36%","DPS":"6,289.7","":""},{"Parse %":73,"Name":"Nightpumà","Amount":"2047989$9.52%2.05m","Ilvl":255,"Ilvl %":37,"Active":"98.67%","DPS":"6,078.7","":""},{"Parse %":53,"Name":"Merrigus","Amount":"1884903$8.76%1.88m","Ilvl":254,"Ilvl %":23,"Active":"93.89%","DPS":"5,594.6","":""},{"Parse %":13,"Name":"Mevanana","Amount":"1546485$7.19%1.55m","Ilvl":245,"Ilvl %":21,"Active":"97.67%","DPS":"4,590.2","":""},{"Parse %":74,"Name":"Redm","Amount":"1356687$6.31%1.36m","Ilvl":255,"Ilvl %":49,"Active":"96.51%","DPS":"4,026.8","":""},{"Parse %":3,"Name":"Araghast","Amount":"1266885$5.89%1.27m","Ilvl":229,"Ilvl %":28,"Active":"92.61%","DPS":"3,760.3","":""},{"Parse %":43,"Name":"Loudda","Amount":"1034547$4.81%1.03m","Ilvl":249,"Ilvl %":45,"Active":"94.02%","DPS":"3,070.7","":""},{"Parse %":52,"Name":"Noffs","Amount":"560275$2.60%560.3k","Ilvl":257,"Ilvl %":31,"Active":"81.09%","DPS":"1,663.0","":""},{"Parse %":69,"Name":"Myanaa","Amount":"351500$1.63%351.5k","Ilvl":250,"Ilvl %":75,"Active":"74.78%","DPS":"1,043.3","":""},{"Parse %":34,"Name":"Syrane","Amount":"143609$0.67%143.6k","Ilvl":247,"Ilvl %":42,"Active":"20.19%","DPS":426.2,"":""}],
        "Anduin": [],
        "Dread": [],
        "Rygelon": [],
        "Geolier": []
    },
    {                        
        "Guardian": [{"Parse %":77,"Name":"Mahbit","Amount":"2890593$16.80%2.89m","Ilvl":259,"Ilvl %":48,"Active":"93.33%","DPS":"6,980.0","":""},{"Parse %":72,"Name":"Blackbétty","Amount":"2701308$15.70%2.70m","Ilvl":253,"Ilvl %":74,"Active":"95.51%","DPS":"6,522.9","":""},{"Parse %":53,"Name":"Mamyhanzo","Amount":"2678346$15.57%2.68m","Ilvl":256,"Ilvl %":31,"Active":"88.83%","DPS":"6,467.5","":""},{"Parse %":81,"Name":"Nightpumà","Amount":"2504252$14.56%2.50m","Ilvl":256,"Ilvl %":61,"Active":"95.19%","DPS":"6,047.1","":""},{"Parse %":41,"Name":"Dryhwa","Amount":"2318761$13.48%2.32m","Ilvl":256,"Ilvl %":19,"Active":"95.37%","DPS":"5,599.2","":""},{"Parse %":60,"Name":"Loudda","Amount":"1549384$9.01%1.55m","Ilvl":254,"Ilvl %":42,"Active":"91.81%","DPS":"3,741.4","":""},{"Parse %":74,"Name":"Dread","Amount":"1460040$8.49%1.46m","Ilvl":251,"Ilvl %":74,"Active":"90.59%","DPS":"3,525.6","":""},{"Parse %":74,"Name":"Noffs","Amount":"683352$3.97%683.4k","Ilvl":259,"Ilvl %":63,"Active":"94.09%","DPS":"1,650.1","":""},{"Parse %":81,"Name":"Myanaa","Amount":"389636$2.26%389.6k","Ilvl":252,"Ilvl %":81,"Active":"38.65%","DPS":940.9,"":""},{"Parse %":10,"Name":"Syrane","Amount":"26904$0.16%26.9k","Ilvl":251,"Ilvl %":11,"Active":"0.00%","DPS":65,"":""}],
        "Dausegne": [{"Parse %":69,"Name":"Likene","Amount":"1984839$14.55%1.98m","Ilvl":256,"Ilvl %":50,"Active":"99.59%","DPS":"7,880.0","":""},{"Parse %":68,"Name":"Mahbit","Amount":"1851932$13.58%1.85m","Ilvl":259,"Ilvl %":33,"Active":"99.69%","DPS":"7,352.3","":""},{"Parse %":51,"Name":"Blackbétty","Amount":"1839860$13.49%1.84m","Ilvl":253,"Ilvl %":51,"Active":"98.52%","DPS":"7,304.4","":""},{"Parse %":50,"Name":"Mamyhanzo","Amount":"1822640$13.36%1.82m","Ilvl":256,"Ilvl %":27,"Active":"99.50%","DPS":"7,236.0","":""},{"Parse %":65,"Name":"Nightpumà","Amount":"1736730$12.73%1.74m","Ilvl":256,"Ilvl %":37,"Active":"99.23%","DPS":"6,895.0","":""},{"Parse %":31,"Name":"Dryhwa","Amount":"1631709$11.96%1.63m","Ilvl":256,"Ilvl %":9,"Active":"99.97%","DPS":"6,478.0","":""},{"Parse %":83,"Name":"Dread","Amount":"1030051$7.55%1.03m","Ilvl":251,"Ilvl %":84,"Active":"99.52%","DPS":"4,089.4","":""},{"Parse %":48,"Name":"Loudda","Amount":"855334$6.27%855.3k","Ilvl":254,"Ilvl %":32,"Active":"99.82%","DPS":"3,395.7","":""},{"Parse %":64,"Name":"Noffs","Amount":"477027$3.50%477.0k","Ilvl":259,"Ilvl %":46,"Active":"94.70%","DPS":"1,893.8","":""},{"Parse %":82,"Name":"Myanaa","Amount":"387259$2.84%387.3k","Ilvl":252,"Ilvl %":85,"Active":"80.22%","DPS":"1,537.4","":""},{"Parse %":9,"Name":"Syrane","Amount":"21367$0.16%21.4k","Ilvl":251,"Ilvl %":10,"Active":"0.52%","DPS":84.8,"":""}],
        "Wishmox": [{"Parse %":70,"Name":"Mahbit","Amount":"2683475$14.69%2.68m","Ilvl":259,"Ilvl %":45,"Active":"99.64%","DPS":"7,253.4","":""},{"Parse %":62,"Name":"Likene","Amount":"2442762$13.37%2.44m","Ilvl":257,"Ilvl %":31,"Active":"99.52%","DPS":"6,602.8","":""},{"Parse %":43,"Name":"Blackbétty","Amount":"2332757$12.77%2.33m","Ilvl":253,"Ilvl %":42,"Active":"99.32%","DPS":"6,305.4","":""},{"Parse %":39,"Name":"Mamyhanzo","Amount":"2295430$12.56%2.30m","Ilvl":256,"Ilvl %":23,"Active":"99.67%","DPS":"6,204.5","":""},{"Parse %":32,"Name":"Dryhwa","Amount":"2287471$12.52%2.29m","Ilvl":256,"Ilvl %":15,"Active":"99.68%","DPS":"6,183.0","":""},{"Parse %":60,"Name":"Nightpumà","Amount":"2154827$11.79%2.15m","Ilvl":256,"Ilvl %":36,"Active":"99.00%","DPS":"5,824.5","":""},{"Parse %":92,"Name":"Dread","Amount":"1605813$8.79%1.61m","Ilvl":251,"Ilvl %":94,"Active":"99.95%","DPS":"4,340.5","":""},{"Parse %":64,"Name":"Loudda","Amount":"1317586$7.21%1.32m","Ilvl":254,"Ilvl %":50,"Active":"96.58%","DPS":"3,561.4","":""},{"Parse %":75,"Name":"Noffs","Amount":"716233$3.92%716.2k","Ilvl":259,"Ilvl %":61,"Active":"99.35%","DPS":"1,936.0","":""},{"Parse %":81,"Name":"Myanaa","Amount":"395375$2.16%395.4k","Ilvl":252,"Ilvl %":84,"Active":"65.57%","DPS":"1,068.7","":""},{"Parse %":14,"Name":"Syrane","Amount":"40294$0.22%40.3k","Ilvl":251,"Ilvl %":15,"Active":"0.00%","DPS":108.9,"":""}],
        "Pantheon": [{"Parse %":52,"Name":"Mamyhanzo","Amount":"2900897$15.04%2.90m","Ilvl":257,"Ilvl %":21,"Active":"91.11%","DPS":"8,070.1","":""},{"Parse %":53,"Name":"Likene","Amount":"2739076$14.20%2.74m","Ilvl":257,"Ilvl %":20,"Active":"90.85%","DPS":"7,619.9","":""},{"Parse %":49,"Name":"Nightpumà","Amount":"2717677$14.09%2.72m","Ilvl":256,"Ilvl %":23,"Active":"87.04%","DPS":"7,560.4","":""},{"Parse %":39,"Name":"Mahbit","Amount":"2673458$13.86%2.67m","Ilvl":259,"Ilvl %":11,"Active":"91.40%","DPS":"7,437.4","":""},{"Parse %":33,"Name":"Blackbétty","Amount":"2464529$12.78%2.46m","Ilvl":253,"Ilvl %":28,"Active":"90.52%","DPS":"6,856.1","":""},{"Parse %":11,"Name":"Dryhwa","Amount":"1773030$9.19%1.77m","Ilvl":256,"Ilvl %":4,"Active":"90.35%","DPS":"4,932.4","":""},{"Parse %":61,"Name":"Dread","Amount":"1627498$8.44%1.63m","Ilvl":251,"Ilvl %":63,"Active":"92.38%","DPS":"4,527.6","":""},{"Parse %":21,"Name":"Loudda","Amount":"1440671$7.47%1.44m","Ilvl":254,"Ilvl %":10,"Active":"85.60%","DPS":"4,007.8","":""},{"Parse %":87,"Name":"Myanaa","Amount":"495449$2.57%495.4k","Ilvl":252,"Ilvl %":90,"Active":"49.74%","DPS":"1,378.3","":""},{"Parse %":25,"Name":"Noffs","Amount":"418989$2.17%419.0k","Ilvl":259,"Ilvl %":14,"Active":"59.02%","DPS":"1,165.6","":""},{"Parse %":9,"Name":"Syrane","Amount":"39592$0.21%39.6k","Ilvl":251,"Ilvl %":11,"Active":"0.00%","DPS":110.1,"":""}],
        "Skrolex": [{"Parse %":61,"Name":"Mamyhanzo","Amount":"2374830$16.81%2.37m","Ilvl":256,"Ilvl %":36,"Active":"98.77%","DPS":"7,708.6","":""},{"Parse %":64,"Name":"Blackbétty","Amount":"2314349$16.38%2.31m","Ilvl":253,"Ilvl %":65,"Active":"99.59%","DPS":"7,512.3","":""},{"Parse %":87,"Name":"Nightpumà","Amount":"2291190$16.22%2.29m","Ilvl":256,"Ilvl %":70,"Active":"99.23%","DPS":"7,437.1","":""},{"Parse %":64,"Name":"Mahbit","Amount":"2140512$15.15%2.14m","Ilvl":259,"Ilvl %":29,"Active":"99.71%","DPS":"6,948.0","":""},{"Parse %":33,"Name":"Dryhwa","Amount":"1996546$14.13%2.00m","Ilvl":256,"Ilvl %":10,"Active":"99.69%","DPS":"6,480.7","":""},{"Parse %":82,"Name":"Dread","Amount":"1124249$7.96%1.12m","Ilvl":251,"Ilvl %":82,"Active":"99.69%","DPS":"3,649.3","":""},{"Parse %":48,"Name":"Loudda","Amount":"1016889$7.20%1.02m","Ilvl":254,"Ilvl %":27,"Active":"99.96%","DPS":"3,300.8","":""},{"Parse %":59,"Name":"Noffs","Amount":"523405$3.71%523.4k","Ilvl":259,"Ilvl %":38,"Active":"94.63%","DPS":"1,699.0","":""},{"Parse %":80,"Name":"Myanaa","Amount":"316755$2.24%316.8k","Ilvl":252,"Ilvl %":81,"Active":"48.27%","DPS":"1,028.2","":""},{"Parse %":16,"Name":"Syrane","Amount":"27134$0.19%27.1k","Ilvl":251,"Ilvl %":18,"Active":"5.19%","DPS":88.1,"":""}],
        "Halondrus": [{"Parse %":38,"Name":"Dryhwa","Amount":"3044938$15.45%3.04m","Ilvl":256,"Ilvl %":19,"Active":"99.90%","DPS":"7,881.8","":""},{"Parse %":60,"Name":"Blackbétty","Amount":"2793409$14.17%2.79m","Ilvl":253,"Ilvl %":72,"Active":"99.80%","DPS":"7,230.8","":""},{"Parse %":49,"Name":"Mamyhanzo","Amount":"2703146$13.71%2.70m","Ilvl":257,"Ilvl %":19,"Active":"99.63%","DPS":"6,997.1","":""},{"Parse %":55,"Name":"Mahbit","Amount":"2580249$13.09%2.58m","Ilvl":259,"Ilvl %":28,"Active":"99.96%","DPS":"6,679.0","":""},{"Parse %":78,"Name":"Nightpumà","Amount":"2283175$11.58%2.28m","Ilvl":256,"Ilvl %":67,"Active":"99.32%","DPS":"5,910.0","":""},{"Parse %":55,"Name":"Likene","Amount":"2257362$11.45%2.26m","Ilvl":256,"Ilvl %":44,"Active":"91.02%","DPS":"5,843.2","":""},{"Parse %":92,"Name":"Redm","Amount":"1911997$9.70%1.91m","Ilvl":260,"Ilvl %":79,"Active":"99.86%","DPS":"4,949.2","":""},{"Parse %":57,"Name":"Loudda","Amount":"1257552$6.38%1.26m","Ilvl":254,"Ilvl %":48,"Active":"99.85%","DPS":"3,255.2","":""},{"Parse %":54,"Name":"Noffs","Amount":"572542$2.90%572.5k","Ilvl":259,"Ilvl %":43,"Active":"73.82%","DPS":"1,482.0","":""},{"Parse %":73,"Name":"Myanaa","Amount":"286901$1.46%286.9k","Ilvl":252,"Ilvl %":80,"Active":"39.68%","DPS":742.6,"":""},{"Parse %":10,"Name":"Syrane","Amount":"21468$0.11%21.5k","Ilvl":251,"Ilvl %":12,"Active":"0.00%","DPS":55.6,"":""}],
        "Lihuvim": [{"Parse %":71,"Name":"Likene","Amount":"2714812$14.65%2.71m","Ilvl":256,"Ilvl %":64,"Active":"93.35%","DPS":"6,800.9","":""},{"Parse %":52,"Name":"Mamyhanzo","Amount":"2597838$14.01%2.60m","Ilvl":257,"Ilvl %":26,"Active":"99.62%","DPS":"6,507.9","":""},{"Parse %":40,"Name":"Mahbit","Amount":"2581748$13.93%2.58m","Ilvl":259,"Ilvl %":14,"Active":"90.16%","DPS":"6,467.5","":""},{"Parse %":15,"Name":"Dryhwa","Amount":"2351607$12.69%2.35m","Ilvl":256,"Ilvl %":4,"Active":"99.68%","DPS":"5,891.0","":""},{"Parse %":44,"Name":"Nightpumà","Amount":"2101422$11.34%2.10m","Ilvl":256,"Ilvl %":24,"Active":"90.43%","DPS":"5,264.3","":""},{"Parse %":91,"Name":"Redm","Amount":"1990493$10.74%1.99m","Ilvl":260,"Ilvl %":74,"Active":"96.97%","DPS":"4,986.4","":""},{"Parse %":4,"Name":"Blackbétty","Amount":"1650300$8.90%1.65m","Ilvl":253,"Ilvl %":3,"Active":"96.37%","DPS":"4,134.2","":""},{"Parse %":65,"Name":"Loudda","Amount":"1428826$7.71%1.43m","Ilvl":254,"Ilvl %":57,"Active":"95.91%","DPS":"3,579.4","":""},{"Parse %":38,"Name":"Noffs","Amount":"599103$3.23%599.1k","Ilvl":259,"Ilvl %":27,"Active":"83.62%","DPS":"1,500.8","":""},{"Parse %":72,"Name":"Myanaa","Amount":"476132$2.57%476.1k","Ilvl":252,"Ilvl %":77,"Active":"70.39%","DPS":"1,192.8","":""},{"Parse %":9,"Name":"Syrane","Amount":"44726$0.24%44.7k","Ilvl":251,"Ilvl %":11,"Active":"4.34%","DPS":112,"":""}],
        "Anduin": [{"Parse %":68,"Name":"Mahbit","Amount":"9650242$11.60%9.65m","Ilvl":259,"Ilvl %":68,"Active":"99.74%","DPS":"16,685.0","":""},{"Parse %":83,"Name":"Likene","Amount":"9363774$11.25%9.36m","Ilvl":261,"Ilvl %":78,"Active":"94.66%","DPS":"16,189.7","":""},{"Parse %":37,"Name":"Blackbétty","Amount":"8667962$10.41%8.67m","Ilvl":253,"Ilvl %":49,"Active":"99.83%","DPS":"14,986.6","":""},{"Parse %":42,"Name":"Dryhwa","Amount":"7841522$9.42%7.84m","Ilvl":258,"Ilvl %":39,"Active":"97.75%","DPS":"13,557.8","":""},{"Parse %":46,"Name":"Merrigus","Amount":"7672191$9.22%7.67m","Ilvl":258,"Ilvl %":43,"Active":"94.90%","DPS":"13,265.0","":""},{"Parse %":29,"Name":"Nightpumà","Amount":"7600542$9.13%7.60m","Ilvl":255,"Ilvl %":34,"Active":"95.50%","DPS":"13,141.1","":""},{"Parse %":22,"Name":"Kërronz","Amount":"7318897$8.79%7.32m","Ilvl":250,"Ilvl %":46,"Active":"92.49%","DPS":"12,654.2","":""},{"Parse %":92,"Name":"Loudda","Amount":"6398838$7.69%6.40m","Ilvl":254,"Ilvl %":92,"Active":"92.91%","DPS":"11,063.4","":""},{"Parse %":18,"Name":"Mevanana","Amount":"6357799$7.64%6.36m","Ilvl":251,"Ilvl %":21,"Active":"73.58%","DPS":"10,992.4","":""},{"Parse %":99,"Name":"Redm","Amount":"6278247$7.54%6.28m","Ilvl":260,"Ilvl %":98,"Active":"99.95%","DPS":"10,854.9","":""},{"Parse %":4,"Name":"Araghast","Amount":"3681562$4.42%3.68m","Ilvl":234,"Ilvl %":"70*","Active":"84.81%","DPS":"6,365.3","":""},{"Parse %":25,"Name":"Noffs","Amount":"1434398$1.72%1.43m","Ilvl":259,"Ilvl %":24,"Active":"86.76%","DPS":"2,480.0","":""},{"Parse %":39,"Name":"Myanaa","Amount":"615060$0.74%615.1k","Ilvl":254,"Ilvl %":45,"Active":"32.89%","DPS":"1,063.4","":""},{"Parse %":22,"Name":"Syrane","Amount":"344835$0.41%344.8k","Ilvl":252,"Ilvl %":28,"Active":"0.60%","DPS":596.2,"":""}],
        "Dread": [],
        "Rygelon": [],
        "Geolier": []
    },
    {                        
        "Guardian": [],
        "Dausegne": [],
        "Wishmox": [],
        "Pantheon": [],
        "Skrolex": [],
        "Halondrus": [{"Parse %":83,"Name":"Blackbétty","Amount":"2720370$12.32%2.72m","Ilvl":259,"Ilvl %":83,"Active":"99.96%","DPS":"8,797.2","":""},{"Parse %":46,"Name":"Dryhwa","Amount":"2647255$11.99%2.65m","Ilvl":263,"Ilvl %":8,"Active":"99.76%","DPS":"8,560.7","":""},{"Parse %":57,"Name":"Nightpumà","Amount":"2208037$10.00%2.21m","Ilvl":258,"Ilvl %":54,"Active":"99.87%","DPS":"7,140.4","":""},{"Parse %":56,"Name":"Mahbit","Amount":"2186800$9.91%2.19m","Ilvl":262,"Ilvl %":26,"Active":"99.63%","DPS":"7,071.7","":""},{"Parse %":67,"Name":"Likene","Amount":"2090791$9.47%2.09m","Ilvl":264,"Ilvl %":22,"Active":"81.32%","DPS":"6,761.2","":""},{"Parse %":27,"Name":"Kërronz","Amount":"1989647$9.01%1.99m","Ilvl":252,"Ilvl %":29,"Active":"99.26%","DPS":"6,434.1","":""},{"Parse %":54,"Name":"Merrigus","Amount":"1903084$8.62%1.90m","Ilvl":264,"Ilvl %":13,"Active":"95.80%","DPS":"6,154.2","":""},{"Parse %":40,"Name":"Ñõvã","Amount":"1880480$8.52%1.88m","Ilvl":253,"Ilvl %":62,"Active":"99.92%","DPS":"6,081.1","":""},{"Parse %":90,"Name":"Redm","Amount":"1599835$7.25%1.60m","Ilvl":263,"Ilvl %":77,"Active":"99.63%","DPS":"5,173.6","":""},{"Parse %":18,"Name":"Mevanana","Amount":"1442901$6.54%1.44m","Ilvl":255,"Ilvl %":12,"Active":"92.22%","DPS":"4,666.1","":""},{"Parse %":84,"Name":"Loudda","Amount":"1282221$5.81%1.28m","Ilvl":261,"Ilvl %":68,"Active":"99.67%","DPS":"4,146.5","":""},{"Parse %":41,"Name":"Myanaa","Amount":"106565$0.48%106.6k","Ilvl":255,"Ilvl %":46,"Active":"26.07%","DPS":344.6,"":""},{"Parse %":10,"Name":"Syrane","Amount":"18172$0.08%18.2k","Ilvl":253,"Ilvl %":13,"Active":"0.00%","DPS":58.8,"":""}],
        "Lihuvim": [{"Parse %":82,"Name":"Likene","Amount":"2296004$11.72%2.30m","Ilvl":264,"Ilvl %":46,"Active":"94.70%","DPS":"8,687.0","":""},{"Parse %":60,"Name":"Dryhwa","Amount":"2199595$11.23%2.20m","Ilvl":263,"Ilvl %":17,"Active":"99.44%","DPS":"8,322.2","":""},{"Parse %":69,"Name":"Mahbit","Amount":"2083295$10.63%2.08m","Ilvl":262,"Ilvl %":45,"Active":"95.67%","DPS":"7,882.2","":""},{"Parse %":49,"Name":"Nightpumà","Amount":"1914044$9.77%1.91m","Ilvl":258,"Ilvl %":39,"Active":"99.18%","DPS":"7,241.9","":""},{"Parse %":44,"Name":"Blackbétty","Amount":"1769768$9.03%1.77m","Ilvl":259,"Ilvl %":25,"Active":"94.85%","DPS":"6,696.0","":""},{"Parse %":57,"Name":"Merrigus","Amount":"1751423$8.94%1.75m","Ilvl":264,"Ilvl %":11,"Active":"98.65%","DPS":"6,626.6","":""},{"Parse %":24,"Name":"Kërronz","Amount":"1634391$8.34%1.63m","Ilvl":252,"Ilvl %":24,"Active":"97.52%","DPS":"6,183.8","":""},{"Parse %":33,"Name":"Ñõvã","Amount":"1621169$8.27%1.62m","Ilvl":253,"Ilvl %":46,"Active":"99.56%","DPS":"6,133.8","":""},{"Parse %":91,"Name":"Redm","Amount":"1411060$7.20%1.41m","Ilvl":263,"Ilvl %":77,"Active":"95.81%","DPS":"5,338.8","":""},{"Parse %":17,"Name":"Mevanana","Amount":"1290586$6.59%1.29m","Ilvl":255,"Ilvl %":9,"Active":"95.12%","DPS":"4,883.0","":""},{"Parse %":83,"Name":"Loudda","Amount":"1127058$5.75%1.13m","Ilvl":261,"Ilvl %":67,"Active":"94.99%","DPS":"4,264.3","":""},{"Parse %":86,"Name":"Myanaa","Amount":"483595$2.47%483.6k","Ilvl":255,"Ilvl %":90,"Active":"83.67%","DPS":"1,829.7","":""},{"Parse %":4,"Name":"Syrane","Amount":"10771$0.05%10.8k","Ilvl":253,"Ilvl %":6,"Active":"0.00%","DPS":40.8,"":""}],
        "Anduin": [{"Parse %":76,"Name":"Blackbétty","Amount":"9643187$12.13%9.64m","Ilvl":259,"Ilvl %":80,"Active":"99.94%","DPS":"17,347.6","":""},{"Parse %":76,"Name":"Likene","Amount":"8962801$11.27%8.96m","Ilvl":264,"Ilvl %":59,"Active":"93.96%","DPS":"16,123.7","":""},{"Parse %":62,"Name":"Dryhwa","Amount":"8470157$10.65%8.47m","Ilvl":263,"Ilvl %":45,"Active":"99.87%","DPS":"15,237.4","":""},{"Parse %":46,"Name":"Nightpumà","Amount":"8202995$10.32%8.20m","Ilvl":258,"Ilvl %":57,"Active":"95.20%","DPS":"14,756.8","":""},{"Parse %":19,"Name":"Kërronz","Amount":"6860620$8.63%6.86m","Ilvl":252,"Ilvl %":27,"Active":"99.93%","DPS":"12,341.9","":""},{"Parse %":19,"Name":"Mahbit","Amount":"6814534$8.57%6.81m","Ilvl":261,"Ilvl %":13,"Active":"89.01%","DPS":"12,259.0","":""},{"Parse %":26,"Name":"Ñøvä","Amount":"6740805$8.48%6.74m","Ilvl":261,"Ilvl %":19,"Active":"95.42%","DPS":"12,126.4","":""},{"Parse %":24,"Name":"Merrigus","Amount":"6427745$8.08%6.43m","Ilvl":262,"Ilvl %":16,"Active":"94.39%","DPS":"11,563.2","":""},{"Parse %":95,"Name":"Redm","Amount":"5624371$7.07%5.62m","Ilvl":263,"Ilvl %":92,"Active":"99.75%","DPS":"10,118.0","":""},{"Parse %":74,"Name":"Loudda","Amount":"5461003$6.87%5.46m","Ilvl":261,"Ilvl %":71,"Active":"95.18%","DPS":"9,824.1","":""},{"Parse %":5,"Name":"Mevanana","Amount":"4054037$5.10%4.05m","Ilvl":255,"Ilvl %":5,"Active":"63.00%","DPS":"7,293.0","":""},{"Parse %":22,"Name":"Noffs","Amount":"1333348$1.68%1.33m","Ilvl":258,"Ilvl %":22,"Active":"99.74%","DPS":"2,398.6","":""},{"Parse %":36,"Name":"Syrane","Amount":"573905$0.72%573.9k","Ilvl":253,"Ilvl %":47,"Active":"0.70%","DPS":"1,032.4","":""},{"Parse %":21,"Name":"Myanaa","Amount":"339592$0.43%339.6k","Ilvl":255,"Ilvl %":27,"Active":"25.28%","DPS":610.9,"":""}],
        "Dread": [{"Parse %":47,"Name":"Mahbit","Amount":"6642483$14.45%6.64m","Ilvl":261,"Ilvl %":40,"Active":"99.91%","DPS":"9,130.1","":""},{"Parse %":37,"Name":"Likene","Amount":"6259364$13.62%6.26m","Ilvl":264,"Ilvl %":16,"Active":"68.80%","DPS":"8,603.5","":""},{"Parse %":86,"Name":"Redm","Amount":"5368924$11.68%5.37m","Ilvl":263,"Ilvl %":79,"Active":"99.98%","DPS":"7,379.6","":""},{"Parse %":10,"Name":"Nightpumà","Amount":"5094086$11.08%5.09m","Ilvl":258,"Ilvl %":9,"Active":"99.64%","DPS":"7,001.8","":""},{"Parse %":13,"Name":"Ñøvä","Amount":"4774621$10.39%4.77m","Ilvl":261,"Ilvl %":7,"Active":"85.21%","DPS":"6,562.7","":""},{"Parse %":12,"Name":"Dryhwa","Amount":"4268373$9.29%4.27m","Ilvl":263,"Ilvl %":4,"Active":"96.01%","DPS":"5,866.9","":""},{"Parse %":56,"Name":"Loudda","Amount":"4225956$9.19%4.23m","Ilvl":261,"Ilvl %":43,"Active":"94.00%","DPS":"5,808.6","":""},{"Parse %":4,"Name":"Mevanana","Amount":"2746945$5.98%2.75m","Ilvl":255,"Ilvl %":4,"Active":"48.51%","DPS":"3,775.7","":""},{"Parse %":3,"Name":"Blackbétty","Amount":"2732401$5.94%2.73m","Ilvl":257,"Ilvl %":2,"Active":"55.07%","DPS":"3,755.7","":""},{"Parse %":3,"Name":"Merrigus","Amount":"2299769$5.00%2.30m","Ilvl":264,"Ilvl %":1,"Active":"37.96%","DPS":"3,161.0","":""},{"Parse %":32,"Name":"Noffs","Amount":"1257653$2.74%1.26m","Ilvl":258,"Ilvl %":33,"Active":"93.36%","DPS":"1,728.6","":""},{"Parse %":29,"Name":"Syrane","Amount":"151018$0.33%151.0k","Ilvl":251,"Ilvl %":39,"Active":"0.00%","DPS":207.6,"":""},{"Parse %":28,"Name":"Myanaa","Amount":"144880$0.32%144.9k","Ilvl":255,"Ilvl %":35,"Active":"4.26%","DPS":199.1,"":""}],
        "Rygelon": [{"Parse %":79,"Name":"Ñøvä","Amount":"3109345$12.32%3.11m","Ilvl":264,"Ilvl %":62,"Active":"99.87%","DPS":"9,163.0","":""},{"Parse %":63,"Name":"Merrigus","Amount":"3038970$12.04%3.04m","Ilvl":266,"Ilvl %":25,"Active":"99.78%","DPS":"8,955.6","":""},{"Parse %":47,"Name":"Dryhwa","Amount":"2785010$11.04%2.79m","Ilvl":265,"Ilvl %":28,"Active":"99.76%","DPS":"8,207.2","":""},{"Parse %":50,"Name":"Likene","Amount":"2707276$10.73%2.71m","Ilvl":264,"Ilvl %":31,"Active":"99.78%","DPS":"7,978.2","":""},{"Parse %":56,"Name":"Blackbétty","Amount":"2634890$10.44%2.63m","Ilvl":259,"Ilvl %":63,"Active":"99.32%","DPS":"7,764.8","":""},{"Parse %":37,"Name":"Mahbit","Amount":"2323862$9.21%2.32m","Ilvl":256,"Ilvl %":54,"Active":"99.86%","DPS":"6,848.3","":""},{"Parse %":87,"Name":"Redm","Amount":"1951548$7.73%1.95m","Ilvl":263,"Ilvl %":83,"Active":"99.78%","DPS":"5,751.1","":""},{"Parse %":22,"Name":"Mevanana","Amount":"1921123$7.61%1.92m","Ilvl":255,"Ilvl %":32,"Active":"92.47%","DPS":"5,661.4","":""},{"Parse %":80,"Name":"Loudda","Amount":"1548597$6.14%1.55m","Ilvl":261,"Ilvl %":80,"Active":"95.56%","DPS":"4,563.6","":""},{"Parse %":8,"Name":"Araghast","Amount":"1484704$5.88%1.48m","Ilvl":239,"Ilvl %":51,"Active":"95.82%","DPS":"4,375.3","":""},{"Parse %":87,"Name":"Noffs","Amount":"994178$3.94%994.2k","Ilvl":261,"Ilvl %":90,"Active":"99.50%","DPS":"2,929.8","":""},{"Parse %":1,"Name":"Kërronz","Amount":"457193$1.81%457.2k","Ilvl":252,"Ilvl %":3,"Active":"18.21%","DPS":"1,347.3","":""},{"Parse %":41,"Name":"Myanaa","Amount":"200975$0.80%201.0k","Ilvl":255,"Ilvl %":51,"Active":"41.55%","DPS":592.3,"":""},{"Parse %":17,"Name":"Syrane","Amount":"78295$0.31%78.3k","Ilvl":251,"Ilvl %":25,"Active":"1.30%","DPS":230.7,"":""}],
        "Geolier": []
    },
    {                        
        "Guardian": [{"Parse %":96,"Name":"Blackbétty","Amount":"3022928$14.13%3.02m","Ilvl":261,"Ilvl %":93,"Active":"96.25%","DPS":"8,474.7","":""},{"Parse %":78,"Name":"Mahbit","Amount":"2673810$12.50%2.67m","Ilvl":263,"Ilvl %":47,"Active":"93.52%","DPS":"7,496.0","":""},{"Parse %":73,"Name":"Ñøvä","Amount":"2646312$12.37%2.65m","Ilvl":264,"Ilvl %":37,"Active":"96.15%","DPS":"7,418.9","":""},{"Parse %":64,"Name":"Nightpumà","Amount":"2375171$11.10%2.38m","Ilvl":260,"Ilvl %":39,"Active":"96.08%","DPS":"6,658.8","":""},{"Parse %":62,"Name":"Dryhwa","Amount":"2321555$10.85%2.32m","Ilvl":265,"Ilvl %":29,"Active":"91.95%","DPS":"6,508.4","":""},{"Parse %":49,"Name":"Merrigus","Amount":"2019610$9.44%2.02m","Ilvl":267,"Ilvl %":8,"Active":"76.55%","DPS":"5,661.9","":""},{"Parse %":11,"Name":"Kërronz","Amount":"1653198$7.73%1.65m","Ilvl":253,"Ilvl %":8,"Active":"95.89%","DPS":"4,634.7","":""},{"Parse %":15,"Name":"Mevanana","Amount":"1593919$7.45%1.59m","Ilvl":255,"Ilvl %":8,"Active":"87.68%","DPS":"4,468.5","":""},{"Parse %":73,"Name":"Redm","Amount":"1429517$6.68%1.43m","Ilvl":263,"Ilvl %":50,"Active":"88.68%","DPS":"4,007.6","":""},{"Parse %":46,"Name":"Loudda","Amount":"1292593$6.04%1.29m","Ilvl":262,"Ilvl %":24,"Active":"92.59%","DPS":"3,623.8","":""},{"Parse %":78,"Name":"Myanaa","Amount":"341515$1.60%341.5k","Ilvl":255,"Ilvl %":81,"Active":"40.65%","DPS":957.4,"":""},{"Parse %":9,"Name":"Syrane","Amount":"24833$0.12%24.8k","Ilvl":253,"Ilvl %":12,"Active":"0.00%","DPS":69.6,"":""}],
        "Dausegne": [{"Parse %":91,"Name":"Blackbétty","Amount":"2127334$11.17%2.13m","Ilvl":261,"Ilvl %":83,"Active":"99.26%","DPS":"10,534.1","":""},{"Parse %":85,"Name":"Likene","Amount":"2124237$11.16%2.12m","Ilvl":265,"Ilvl %":58,"Active":"99.81%","DPS":"10,518.8","":""},{"Parse %":87,"Name":"Dryhwa","Amount":"2053227$10.78%2.05m","Ilvl":265,"Ilvl %":60,"Active":"99.80%","DPS":"10,167.2","":""},{"Parse %":75,"Name":"Ñøvä","Amount":"1807768$9.50%1.81m","Ilvl":264,"Ilvl %":32,"Active":"99.93%","DPS":"8,951.7","":""},{"Parse %":79,"Name":"Mahbit","Amount":"1759433$9.24%1.76m","Ilvl":263,"Ilvl %":43,"Active":"99.85%","DPS":"8,712.4","":""},{"Parse %":67,"Name":"Merrigus","Amount":"1752144$9.20%1.75m","Ilvl":267,"Ilvl %":11,"Active":"99.31%","DPS":"8,676.3","":""},{"Parse %":56,"Name":"Nightpumà","Amount":"1750819$9.20%1.75m","Ilvl":260,"Ilvl %":26,"Active":"98.55%","DPS":"8,669.7","":""},{"Parse %":26,"Name":"Kërronz","Amount":"1285576$6.75%1.29m","Ilvl":254,"Ilvl %":21,"Active":"99.58%","DPS":"6,365.9","":""},{"Parse %":40,"Name":"Mevanana","Amount":"1252717$6.58%1.25m","Ilvl":255,"Ilvl %":33,"Active":"97.70%","DPS":"6,203.2","":""},{"Parse %":95,"Name":"Redm","Amount":"1232731$6.47%1.23m","Ilvl":263,"Ilvl %":88,"Active":"99.80%","DPS":"6,104.2","":""},{"Parse %":84,"Name":"Loudda","Amount":"934966$4.91%935.0k","Ilvl":262,"Ilvl %":73,"Active":"99.44%","DPS":"4,629.8","":""},{"Parse %":95,"Name":"Noffs","Amount":"644776$3.39%644.8k","Ilvl":263,"Ilvl %":85,"Active":"99.34%","DPS":"3,192.8","":""},{"Parse %":75,"Name":"Myanaa","Amount":"294416$1.55%294.4k","Ilvl":255,"Ilvl %":78,"Active":"67.16%","DPS":"1,457.9","":""},{"Parse %":8,"Name":"Syrane","Amount":"18203$0.10%18.2k","Ilvl":253,"Ilvl %":10,"Active":"0.00%","DPS":90.1,"":""}],
        "Wishmox": [{"Parse %":91,"Name":"Mahbit","Amount":"2813012$12.42%2.81m","Ilvl":263,"Ilvl %":77,"Active":"99.63%","DPS":"9,601.6","":""},{"Parse %":80,"Name":"Likene","Amount":"2522328$11.14%2.52m","Ilvl":265,"Ilvl %":48,"Active":"97.84%","DPS":"8,609.4","":""},{"Parse %":80,"Name":"Ñøvä","Amount":"2441569$10.78%2.44m","Ilvl":264,"Ilvl %":45,"Active":"99.69%","DPS":"8,333.7","":""},{"Parse %":65,"Name":"Dryhwa","Amount":"2409701$10.64%2.41m","Ilvl":265,"Ilvl %":28,"Active":"99.46%","DPS":"8,225.0","":""},{"Parse %":64,"Name":"Blackbétty","Amount":"2282236$10.08%2.28m","Ilvl":261,"Ilvl %":35,"Active":"69.90%","DPS":"7,789.9","":""},{"Parse %":45,"Name":"Nightpumà","Amount":"1988776$8.78%1.99m","Ilvl":260,"Ilvl %":17,"Active":"96.98%","DPS":"6,788.2","":""},{"Parse %":30,"Name":"Mevanana","Amount":"1575890$6.96%1.58m","Ilvl":255,"Ilvl %":23,"Active":"95.83%","DPS":"5,378.9","":""},{"Parse %":95,"Name":"Redm","Amount":"1543297$6.81%1.54m","Ilvl":263,"Ilvl %":86,"Active":"99.44%","DPS":"5,267.7","":""},{"Parse %":23,"Name":"Merrigus","Amount":"1423798$6.29%1.42m","Ilvl":267,"Ilvl %":3,"Active":"65.93%","DPS":"4,859.8","":""},{"Parse %":14,"Name":"Kërronz","Amount":"1337074$5.90%1.34m","Ilvl":254,"Ilvl %":12,"Active":"86.62%","DPS":"4,563.8","":""},{"Parse %":82,"Name":"Loudda","Amount":"1267139$5.60%1.27m","Ilvl":262,"Ilvl %":71,"Active":"99.17%","DPS":"4,325.1","":""},{"Parse %":93,"Name":"Noffs","Amount":"777190$3.43%777.2k","Ilvl":264,"Ilvl %":83,"Active":"98.46%","DPS":"2,652.8","":""},{"Parse %":62,"Name":"Myanaa","Amount":"228579$1.01%228.6k","Ilvl":255,"Ilvl %":67,"Active":"57.23%","DPS":780.2,"":""},{"Parse %":13,"Name":"Syrane","Amount":"36336$0.16%36.3k","Ilvl":254,"Ilvl %":16,"Active":"5.89%","DPS":124,"":""}],
        "Pantheon": [{"Parse %":82,"Name":"Merrigus","Amount":"2579922$11.44%2.58m","Ilvl":267,"Ilvl %":33,"Active":"83.54%","DPS":"10,944.7","":""},{"Parse %":85,"Name":"Blackbétty","Amount":"2398701$10.63%2.40m","Ilvl":261,"Ilvl %":74,"Active":"86.52%","DPS":"10,175.9","":""},{"Parse %":73,"Name":"Ñøvä","Amount":"2338361$10.37%2.34m","Ilvl":264,"Ilvl %":39,"Active":"87.89%","DPS":"9,920.0","":""},{"Parse %":71,"Name":"Likene","Amount":"2305109$10.22%2.31m","Ilvl":265,"Ilvl %":32,"Active":"86.80%","DPS":"9,778.9","":""},{"Parse %":49,"Name":"Mahbit","Amount":"2043291$9.06%2.04m","Ilvl":263,"Ilvl %":14,"Active":"86.72%","DPS":"8,668.2","":""},{"Parse %":40,"Name":"Nightpumà","Amount":"1869943$8.29%1.87m","Ilvl":260,"Ilvl %":12,"Active":"84.27%","DPS":"7,932.8","":""},{"Parse %":56,"Name":"Dryhwa","Amount":"1803471$8.00%1.80m","Ilvl":265,"Ilvl %":16,"Active":"84.52%","DPS":"7,650.8","":""},{"Parse %":19,"Name":"Kërronz","Amount":"1670201$7.40%1.67m","Ilvl":254,"Ilvl %":14,"Active":"83.29%","DPS":"7,085.4","":""},{"Parse %":24,"Name":"Mevanana","Amount":"1632056$7.24%1.63m","Ilvl":255,"Ilvl %":17,"Active":"80.12%","DPS":"6,923.6","":""},{"Parse %":91,"Name":"Redm","Amount":"1572093$6.97%1.57m","Ilvl":263,"Ilvl %":77,"Active":"84.59%","DPS":"6,669.2","":""},{"Parse %":76,"Name":"Loudda","Amount":"1384137$6.14%1.38m","Ilvl":262,"Ilvl %":59,"Active":"86.11%","DPS":"5,871.9","":""},{"Parse %":88,"Name":"Noffs","Amount":"659720$2.92%659.7k","Ilvl":265,"Ilvl %":75,"Active":"77.15%","DPS":"2,798.7","":""},{"Parse %":73,"Name":"Myanaa","Amount":"259976$1.15%260.0k","Ilvl":255,"Ilvl %":78,"Active":"38.63%","DPS":"1,102.9","":""},{"Parse %":12,"Name":"Syrane","Amount":"38666$0.17%38.7k","Ilvl":253,"Ilvl %":16,"Active":"8.23%","DPS":164,"":""}],
        "Skrolex": [{"Parse %":94,"Name":"Blackbétty","Amount":"2252640$11.25%2.25m","Ilvl":261,"Ilvl %":91,"Active":"99.45%","DPS":"10,362.7","":""},{"Parse %":88,"Name":"Likene","Amount":"2220648$11.09%2.22m","Ilvl":265,"Ilvl %":62,"Active":"99.97%","DPS":"10,215.5","":""},{"Parse %":90,"Name":"Dryhwa","Amount":"2209442$11.04%2.21m","Ilvl":265,"Ilvl %":67,"Active":"99.43%","DPS":"10,164.0","":""},{"Parse %":87,"Name":"Merrigus","Amount":"2200493$10.99%2.20m","Ilvl":267,"Ilvl %":36,"Active":"99.18%","DPS":"10,122.8","":""},{"Parse %":89,"Name":"Ñøvä","Amount":"2104054$10.51%2.10m","Ilvl":264,"Ilvl %":58,"Active":"99.65%","DPS":"9,679.2","":""},{"Parse %":63,"Name":"Nightpumà","Amount":"1853357$9.26%1.85m","Ilvl":260,"Ilvl %":33,"Active":"99.14%","DPS":"8,525.9","":""},{"Parse %":77,"Name":"Mahbit","Amount":"1751705$8.75%1.75m","Ilvl":263,"Ilvl %":39,"Active":"99.58%","DPS":"8,058.3","":""},{"Parse %":47,"Name":"Kërronz","Amount":"1465441$7.32%1.47m","Ilvl":254,"Ilvl %":43,"Active":"99.56%","DPS":"6,741.4","":""},{"Parse %":47,"Name":"Mevanana","Amount":"1277916$6.38%1.28m","Ilvl":255,"Ilvl %":39,"Active":"99.40%","DPS":"5,878.7","":""},{"Parse %":77,"Name":"Redm","Amount":"1042228$5.21%1.04m","Ilvl":263,"Ilvl %":52,"Active":"99.79%","DPS":"4,794.5","":""},{"Parse %":79,"Name":"Loudda","Amount":"891684$4.46%891.7k","Ilvl":262,"Ilvl %":60,"Active":"93.68%","DPS":"4,102.0","":""},{"Parse %":95,"Name":"Noffs","Amount":"653618$3.27%653.6k","Ilvl":264,"Ilvl %":88,"Active":"98.60%","DPS":"3,006.8","":""},{"Parse %":40,"Name":"Myanaa","Amount":"73085$0.37%73.1k","Ilvl":255,"Ilvl %":43,"Active":"17.54%","DPS":336.2,"":""},{"Parse %":14,"Name":"Syrane","Amount":"18627$0.09%18.6k","Ilvl":254,"Ilvl %":18,"Active":"0.00%","DPS":85.7,"":""}],
        "Halondrus": [{"Parse %":79,"Name":"Dryhwa","Amount":"3215895$13.47%3.22m","Ilvl":265,"Ilvl %":60,"Active":"99.79%","DPS":"11,111.0","":""},{"Parse %":93,"Name":"Blackbétty","Amount":"3037240$12.72%3.04m","Ilvl":261,"Ilvl %":96,"Active":"99.77%","DPS":"10,493.7","":""},{"Parse %":74,"Name":"Likene","Amount":"2260427$9.47%2.26m","Ilvl":265,"Ilvl %":54,"Active":"99.73%","DPS":"7,809.8","":""},{"Parse %":63,"Name":"Mahbit","Amount":"2245714$9.41%2.25m","Ilvl":263,"Ilvl %":32,"Active":"99.75%","DPS":"7,759.0","":""},{"Parse %":48,"Name":"Ñøvä","Amount":"2192833$9.19%2.19m","Ilvl":264,"Ilvl %":14,"Active":"86.51%","DPS":"7,576.3","":""},{"Parse %":59,"Name":"Nightpumà","Amount":"2187960$9.17%2.19m","Ilvl":260,"Ilvl %":47,"Active":"98.74%","DPS":"7,559.4","":""},{"Parse %":63,"Name":"Merrigus","Amount":"2056758$8.62%2.06m","Ilvl":267,"Ilvl %":19,"Active":"86.25%","DPS":"7,106.1","":""},{"Parse %":26,"Name":"Kërronz","Amount":"1902071$7.97%1.90m","Ilvl":254,"Ilvl %":25,"Active":"99.78%","DPS":"6,571.7","":""},{"Parse %":86,"Name":"Redm","Amount":"1495890$6.27%1.50m","Ilvl":263,"Ilvl %":78,"Active":"99.58%","DPS":"5,168.3","":""},{"Parse %":76,"Name":"Loudda","Amount":"1164274$4.88%1.16m","Ilvl":262,"Ilvl %":67,"Active":"99.61%","DPS":"4,022.6","":""},{"Parse %":10,"Name":"Mevanana","Amount":"1163806$4.88%1.16m","Ilvl":255,"Ilvl %":8,"Active":"87.17%","DPS":"4,021.0","":""},{"Parse %":81,"Name":"Noffs","Amount":"608276$2.55%608.3k","Ilvl":262,"Ilvl %":80,"Active":"99.62%","DPS":"2,101.6","":""},{"Parse %":81,"Name":"Myanaa","Amount":"319288$1.34%319.3k","Ilvl":255,"Ilvl %":89,"Active":"66.20%","DPS":"1,103.1","":""},{"Parse %":9,"Name":"Syrane","Amount":"18216$0.08%18.2k","Ilvl":254,"Ilvl %":14,"Active":"5.53%","DPS":62.9,"":""}],
        "Lihuvim": [{"Parse %":65,"Name":"Dryhwa","Amount":"2308515$10.87%2.31m","Ilvl":265,"Ilvl %":34,"Active":"99.97%","DPS":"9,041.0","":""},{"Parse %":80,"Name":"Blackbétty","Amount":"2295339$10.81%2.30m","Ilvl":261,"Ilvl %":76,"Active":"99.93%","DPS":"8,989.4","":""},{"Parse %":72,"Name":"Likene","Amount":"2197343$10.35%2.20m","Ilvl":265,"Ilvl %":48,"Active":"94.70%","DPS":"8,605.6","":""},{"Parse %":69,"Name":"Mahbit","Amount":"2153019$10.14%2.15m","Ilvl":263,"Ilvl %":42,"Active":"95.38%","DPS":"8,432.0","":""},{"Parse %":68,"Name":"Ñøvä","Amount":"2023174$9.53%2.02m","Ilvl":264,"Ilvl %":34,"Active":"91.62%","DPS":"7,923.5","":""},{"Parse %":49,"Name":"Nightpumà","Amount":"1982646$9.33%1.98m","Ilvl":260,"Ilvl %":31,"Active":"98.87%","DPS":"7,764.8","":""},{"Parse %":60,"Name":"Merrigus","Amount":"1922628$9.05%1.92m","Ilvl":267,"Ilvl %":12,"Active":"93.99%","DPS":"7,529.7","":""},{"Parse %":13,"Name":"Kërronz","Amount":"1436938$6.77%1.44m","Ilvl":254,"Ilvl %":9,"Active":"99.28%","DPS":"5,627.6","":""},{"Parse %":21,"Name":"Mevanana","Amount":"1346714$6.34%1.35m","Ilvl":255,"Ilvl %":17,"Active":"90.66%","DPS":"5,274.2","":""},{"Parse %":80,"Name":"Redm","Amount":"1238895$5.83%1.24m","Ilvl":263,"Ilvl %":64,"Active":"99.86%","DPS":"4,852.0","":""},{"Parse %":81,"Name":"Loudda","Amount":"1122295$5.28%1.12m","Ilvl":262,"Ilvl %":75,"Active":"94.97%","DPS":"4,395.3","":""},{"Parse %":89,"Name":"Noffs","Amount":"735539$3.46%735.5k","Ilvl":265,"Ilvl %":80,"Active":"85.19%","DPS":"2,880.6","":""},{"Parse %":83,"Name":"Myanaa","Amount":"465872$2.19%465.9k","Ilvl":255,"Ilvl %":90,"Active":"94.49%","DPS":"1,824.5","":""},{"Parse %":4,"Name":"Syrane","Amount":"11000$0.05%11.0k","Ilvl":253,"Ilvl %":6,"Active":"0.00%","DPS":43.1,"":""}],
        "Anduin": [{"Parse %":52,"Name":"Blackbétty","Amount":"8080273$10.63%8.08m","Ilvl":261,"Ilvl %":51,"Active":"92.43%","DPS":"15,787.6","":""},{"Parse %":46,"Name":"Mahbit","Amount":"7767909$10.22%7.77m","Ilvl":263,"Ilvl %":35,"Active":"99.86%","DPS":"15,177.3","":""},{"Parse %":57,"Name":"Merrigus","Amount":"7728032$10.17%7.73m","Ilvl":267,"Ilvl %":25,"Active":"99.09%","DPS":"15,099.4","":""},{"Parse %":54,"Name":"Likene","Amount":"7599018$10.00%7.60m","Ilvl":265,"Ilvl %":41,"Active":"99.78%","DPS":"14,847.3","":""},{"Parse %":61,"Name":"Ñøvä","Amount":"7565075$9.95%7.57m","Ilvl":264,"Ilvl %":48,"Active":"99.84%","DPS":"14,781.0","":""},{"Parse %":39,"Name":"Dryhwa","Amount":"7077749$9.31%7.08m","Ilvl":265,"Ilvl %":24,"Active":"99.77%","DPS":"13,828.9","":""},{"Parse %":29,"Name":"Nightpumà","Amount":"6869093$9.04%6.87m","Ilvl":260,"Ilvl %":23,"Active":"99.59%","DPS":"13,421.2","":""},{"Parse %":9,"Name":"Kërronz","Amount":"5377783$7.07%5.38m","Ilvl":254,"Ilvl %":12,"Active":"99.74%","DPS":"10,507.4","":""},{"Parse %":70,"Name":"Loudda","Amount":"4966206$6.53%4.97m","Ilvl":262,"Ilvl %":70,"Active":"99.83%","DPS":"9,703.2","":""},{"Parse %":86,"Name":"Redm","Amount":"4821661$6.34%4.82m","Ilvl":263,"Ilvl %":84,"Active":"99.69%","DPS":"9,420.8","":""},{"Parse %":7,"Name":"Mevanana","Amount":"3933874$5.17%3.93m","Ilvl":255,"Ilvl %":7,"Active":"99.65%","DPS":"7,686.2","":""},{"Parse %":71,"Name":"Noffs","Amount":"2086404$2.74%2.09m","Ilvl":262,"Ilvl %":74,"Active":"99.66%","DPS":"4,076.5","":""},{"Parse %":90,"Name":"Myanaa","Amount":"1855499$2.44%1.86m","Ilvl":255,"Ilvl %":95,"Active":"51.11%","DPS":"3,625.4","":""},{"Parse %":19,"Name":"Syrane","Amount":"290912$0.38%290.9k","Ilvl":254,"Ilvl %":25,"Active":"0.00%","DPS":568.4,"":""}],
        "Dread": [{"Parse %":74,"Name":"Likene","Amount":"6602211$13.89%6.60m","Ilvl":265,"Ilvl %":77,"Active":"95.01%","DPS":"13,426.6","":""},{"Parse %":60,"Name":"Merrigus","Amount":"5743199$12.08%5.74m","Ilvl":266,"Ilvl %":27,"Active":"94.37%","DPS":"11,679.7","":""},{"Parse %":48,"Name":"Mahbit","Amount":"5095503$10.72%5.10m","Ilvl":263,"Ilvl %":34,"Active":"97.43%","DPS":"10,362.5","":""},{"Parse %":51,"Name":"Ñøvä","Amount":"4619275$9.72%4.62m","Ilvl":264,"Ilvl %":36,"Active":"93.98%","DPS":"9,394.0","":""},{"Parse %":47,"Name":"Blackbétty","Amount":"4393276$9.24%4.39m","Ilvl":261,"Ilvl %":42,"Active":"80.61%","DPS":"8,934.4","":""},{"Parse %":56,"Name":"Dryhwa","Amount":"4366594$9.19%4.37m","Ilvl":265,"Ilvl %":41,"Active":"99.61%","DPS":"8,880.1","":""},{"Parse %":17,"Name":"Nightpumà","Amount":"3885195$8.17%3.89m","Ilvl":260,"Ilvl %":9,"Active":"87.65%","DPS":"7,901.1","":""},{"Parse %":78,"Name":"Redm","Amount":"3602472$7.58%3.60m","Ilvl":263,"Ilvl %":74,"Active":"99.68%","DPS":"7,326.2","":""},{"Parse %":79,"Name":"Loudda","Amount":"3484060$7.33%3.48m","Ilvl":262,"Ilvl %":82,"Active":"93.55%","DPS":"7,085.4","":""},{"Parse %":4,"Name":"Kërronz","Amount":"2344834$4.93%2.34m","Ilvl":254,"Ilvl %":5,"Active":"67.68%","DPS":"4,768.6","":""},{"Parse %":4,"Name":"Mevanana","Amount":"1625107$3.42%1.63m","Ilvl":255,"Ilvl %":4,"Active":"47.81%","DPS":"3,304.9","":""},{"Parse %":87,"Name":"Noffs","Amount":"1540098$3.24%1.54m","Ilvl":262,"Ilvl %":92,"Active":"95.92%","DPS":"3,132.0","":""},{"Parse %":34,"Name":"Myanaa","Amount":"130428$0.27%130.4k","Ilvl":255,"Ilvl %":44,"Active":"5.56%","DPS":265.2,"":""},{"Parse %":25,"Name":"Syrane","Amount":"93514$0.20%93.5k","Ilvl":254,"Ilvl %":34,"Active":"6.49%","DPS":190.2,"":""}],
        "Rygelon": [{"Parse %":46,"Name":"Merrigus","Amount":"3073026$11.99%3.07m","Ilvl":265,"Ilvl %":30,"Active":"96.29%","DPS":"7,931.4","":""},{"Parse %":48,"Name":"Ñøvä","Amount":"3060971$11.94%3.06m","Ilvl":264,"Ilvl %":29,"Active":"99.75%","DPS":"7,900.3","":""},{"Parse %":42,"Name":"Mahbit","Amount":"2793488$10.90%2.79m","Ilvl":263,"Ilvl %":24,"Active":"99.53%","DPS":"7,209.9","":""},{"Parse %":24,"Name":"Blackbétty","Amount":"2549230$9.95%2.55m","Ilvl":261,"Ilvl %":19,"Active":"81.32%","DPS":"6,579.5","":""},{"Parse %":92,"Name":"Redm","Amount":"2446358$9.54%2.45m","Ilvl":263,"Ilvl %":93,"Active":"99.97%","DPS":"6,314.0","":""},{"Parse %":12,"Name":"Nightpumà","Amount":"2374380$9.26%2.37m","Ilvl":260,"Ilvl %":9,"Active":"99.36%","DPS":"6,128.2","":""},{"Parse %":10,"Name":"Kërronz","Amount":"2205991$8.61%2.21m","Ilvl":254,"Ilvl %":15,"Active":"99.22%","DPS":"5,693.6","":""},{"Parse %":9,"Name":"Mevanana","Amount":"1711044$6.68%1.71m","Ilvl":255,"Ilvl %":16,"Active":"98.38%","DPS":"4,416.1","":""},{"Parse %":64,"Name":"Loudda","Amount":"1635104$6.38%1.64m","Ilvl":262,"Ilvl %":63,"Active":"99.20%","DPS":"4,220.1","":""},{"Parse %":5,"Name":"Likene","Amount":"1356292$5.29%1.36m","Ilvl":265,"Ilvl %":3,"Active":"29.63%","DPS":"3,500.5","":""},{"Parse %":86,"Name":"Noffs","Amount":"1151768$4.49%1.15m","Ilvl":262,"Ilvl %":90,"Active":"99.43%","DPS":"2,972.7","":""},{"Parse %":81,"Name":"Myanaa","Amount":"610261$2.38%610.3k","Ilvl":255,"Ilvl %":91,"Active":"90.09%","DPS":"1,575.1","":""},{"Parse %":2,"Name":"Dryhwa","Amount":"581250$2.27%581.3k","Ilvl":265,"Ilvl %":0,"Active":"16.65%","DPS":"1,500.2","":""},{"Parse %":15,"Name":"Syrane","Amount":"81492$0.32%81.5k","Ilvl":254,"Ilvl %":21,"Active":"0.67%","DPS":210.3,"":""}],
        "Geolier": [{"Parse %":80,"Name":"Blackbétty","Amount":"5133204$12.07%5.13m","Ilvl":261,"Ilvl %":84,"Active":"99.65%","DPS":"9,684.3","":""},{"Parse %":42,"Name":"Dryhwa","Amount":"4714717$11.08%4.71m","Ilvl":265,"Ilvl %":23,"Active":"97.63%","DPS":"8,894.8","":""},{"Parse %":64,"Name":"Likene","Amount":"4591630$10.80%4.59m","Ilvl":266,"Ilvl %":37,"Active":"96.81%","DPS":"8,662.5","":""},{"Parse %":60,"Name":"Mahbit","Amount":"4474209$10.52%4.47m","Ilvl":263,"Ilvl %":45,"Active":"99.72%","DPS":"8,441.0","":""},{"Parse %":52,"Name":"Merrigus","Amount":"4255557$10.01%4.26m","Ilvl":266,"Ilvl %":26,"Active":"97.37%","DPS":"8,028.5","":""},{"Parse %":37,"Name":"Ñøvä","Amount":"4209136$9.90%4.21m","Ilvl":264,"Ilvl %":23,"Active":"97.61%","DPS":"7,940.9","":""},{"Parse %":28,"Name":"Nightpumà","Amount":"3922160$9.22%3.92m","Ilvl":260,"Ilvl %":23,"Active":"88.54%","DPS":"7,399.5","":""},{"Parse %":80,"Name":"Redm","Amount":"2640634$6.21%2.64m","Ilvl":263,"Ilvl %":74,"Active":"97.34%","DPS":"4,981.8","":""},{"Parse %":15,"Name":"Mevanana","Amount":"2519990$5.92%2.52m","Ilvl":255,"Ilvl %":18,"Active":"86.92%","DPS":"4,754.2","":""},{"Parse %":69,"Name":"Loudda","Amount":"2178402$5.12%2.18m","Ilvl":262,"Ilvl %":70,"Active":"97.68%","DPS":"4,109.8","":""},{"Parse %":6,"Name":"Kërronz","Amount":"1944994$4.57%1.94m","Ilvl":254,"Ilvl %":6,"Active":"65.51%","DPS":"3,669.4","":""},{"Parse %":96,"Name":"Noffs","Amount":"1599199$3.76%1.60m","Ilvl":262,"Ilvl %":97,"Active":"99.71%","DPS":"3,017.0","":""},{"Parse %":46,"Name":"Myanaa","Amount":"323796$0.76%323.8k","Ilvl":255,"Ilvl %":56,"Active":"44.00%","DPS":610.9,"":""},{"Parse %":5,"Name":"Syrane","Amount":"25603$0.06%25.6k","Ilvl":254,"Ilvl %":8,"Active":"4.65%","DPS":48.3,"":""}]
    },
    {                        
        "Guardian": [],
        "Dausegne": [],
        "Wishmox": [],
        "Pantheon": [],
        "Skrolex": [],
        "Halondrus": [],
        "Lihuvim": [],
        "Anduin": [],
        "Dread": [],
        "Rygelon": [],
        "Geolier": []
    }
];