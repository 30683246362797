/* eslint-disable */
import Axios from 'axios'

import SFO from '../../services/SFO';

// initial state
const state = () => ({
  members: [],
  roster: [
    "Merrigus","Mahbit","Redm","Dryhwa","Araghast","Myanaa","Mevanana","Mamyhanzo","Likene","Nightpumà","Kërronz","Noffs","Loudda","Blackbétty","Syrane"
  ]
});

// getters
const getters = {
  getMembers: (state) => {
    return state.members;
  },
  getRosterMembers: (state) => {
    return state.members.filter(m => state.roster.includes(m.name));
  }
}

// actions
const actions = {
  loadMembers ({ commit }) {
      return new Promise((resolve, reject) => {
          try {
            Axios.get("https://server.dont-release.fr/raid")
            .then(response => {
              if (response.status == 200) {
                response.data.forEach(member => {
                  try {
                    Axios.get('https://server.dont-release.fr/characters/eu/' + member.realmSlug + '/' + member.name + '/rio')
                    .then(response => {
                      if (response.status == 200) {
                        const data = response.data;
                        member.rioCurrentScore = data.RIO.current.scores.all;

                        try {
                          member.maxKey = "+" + data.RIO.maxKey[0].mythic_level + " " + data.RIO.maxKey[0].short_name + " (" + data.RIO.maxKey[0].num_keystone_upgrades + ")"
                        } catch (error) {
                          console.log(error);
                          member.maxKey = "";
                        }
                        
                        if (data.RIO.maxWeeklyKey.length > 0) {
                          member.maxWeeklyKey = "+" + data.RIO.maxWeeklyKey[0].mythic_level + " " + data.RIO.maxWeeklyKey[0].short_name;
                        }
                        else {
                          member.maxWeeklyKey = "Ø";
                        }
                        
                        if (data.RIO.maxLastWeekKey.length > 0) {
                          member.maxLastWeekKey = "+" + data.RIO.maxLastWeekKey[0].mythic_level + " " + data.RIO.maxLastWeekKey[0].short_name;
                        }
                        else {
                          member.maxLastWeekKey = "Ø";
                        }

                        commit('setMember', member);
                      }
                      else {
                        throw null;
                      }
                    }).catch((error) => {
                      console.error(error);
                      throw error;
                    });
                  } catch (error) {
                    return;
                  }
                });
                resolve({ success: true });
              }
              else {
                reject(false);
              }
            }).catch((error) => {
              console.error(error);
              throw error;
            });
          } catch (error) {
            reject({ success: false, error: error });
          }
      });
  }
}

// mutations
const mutations = {
  setMember(state, member) {
    let exist = state.members.find(m => m.id == member.id);

    // Pour chaque membre il me faut son DPS sur chaque boss chaque semaine
    // L'object SFO est fait de la manière suivante :
    // NM -> Week -> Boss -> each dps

    // Donc pour chaque semaine
    SFO.NM.log.forEach(week => {
      // Pour chaque boss
      Object.keys(week).forEach(key => {
        // index est le nom du boss
        // value est le résultat et il faut trouver le DPS dedans
        const value = week[key];
        const existedLog = value.find(el => el.Name == member.name);
        if (existedLog) {
          if (typeof member.SFO == "undefined") {
            member.SFO = {}
          }

          if (typeof member.SFO.NM == "undefined") {
            member.SFO.NM = {}
          }

          if (typeof member.SFO.NM[key] == "undefined") {
            member.SFO.NM[key] = [];
          }

          member.SFO.NM[key].push(existedLog);
        }
      });
    });

    if (!exist) {
      state.members.push(member);
    }
    else {
      exist = member;
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}