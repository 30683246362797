<template>
    <v-dialog
      v-model="show"
      persistent
    >
      <v-card>
        <v-card-title class="headline">
            <img style="border-radius: 100%; max-width:26px; max-height:26px;" class="mr-2" height="100%" :src="DRClassURL" />
            <img style="border-radius: 100%; max-width:26px; max-height:26px;" height="100%" :src="DRSpecURL"/>
            <img class="mr-2" style="border-radius: 100%; max-width:26px; max-height:26px;" height="100%" :src="DRCovenantURL"/>
             {{ title }}
            <v-spacer></v-spacer>
            <v-btn 
                icon
                :href="WOWURL"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/wow-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                :href="RIOURL"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/brand/Icon_FullColor.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />
            </v-btn>
            <v-btn 
                icon
                :href="WCLURL"
                target="_blank" >
                <img
                    src="https://cdnassets.raider.io/images/profile/external/warcraftlogs-icon.png"
                    style="max-width: 32px; max-height: 32px; height: 100%; border-radius: 100%;"
                />  
            </v-btn>
        </v-card-title>

        <v-tabs v-model="tab" dark>
            <v-tab>Enchantements/Gemmes</v-tab>
            <v-tab>Logs</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item class="mx-4 my-2">
                <v-row>
                    <v-col xl="12" lg="12" md="12" sm="12" xs="12">
                        <h4>Enchantement(s) manquant(s)</h4>
                    </v-col>
                    <v-col xl="4" lg="4" md="6" sm="12" xs="12" v-for="enchantment in score.missing_enchantment" :key="enchantment.id">
                        <v-card
                            class="mx-auto"
                            color="#001845"
                            dark
                        >
                            <v-card-title>
                                <span class="title font-weight-light">{{enchantment.name}}</span>
                            </v-card-title>

                            <v-card-text class="headline font-weight-bold">
                                {{enchantment.type}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col xl="12" lg="12" md="12" sm="12" xs="12">
                        <h4 class="mt-4">Gemme(s) manquante(s)</h4>
                    </v-col>
                    <v-col xl="4" lg="4" md="6" sm="12" xs="12" v-for="socket in score.missing_socket" :key="socket.id">
                        <v-card
                            class="mx-auto"
                            color="#001845"
                            dark
                        >
                            <v-card-title>
                                <span class="title font-weight-light">{{socket.name}}</span>
                            </v-card-title>

                            <v-card-text class="headline font-weight-bold">
                                {{socket.type}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item class="mx-4 my-2">
                <v-card-title class="pt-0">Logs NM</v-card-title>
                <div v-if="haveNMLog">
                    <v-row>
                        <v-col v-for="b in boss_SFO" :key="b.id" xl="3" lg="3" md="6" sm="6" xs="12" cols="12">
                            <v-card
                                class="mx-auto"
                                color="secondary"
                                dark
                            >
                                <v-card-title>
                                    <v-list-item class="grow">
                                        <v-list-item-avatar color="grey darken-3">
                                            <v-img
                                                class="elevation-6"
                                                alt=""
                                                :src="b.icon"
                                            ></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>{{ b.name }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card-title>

                                <v-card-text class="text-h5 font-weight-bold" v-html="getSFOLog(b)">
                                </v-card-text>
                                <LineChart :data="getChartData(b)" :chartData="getChartData(b)" :options="chartOptions" />
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>Aucune logs NM pour ce personnage sur SFO</div>
            </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="close"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
    import boss_SFO from '../services/boss_SFO';
    import LineChart from './LineChart.vue';

    export default {
        name: "GuildeUserDetail",
        components: { LineChart },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            user: {
                type: Object,
                default: () => ({
                    realmSlug: "",
                    name: "",
                    class: 0,
                    spec: 0,
                    character: {
                        score: {
                            missing_enchantment: [],
                            missing_socket: [],
                            score: 0
                        }
                    }
                })
            }
        },
        computed: {
            DRClassURL() {
                return 'https://server.dont-release.fr/classMedia/' + this?.user?.character.class;
            },
            DRSpecURL() {
                return 'https://server.dont-release.fr/specMedia/' + this?.user?.character.spec;
            },
            DRCovenantURL() {
                let link = "";
                switch (this?.user?.covenant) {
                    case 1:
                        link = "https://cdnassets.raider.io/images/wow/icons/medium/ui_sigil_kyrian.jpg";
                        break;
                    case 2:
                        link = "https://cdnassets.raider.io/images/wow/icons/medium/ui_sigil_venthyr.jpg";
                        break;
                    case 3:
                        link = "https://cdnassets.raider.io/images/wow/icons/medium/ui_sigil_nightfae.jpg";
                        break;
                    case 4:
                        link = "https://cdnassets.raider.io/images/wow/icons/medium/ui_sigil_necrolord.jpg";
                        break;
                    
                    default:
                        break;
                }

                return link;
            },
            WOWURL() {
                return 'https://worldofwarcraft.com/fr-fr/character/eu/' + this.user?.realmSlug?.toLowerCase() + '/' + this.user?.name?.toLowerCase()
            },
            RIOURL() {
                return 'https://raider.io/characters/eu/' + this.user?.realmSlug?.toLowerCase() + '/' + this.user?.name?.toLowerCase()
            },
            WCLURL() {
                return 'https://www.warcraftlogs.com/character/eu/' + this.user?.realmSlug?.toLowerCase() + '/' + this.user?.name?.toLowerCase()
            },
            title() {
                return `${this.user?.name} (${this.user?.rioCurrentScore} RIO) (${parseInt(this.user?.character?.score?.score)}/100)`;
            },
            score() {
                return this.user?.character.score ? this.user.character.score : {
                    missing_enchantment: [],
                    missing_socket: [],
                    score: 0
                }
            },
            haveNMLog() {
                return this.user?.SFO && this.user?.SFO?.NM;
            },
            getChartData() {
                return boss => {
                    const data = {
                        labels: ["1er down", "2nd", "3th", "4th", "5th"],
                        datasets: [
                            {
                                label: 'DPS',
                                fill: true,
                                borderColor: '#2196f3',
                                backgroundColor: '#2196f3',
                                data: [],
                                yAxisID: 'y',
                                pointStyle: 'circle',
                                pointRadius: 5,
                                pointHoverRadius: 10
                            },
                            {
                                label: 'iLvl',
                                fill: true,
                                borderColor: '#c00050',
                                backgroundColor: '#c00050',
                                data: [],
                                yAxisID: 'y1',
                                pointStyle: 'circle',
                                pointRadius: 5,
                                pointHoverRadius: 10
                            }
                        ]
                    }
                    
                    const bossData = this.user.SFO.NM[boss.name];

                    if (bossData) {
                        bossData.forEach(bd => {
                            if (bd.DPS && bd.Ilvl) {
                                data.datasets[0].data.push(parseFloat(bd.DPS.toString().replace(/,/g, '')));
                                data.datasets[1].data.push(parseFloat(bd.Ilvl));
                            }
                        });
                    }

                    return data;
                }
            }
        },
        data () {
            return {
                tab: 0,
                boss_SFO: boss_SFO,
                chartOptions: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    plugins: {
                        title: {
                            display: true,
                            text: (ctx) => 'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
                        }
                    },
                    stacked: false,
                    scales: {
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                        },
                        y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',

                            // grid line settings
                            grid: {
                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                            },
                        },
                    }
                }
            }
        },
        methods: {
            getSFOLog: function(boss) {
                const bossData = this.user.SFO.NM[boss.name];

                if (bossData) {
                    let html = "";

                    bossData.forEach(data => {
                        html += `<div>${data.DPS} (${data.Ilvl} iLvl) ${data["Ilvl %"]} Ilvl %</div>`
                    });

                    return html;
                }
                else {
                    return "Pas de log";
                }
            },
            close: function() {
                this.$emit('close');
            }
        }
    }
</script>