/* eslint-disable */
import Axios from 'axios'
import Vue from 'vue'

// initial state
const state = () => ({
  classes: [],
});

// getters
const getters = {
  getClasses: (state) => {
    return state.classes;
  }
}

// actions
const actions = {
  load ({ commit }) {
      return new Promise((resolve, reject) => {
          try {
            Axios.get("https://v2.server.dont-release.fr/api/v1/classes")
            .then(response => {
              if (response.status == 200) {
                commit('setClasses', response.data.classes);
                resolve({ success: true });
              }
              else {
                reject(false);
              }
            }).catch((error) => {
              console.error(error);
              throw error;
            });
          } catch (error) {
            reject({ success: false, error: error });
          }
      });
  }
}

// mutations
const mutations = {
  setClasses(state, classes) {
    state.classes = classes;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}